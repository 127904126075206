import { Gasto } from './../shared/sdk/models/Gasto';
import { Embargo } from './../shared/sdk/models/Embargo';
import { PopupAbogadoModalComponent } from './../popup-abogado-modal/popup-abogado-modal.component';
import { ActualizacionesModalComponent } from './../actualizaciones-modal/actualizaciones-modal.component';
import { VerArchivosModalComponent } from './../ver-archivos-modal/ver-archivos-modal.component';
import { SubirArchivoModalComponent } from './../subir-archivo-modal/subir-archivo-modal.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CasoApi, Caso, UsuarioApi, Usuario, ContainerApi, Comentario, ComentarioApi, LogApi, Log, } from '../shared/sdk';
import { ContextMenu } from 'primeng/contextmenu';
import { MenuItem, DialogService, MessageService } from 'primeng/primeng';
import { EditarModalComponent } from '../editar-modal/editar-modal.component';
import { HistorialModalComponent } from '../historial-modal/historial-modal.component';
import { Editar2ModalComponent } from '../editar2-modal/editar2-modal.component';
import { CambiarEstadoComponent } from '../cambiar-estado/cambiar-estado.component';
import { PopupModalComponent } from '../popup-modal/popup-modal.component';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as fileSaver from 'file-saver';
import { HttpResponse, HttpClient } from '@angular/common/http';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const EXCEL_EXTENSION = '.xlsx';
import * as XLSX from 'xlsx';
import { VerInformeComponent } from '../ver-informe/ver-informe.component';

/*

Es practicamente lo mismo del componente de cartera, (la carpeta arriba de esta) cualquier ayuda esta ahi

*/



@Component({
  selector: 'app-cartera-abogado',
  templateUrl: './cartera-abogado.component.html',
  styleUrls: ['./cartera-abogado.component.css'],
  providers: [DialogService, MessageService]
})
export class CarteraAbogadoComponent implements OnInit {
  honorarioPandemia: any
  honorarioPrePandemia: any
  honorarioPostPandemia: any

  constructor(public logapi: LogApi, private http: HttpClient, public router: Router, public comentarioapi: ComentarioApi, public dialogService: DialogService, public casoapi: CasoApi, public usuarioapi: UsuarioApi, public containerapi: ContainerApi) { }

  actualDate = new Date()
  public files: Set<File> = new Set();
  filename = ''
  cols = [
    { field: 'id', header: '# id' },//ESTA EN PRINCIPAL
    { field: 'ff', header: '' },//ESTA EN PRINCIPAL
    { field: 'acreditado', header: 'Acreditado' },//ESTA EN PRINCIPAL
    { field: 'Cast_fira', header: 'Castigo FIRA' }, //NUEVA COLUMNA
    { field: 'Cast_accedde', header: 'Castigo Accedde'}, //NUEVA COLUMNA
    { field: 'Et_procesal', header: 'Etapa Procesal'}, //NUEVA COLUMNA 
    { field: 'estatusM', header: 'Estatus Mercantil' },//ESTA EN PRINCIPAL
    { field: 'impugnacionM', header: 'Impugnacion Mercantil' },//ESTA EN PRINCIPAL
    { field: 'expedienteM', header: 'Expediente Mercantil' },//ESTA EN PRINCIPAL
    { field: 'juzgadoM', header: 'Juzgado Mercantil' },// ESTA EN PRINCIPAL
    { field: 'estatusC', header: 'Estatus Civil' },// ESTA EN PRINCIPAL
    { field: 'impugnacionC', header: 'Impugnacion Civil' },//ESTA EN PRINCIPAL
    { field: 'expedienteC', header: 'Expediente Civil' },//ESTA EN PRINCIPAL
    { field: 'Juzgado_civil', header: 'Juzgado Civil' },//ESTA EN PRINCIPAL
    { field: 'expToca', header: 'Expediente TOCA' },//ESTA EN PRINCIPAL
    { field: 'sala', header: 'Sala' },//ESTA EN PRINCIPAL
    { field: 'capital', header: 'Capital' },//ESTA EN PRINCIPAL
    { field: 'intOrdinarios', header: 'Intereses Ordinarios' },//ESTA EN PRINCIPAL
    { field: 'intMoratorios', header: 'Intereses Moratorios' },//ESTA EN PRINCIPAL
    { field: 'total', header: 'Total' },//ESTA EN PRINCIPAL
    { field: 'cap_efsy', header: 'Capital EFSY' },//NUEVA COLUMNA
    { field: 'intOrdinarios_efsy', header: 'Intereses Ordinarios EFSY' },// NUEVA COLUMNA
    { field: 'intMoratorios_efsy', header: 'Intereses Moratorios EFSY' },// NUEVA COLUMNA
    { field: 'total_efsy', header: 'Total EFSY' },// NUEVA COLUMNA
    { field: 'montoCast', header: 'Monto Castigo/Por Castigar'}, //NUEVA COLUMNA
    { field: 'conceptoCast', header: 'Concepto Castigo'}, //NUEVA COLUMNA
    { field: 'comentarios', header: 'Comentarios' },//ESTA EN PRINCIPAL
    { field: 'bienesEmbargados', header: 'Bienes Embargados (solo desde admin se llena)' },//ESTA EN PRINCIPAL
    { field: 'inscripcionEmbargo', header: 'Inscripcion de Embargo (solo desde admin se llena)' },//ESTA EN PRINCIPAL
    { field: 'informeMens', header: 'Informe Mensual'}, //NUEVA COLUMNA
    { field: 'garantia', header: 'Garantia' },//ESTA EN PRINCIPAL
    { field: 'valorEstimado', header: 'Valor Estimado' },//ESTA EN PRINCIPAL
    { field: 'fechaRecuperacion', header: 'Fecha de Recuperacion' },//ESTA EN PRINCIPAL
    { field: 'fechaAsignacion', header: 'Fecha de Asignacion' },//ESTA EN PRINCIPAL
    { field: 'grupo', header: 'Grupo' },//ESTA EN PRINCIPAL
    { field: 'honorarios', header: 'Honorarios' },//ESTA EN PRINCIPAL
    { field: 'honorariosPeri', header: 'Honorarios Perito' },//NUEVA COLUMNA
    { field: 'honorariosRebel', header: 'Honorarios Perito En Rebeldia' },//NUEVA COLUMNA
    { field: 'actualizaciones', header: 'Actualizaciones' },


  ];
 //SE AGREGA DROPDOWN PARA SELECCIONAR CASOS DONDE SE HAYAN RECUPERADO LOS CAPITALES
  /*dropdownrecuperado: any[] = [
    {label: 'NO', value: false},
    {label: 'SI', value: true},
  ]*/

  //SE QUITA EL DROPDOWN TRAMOS
  /*dropdowntramos: any[] = []
  tramos: any[] = [
    { label: '1 Tramo', value: 0 },
    { label: '2 Tramo', value: 1 },
    { label: '3 Tramo', value: 2 },
    { label: 'FEGA/FONAGA', value: 3 },
    { label: 'Propia Good Bank', value: 4 },
    { label: 'Propia Bad Bank', value: 5 },
    { label: 'GRUPAL', value: 6 },
    //{ label: 'X.F.', value: 7 },
  ]*/
  dropdownestatus: any[] = [
    { label: 'Presentacion de la Demanda', value: 0 },
    { label: 'Admision de la Demanda', value: 1 },
    { label: 'Diligencia de Requerimiento emplazamiento y embargo', value: 2 },
    { label: 'Etapa Probatoria', value: 3 },
    { label: 'Alegatos', value: 4 },
    { label: 'Sentencia de la Instancia', value: 5 },
    { label: 'Ejecucion de la Sentencia', value: 6 },
    { label: 'Procedimiento de Remate', value: 7 },
    { label: 'Suspension de proceso', value: 8 },
  ]
  dropdownestatus2: any[] = [
    { label: 'Contestacion de la Demanda', value: 0 },
    { label: 'Reconvencion', value: 1 },
    { label: 'Etapa Probatoria', value: 2 },
    { label: 'Alegatos', value: 3 },
    { label: 'Sentencia de la Instancia', value: 4 },

  ]

  dropdownimpugnacionC: any[] = [
    { label: 'Apelacion', value: 0 },
    { label: 'Revocacion', value: 1 },
    { label: 'Queja', value: 2 },
    { label: 'Amparo Directo', value: 3 },
    { label: 'Amparo Indirecto', value: 4 },
    { label: 'Suspendido', value: 5 },
    { label: 'Caducado', value: 6 },
  ]
  dropdownacreditado: any[] = []

  //OPCIONES DEL MENU DESPLEGABLE PARA ETAPA PROCESAL
  dropdownEt_procesal: any[] = [
    { label: 'Demanda', value: 0 },
    { label: 'Admision de demanda', value: 1 },
    { label: 'Diligencia de requerimiento de pago y embargo', value: 2 },
    { label: 'Contestacion de demanda', value: 3 },
    { label: 'Desahogo de vista de contestacion de demanda', value: 4 },
    { label: 'Pruebas', value: 5 },
    { label: 'Desahogo de pruebas', value: 6 },
    { label: 'Alegatos', value: 7 },
    { label: 'Sentencia', value: 8 },
    { label: 'Ejecucion de sentencia', value: 9 },
    { label: 'Procedimiento de remate', value: 10 },
    { label: 'Adjudicacion', value: 11 }
  ]

  dropdownimpugnacionM: any[] =[
    {label: 'Incidente', value: 0},
    {label: 'Recurso de revocacion', value: 1},
    {label: 'Recurso de apelacion', value: 2},
    {label: 'Amparo', value: 3},
    {label: 'Amparo en revision', value: 4}
  ]

  dropdownjuzgadoM: any[] = [
    { label: 'J°1 M.', value: 26 },
    { label: 'J°2 M.', value: 27 },
    { label: 'J°3 M.', value: 28 },
    { label: 'J°4 M.', value: 29 },
    { label: 'J°5 M.', value: 30 },
    { label: 'J°6 M.', value: 31 },
    { label: 'J°7 M.', value: 32 },
    { label: 'J°8 M.', value: 33 },
    { label: 'J°9 M.', value: 34 },
    { label: 'J°10 M.', value: 35 },
    { label: 'J°1 O.M.', value: 36 },
    { label: 'J°2 O.M.', value: 37 },
    { label: 'J°3 O.M.', value: 38 },
    { label: 'J°4 O.M.', value: 39 },
    { label: 'J°5 O.M.', value: 40 },
    { label: 'J°6 O.M.', value: 41 },
    { label: 'J°7 O.M.', value: 42 },
    { label: 'J°8 O.M.', value: 43 },
    { label: 'J°9 O.M.', value: 44 },
    { label: 'J°10 O.M.', value: 45 },

  ]
  dropdownJuzgado_civil: any[] = [
    { label: 'J°1 C.', value: 0 },
    { label: 'J°2 C.', value: 1 },
    { label: 'J°3 C.', value: 2 },
    { label: 'J°4 C.', value: 3 },
    { label: 'J°5 C.', value: 4 },
    { label: 'J°6 C.', value: 5 },
    { label: 'J°7 C.', value: 6 },
    { label: 'J°8 C.', value: 7 },
    { label: 'J°9 C.', value: 8 },
    { label: 'J°10 C.', value: 9 },
    { label: 'J°11 C.', value: 10 },
    { label: 'J°12 C.', value: 11 },
    { label: 'J°13 C.', value: 12 },
    { label: 'J°14 C.', value: 13 },
    { label: 'J°1 O.C.', value: 14 },
    { label: 'J°2 O.C.', value: 15 },
    { label: 'J°3 O.C.', value: 16 },
    { label: 'J°4 O.C.', value: 17 },
    { label: 'J°5 O.C.', value: 18 },
    { label: 'J°6 O.C.', value: 19 },
    { label: 'J°7 O.C.', value: 20 },
    { label: 'J°8 O.C.', value: 21 },
    { label: 'J°9 O.C.', value: 22 },
    { label: 'J°10 O.C.', value: 23 },
    { label: 'J°11 O.C.', value: 24 },
    { label: 'J°12 O.C.', value: 25 },
    { label: 'J°13 O.C.', value: 26 },
    { label: 'J°14 O.C.', value: 27 },

  ]
  dropdowngrupo: any[] = []
  casos: Caso[];
  usuarios: Usuario[];
  abogados: any[] = [];
  items: MenuItem[];
  casoSeleccionado;
  popupClosed = false;
  onFilesAdded() {
    //console.log("ENTREEEEEEE222")
    const reader = new FileReader();
  }
  logout() {
    this.usuarioapi.logout().subscribe((s) => {
      this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
        var r: Log = new Log();
        r.createdAt = new Date()
        r.updatedAt = new Date()
        r.idUsuario = us.idUsuario
        r.tipo = 1
        r.contenido = 'Usuario: ' + us.username + '| Cerrar Sesion '
        this.logapi.create(r).subscribe((d) => {

        })
        this.router.navigateByUrl("login", { queryParams: { caso: this.casoSeleccionado } })

      })

    })
  }

  showContext(cm: ContextMenu, event: MouseEvent, caso: any) {
    cm.show(event);
    event.stopPropagation();
    this.casoSeleccionado = caso
    //console.log(caso)
  }

  //Metodo que abre el modal para ver informe y a la ves envia el idCaso que corresponde el cual es necesario
  //para poder comparar el idCaso de la tabla caso con el idcaso de la tabla informeMens
  buscarInforme(event: MouseEvent, caso: any) {
    this.casoSeleccionado = caso
    const ref = this.dialogService.open(VerInformeComponent, {
      data: 
      {
        caso:this.casoSeleccionado,
      },

      width: '90%',
      height: '90%',
      showHeader: true,
      dismissableMask: true,
      baseZIndex: 1030,
      style: { "position": "fixed", "overflow": "auto", "max-height": "91%" }
    });
    
  }

   //FUNCION QUE DETECTA SI LA FECHA DE RECUPERACION YA ESTA CERCA DEL LIMITE
  // PONDRA EN ROJO LA FECHA DE RECUPRACION CUANDO FALTEN MENOS O IGUAL DE 91.2501 DIAS (3 MESES) PARA RECUPERARLO.
  ponerFechaRecRojo(caso: Caso){
    let dias = +new Date(caso.fechaRecuperacion) - +this.actualDate
    dias = dias / 1000 / 60 / 60 / 24 
    let diasFaltantes: any = (Number.parseInt(dias.toString())).toFixed(0)

    if(diasFaltantes <= 91.2501){ 
      return true
    }
    else{
      false
    }
  }

  //llama al servidor para descargar un archivo a travez de pura llamada http
  //no usar el sdk para descargar, no funciona correctamente.
  download(f, u) {
    this.downloadFile(f, u).subscribe(response => {
      let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
        var r: Log = new Log();
        r.createdAt = new Date()
        r.updatedAt = new Date()
        r.tipo = 3
        r.idUsuario = us.idUsuario
        r.contenido = JSON.stringify(f)
        this.logapi.create(r).subscribe((d) => {
        })
      })
      fileSaver.saveAs(blob, f);
    }), error => console.log('Error downloading the file'),
      () => {
      }
  }
 //llama al servidor para descargar un archivo a travez de pura llamada http
  //no usar el sdk para descrgar, no funciona correctamente.
  downloadFile(f, u): Observable<Blob> {
    var i = u
    var url = 'https://api1.accedde.mx/api/containers/' + i + '/download/' + f
    //var url = 'http://localhost:3000/api/containers/' + i + '/download/' + f
    return this.http.get(url, { responseType: 'blob' });
  }

  /*download(f) {
    this.downloadFile(f).subscribe(response => {
      let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      //window.location.href = response.url;
      fileSaver.saveAs(blob, f);
    }), error => console.log('Error downloading the file'),
      () => {


      }
  }

  downloadFile(f): Observable<Blob> {
    var i = this.usuarioapi.getCurrentId()
    var url = 'http://localhost:3000/api/containers/' + i + '/download/' + f

    this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
      var r: Log = new Log();
      r.createdAt = new Date()
      r.updatedAt = new Date()
      r.idUsuario = us.idUsuario
      r.tipo = 3
      r.contenido = JSON.stringify(f)
      this.logapi.create(r).subscribe((d) => {

      })
    })

    return this.http.get(url, { responseType: 'blob' });
  } */
  diasHastaCaducidad(d1) {
    var m = +this.actualDate - +new Date(d1)
    m = m / 1000 / 60 / 60 / 24
    var f: any = (Number.parseInt(m.toString())).toFixed(0)
    return 120 - f
  }
  export(tt) {
    console.log(tt.bodyTemplate._projectedViews[0].parent.oldValues[3])
    var casolist: tableCaso[] = []
    var listacasos: Caso[] = tt.bodyTemplate._projectedViews[0].parent.oldValues[3]
    for (var d of listacasos) {
      var c = new tableCaso();
      c.id = d.id

    
      c.acreditado = d.acreditado
      c.Castigo_fira = d.Cast_fira; //COLUMNA NUEVA
      c.Castigo_accedde = d.Cast_accedde;// COLUMNA NUEVA

      //SE AGREGAN LAS OPCIONES DE LOS ESTADOS DE ETAPA PROCESAL
      switch(d.Et_procesal){
        case 0: 
          c.Etapa_procesal = "Demanda"
          break;
        case 1:
          c.Etapa_procesal = "Admision de demanda"
          break;
        case 2:
          c.Etapa_procesal = "Diligencia de requerimiento de pago y embargo"
          break;
        
        case 3: 
          c.Etapa_procesal = "Contestacion de demanda" 
          break;
        
        case 4:
          c.Etapa_procesal = "Desahogo de vista de contestacion de demanda"
          break;

        case 5:
          c.Etapa_procesal = "Pruebas"
          break;

        case 6:
          c.Etapa_procesal = "Desahogo de pruebas"
          break;

        case 7:
          c.Etapa_procesal = "Alegatos"
          break;
        
        case 8:
          c.Etapa_procesal = "Sentencia"
          break;

        case 9:
          c.Etapa_procesal = "Ejecucion de sentencia"
          break;

        case 10:
          c.Etapa_procesal = "Procedimiento de remate"
          break;

          case 11:
            c.Etapa_procesal = "Adjudicacion"
          break;

          default:
            c.Etapa_procesal = ""
          break;
      }

      switch (d.estatusM) {
        case 0:
          c.Estatus_mercantil = "Presentacion de la Demanda"
          break;
        case 1:
          c.Estatus_mercantil = "Admision"
          break;
        case 2:
          c.Estatus_mercantil = "Diligencia de los Requerimientos, Emplazamiento y Embargo"
          break;
        case 3:
          c.Estatus_mercantil = "Etapa Probatoria"
          break;
        case 4:
          c.Estatus_mercantil = "Alegatos"
          break;
        case 5:
          c.Estatus_mercantil = "Sentencia de la Instancia"
          break;
        case 6:
          c.Estatus_mercantil = "Ejecucion de la Sentencia"

          break;
        case 7:
          c.Estatus_mercantil = "Procedimiento de Remate"
          break;
        default:
          c.Estatus_mercantil = ''
          break;
      }
      switch (d.impugnacionM) {
        case 0:
          c.Impugnacion_mercantil = "Incidente"
          break;
        case 1:
          c.Impugnacion_mercantil = "Recurso de revocacion"
          break;
        case 2:
          c.Impugnacion_mercantil = "Recurso de apelacion"
          break;
        case 3:
          c.Impugnacion_mercantil = "Amparo"
          break;
        case 4:
          c.Impugnacion_mercantil = "Amparo en revision"
          break;
        default:
          c.Impugnacion_mercantil = ''
          break;

      }
      c.Expediente_mercantil = d.expedienteM
      switch (d.juzgadoM) {
        case 26:
          c.Juzgado_mercantil = "J°1 M."
          break;
        case 27:
          c.Juzgado_mercantil = "J°2 M."
          break;
        case 28:
          c.Juzgado_mercantil = "J°3 M."
          break;
        case 29:
          c.Juzgado_mercantil = "J°4 M."
          break;
        case 30:
          c.Juzgado_mercantil = "J°5 M."
          break;
        case 31:
          c.Juzgado_mercantil = "J°6 M."
          break;
        case 32:
          c.Juzgado_mercantil = "J°7 M."
          break;
        case 33:
          c.Juzgado_mercantil = "J°8 M."
          break;
        case 34:
          c.Juzgado_mercantil = "J°9 M."
          break;
        case 35:
          c.Juzgado_mercantil = "J°10 M."
          break;
        case 36:
          c.Juzgado_mercantil = "J°1 O.M."
          break;
        case 37:
          c.Juzgado_mercantil = "J°2 O.M."
          break;
        case 38:
          c.Juzgado_mercantil = "J°3 O.M."
          break;
        case 39:
          c.Juzgado_mercantil = "J°4 O.M."
          break;
        case 40:
          c.Juzgado_mercantil = "J°5 O.M."
          break;
        case 41:
          c.Juzgado_mercantil = "J°6 O.M."
          break;
        case 42:
          c.Juzgado_mercantil = "J°7 O.M."
          break;
        case 43:
          c.Juzgado_mercantil = "J°8 O.M."
          break;
        case 44:
          c.Juzgado_mercantil = "J°9 O.M."
          break;
        case 45:
          c.Juzgado_mercantil = "J°10 O.M."
          break;
        default:
          c.Juzgado_mercantil = ''
          break;

      }
      switch (d.estatusC) {
        case 0:
          c.Estatus_civil = "Contestacion de la Demanda"
          break;
        case 1:
          c.Estatus_civil = "Reconvencion"
          break;
        case 2:
          c.Estatus_civil = "Etapa Probatoria"
          break;
        case 3:
          c.Estatus_civil = "Alegatos"
          break;
        case 4:
          c.Estatus_civil = "Sentencia de la Instancia"
          break;
        default:
          c.Estatus_civil = ''
          break;
      }
      switch (d.impugnacionC) {
        case 0:
          c.Impugnacion_civil = "Apelacion"
          break;
        case 1:
          c.Impugnacion_civil = "Revocacion"
          break;
        case 2:
          c.Impugnacion_civil = "Queja"
          break;
        case 3:
          c.Impugnacion_civil = "Amparo Directo"
          break;
        case 4:
          c.Impugnacion_civil = "Amparo Indirecto"
          break;
        case 5:
          c.Impugnacion_civil = "Suspendido"
          break;
        case 6:
          c.Impugnacion_civil = "Caducado"
          break;
        default:
          c.Impugnacion_civil = ''
          break;

      }
      c.Expediente_civil = d.expedienteC
      switch (d.juzgadoC) {
        case 0:
          c.Juzgado_civil = "J°1 C."
          break;
        case 1:
          c.Juzgado_civil = "J°2 C."
          break;
        case 2:
          c.Juzgado_civil = "J°3 C."
          break;
        case 3:
          c.Juzgado_civil = "J°4 C."
          break;
        case 4:
          c.Juzgado_civil = "J°5 C."
          break;
        case 5:
          c.Juzgado_civil = "J°6 C."
          break;
        case 6:
          c.Juzgado_civil = "J°7 C."
          break;
        case 7:
          c.Juzgado_civil = "J°8 C."
          break;
        case 8:
          c.Juzgado_civil = "J°9 C."
          break;
        case 9:
          c.Juzgado_civil = "J°10 C."
          break;
        case 10:
          c.Juzgado_civil = "J°11 C."
          break;
        case 11:
          c.Juzgado_civil = "J°12 C."
          break;
        case 12:
          c.Juzgado_civil = "J°13 C."
          break;
        case 13:
          c.Juzgado_civil = "J°1 O.C."
          break;
        case 14:
          c.Juzgado_civil = "J°2 O.C."
          break;
        case 15:
          c.Juzgado_civil = "J°3 O.C."
          break;
        case 16:
          c.Juzgado_civil = "J°4 O.C."
          break;
        case 17:
          c.Juzgado_civil = "J°5 O.C."
          break;
        case 18:
          c.Juzgado_civil = "J°6 O.C."
          break;
        case 19:
          c.Juzgado_civil = "J°7 O.C."
          break;
        case 20:
          c.Juzgado_civil = "J°8 O.C."
          break;
        case 21:
          c.Juzgado_civil = "J°9 O.C."
          break;
        case 22:
          c.Juzgado_civil = "J°10 O.C."
          break;
        case 23:
          c.Juzgado_civil = "J°11 O.C."
          break;
        case 24:
          c.Juzgado_civil = "J°12 O.C."
          break;
        case 25:
          c.Juzgado_civil = "J°13 O.C."
          break;
        default:
          c.Juzgado_civil = ''
          break;

      }
      
     
      c.Expediente_TOCA = d.expToca
      c.Sala = d.sala
      c.Capital = d.capital

      c.Intereses_ordinarios = d.intOrdinarios
      c.Intereses_moratorios = d.intMoratorios
      c.total = d.capital + d.intOrdinarios + d.intMoratorios
      c.Capital_efsy = d.cap_efsy //NUEVA COLUMNA 
      c.Intereses_ordinarios_efsy = d.intOrdinarios_efsy //NUEVA COLUMNA 
      c.Intereses_moratorios_efsy = d.intMoratorios_efsy //NUEVA COLUMNA 
      c.total_efsy = d.cap_efsy + d.intOrdinarios_efsy + d.intMoratorios_efsy//NUEVA COLUMNA
      
      c.Monto_castigo_por_castigar = d.montoCast
      c.Concepto_castigo = d.conceptoCast

    
      if (d.comentarios.length == 0) {
        c.comentarios = ''
      } else {
        c.comentarios = this.concatenarComentarios(d.comentarios)
      }

      c.Bienes_embargados = this.concatEmbargos(d.embargos)
      c.inscripcionEmbargo = this.getInscripcion(d.embargos)

      c.garantia = d.garantia
      c.valorEstimado = d.valorEstimado

      c.fechaRecuperacion = d.fechaRecuperacion && d.fechaRecuperacion.toString() || null;//
      //  c.fechaRecuperacion = d.fechaRecuperacion.toString()?//
      c.fechaAsignacion = d.fechaAsignacion && d.fechaAsignacion.toString() || null//
      c.grupo = d.grupo
    
      c.honorarios = Number.parseInt(this.getAcumuladoHonorarios(d.fechaAsignacion, d.honorario, d.fechaDescPandemia, d.honorarioPandemia, d.fechaFinPandemia));
      c.honorarios_perito = Number.parseInt(this.getAcumuladoPeritoYRebeldia(d.fechaAsignacion, d.honorariosPeri)); //NUEVA COLUMNA
      c.honorarios_perito_en_rebeldia = Number.parseInt(this.getAcumuladoPeritoYRebeldia(d.fechaAsignacion, d.honorariosRebel)); //NUEVA COLUMNA
    
      casolist.push(c)
    }
    this.exportAsExcelFile(casolist, 'Mis_casos');
  }
  getHeaders() {
  }
  concatenarComentarios(comentarios: Comentario[]) {
    var str = ''
    for (var c of comentarios) {
      str += c.createdAt + '|' + c.contenido + '|' + c.archivo + ' \n'
    }
    return str
  }
  
  getGarantias(caso: Caso) {
    var str = ' '
    if (caso.garantias.length > 0) {
      for (var g of caso.garantias) {
        if (g.descripcion != null) {
          str += g.descripcion
        }
      }
    }
    return str
  }
  getValorGarantias(caso: Caso) {
    var v = 0
    for (var g of caso.garantias) {

      v += g.valorEstimado
    }
    return v
  }
  

  //acumulaciones de valores para imprimir totales de algun tipo
  //Se agrega la variable "restilla" la cual hara la resta del honorario original - el honorario por pandemia
  //esta operacion nos mandara el honorario real en caso de que el caso tenga honorario de pandemia.
  getAcumuladoHonorarios(fecha, monto, fechaIniPandemia, montoPandemia, fechaFinPandemia) {
    let prepandemiaINT: any
    let pandemiaINT: any
    let postpandemiaINT: any
   
    //Primero verifica que el caso no tenga fecha de inicio de pandemia
    if(fechaIniPandemia != null && montoPandemia != null){
      this.honorarioPrePandemia = 0
      this.honorarioPandemia = 0
      this.honorarioPostPandemia = 0
      //-- Verifica que la fecha en que inicio el caso sea menor a la fecha del inicio de la pandemia
      if(new Date(fecha) < new Date(fechaIniPandemia)){

        //--Aqui verifica si la fecha actual es menor a la fecha del inicio de la pandemia
        //-- Seria raro que entre en esta condicion ya que la pandemia inicio en el 2020.
        if(this.actualDate<new Date(fechaIniPandemia)){
          let m = Math.abs(+new Date(fecha) - +this.actualDate)
          m = m / 1000 / 60 / 60 / 24 / 30
          this.honorarioPrePandemia = (Number.parseInt(m.toString()) * monto).toFixed(0)
        }
        //--Si la fecha actual ya es mayor a la fecha de inicio de la pandemia, entonces ya hara el calculo para obtener
        // el honorario pre-pandemia
        else if(this.actualDate>=new Date(fechaIniPandemia)){
          let m = Math.abs(+new Date(fecha) - +new Date(fechaIniPandemia))
          m = m / 1000 / 60 / 60 / 24 / 30
          this.honorarioPrePandemia = (Number.parseInt(m.toString()) * monto).toFixed(0)
        }
      }

      //-- Si la fecha actual es mayor o igual a la fecha de inicio de la pandemia, hara el calculo
      // desde la fecha del inicio de la pandemia con la fecha actual.
      if(this.actualDate >= new Date(fechaIniPandemia)){
        let m = Math.abs(+new Date(fechaIniPandemia) - +this.actualDate)
        m = m / 1000 / 60 / 60 / 24 / 30
        this.honorarioPandemia =(Number.parseInt(m.toString()) * montoPandemia).toFixed(0)
      }
      //---- HASTA ESTE PUNTO SE DA EL HONORARIO PRE PANDEMIA Y HONORARIO EN PANDEMIA ----//
      
      //-- Aqui verifica que el caso tenga fecha para finalizar el honorario por pandemia
      
            if(fechaFinPandemia != null){
              
              //-- Aqui verifica que la fecha actual este dentro del rango entre la fecha de inicio de pandemia
              //  y la fecha de finalizacion de la pandemia, si la fecha actua estuviera en ese rango
              // entonces el honorario por pandemia seguiria vigente.
              if(new Date(fechaFinPandemia)>this.actualDate && new Date(fechaIniPandemia)<= this.actualDate){
                this.honorarioPandemia = this.honorarioPandemia
                this.honorarioPostPandemia = 0;
              }
              
              // -- Aqui en caso de que la fecha actual ya este fuera del rango de la fecha de inicio y fin de pandemia
              //   se hara el calculo para el honorario por pandemia total y ademas el calculo 
              //   del honorario post pandemia para el caso
              else if(new Date(fechaFinPandemia)<=this.actualDate && new Date(fechaIniPandemia)< this.actualDate){
                let m = Math.abs(+new Date(fechaIniPandemia) - +new Date(fechaFinPandemia))
                let m2 = Math.abs(+new Date(fechaFinPandemia) - +this.actualDate)
                m = m / 1000 / 60 / 60 / 24 / 30
                this.honorarioPandemia = (Number.parseInt(m.toString()) * montoPandemia).toFixed(0)

                m2 = m2 / 1000 / 60 / 60 / 24 / 30
                this.honorarioPostPandemia = (Number.parseInt(m2.toString()) * monto).toFixed(0)
              }

            }
            else{
              this.honorarioPostPandemia = 0;
            }
      
            //-- Al final capta las sumatorias de cada tipo de honorario
      prepandemiaINT = parseFloat(this.honorarioPrePandemia)
      pandemiaINT = parseFloat(this.honorarioPandemia)
      postpandemiaINT = parseFloat(this.honorarioPostPandemia)

            //-- Por ultimo suma las sumatorias de los 3 honorarios.
      return prepandemiaINT+pandemiaINT+postpandemiaINT
    }

    //-- Si el caso no cuenta con honorario por pandemia, 
    //   simplemente hara el calculo normal del honorario dado inicialmente
    else{
    
    var m = Math.abs(+new Date(fecha) - +this.actualDate)
    m = m / 1000 / 60 / 60 / 24 / 30
    return (Number.parseInt(m.toString()) * monto).toFixed(0)
    }
  }

  //acumulacion de los honorarios perito y perito en rebeldia
  getAcumuladoPeritoYRebeldia(fecha, monto) {
    var m = Math.abs(+new Date(fecha) - +this.actualDate)
    m = m / 1000 / 60 / 60 / 24 / 30
    return (Number.parseInt(m.toString()) * monto).toFixed(0)
  }

  concatEmbargos(embargos: Embargo[]) {



    var str = '';
    if (embargos) {
      if (embargos.length > 0) {
        for (var e of embargos) {
          if (e.contenido != null) {
            str += e.contenido + " | ";
          }

        }
      }
    }


    return str;

  }

  getInscripcion(embargos: Embargo[]) {
    var str = '';
    for (var e of embargos) {
      if (e.inscripcionEmbargo) {
        str += 'Si |';
      } else {
        str += 'No |';
      }

    }
    return str;

  }

   //FUNCION PARA MOSTRAR LA FECHA CORRECTA DE ASIGNACION DEL CASO
   getFechaAsign(caso: Caso){
    let spliteando
    let fechaAsignSplit
    let fechaAsignReal
    let diaSplit
    if(caso.fechaAsignacion == null){
      fechaAsignReal = ""
      return fechaAsignReal
    }
    else{
      spliteando = caso.fechaAsignacion
      fechaAsignSplit = spliteando.split("-")
      diaSplit = fechaAsignSplit[2].split("")
      fechaAsignReal = diaSplit[0]+diaSplit[1]+"/"+fechaAsignSplit[1]+"/"+fechaAsignSplit[0]
      return fechaAsignReal
    }
    
  }

  //FUNCION PARA MOSTRAR LA FECHA CORRECTA DE RECUPERACION DEL CASO
  getFechaRecup(caso: Caso){
    let spliteando
    let fechaAsignSplit
    let fechaAsignReal
    let diaSplit
    if(caso.fechaRecuperacion == null){
      fechaAsignReal = ""
      return fechaAsignReal
    }
    else{
      spliteando = caso.fechaRecuperacion
      fechaAsignSplit = spliteando.split("-")
      diaSplit = fechaAsignSplit[2].split("")
      fechaAsignReal = diaSplit[0]+diaSplit[1]+"/"+fechaAsignSplit[1]+"/"+fechaAsignSplit[0]
      return fechaAsignReal
    }

  }
  openSubirArchivoModal(caso, TIPO_ARCHIVO) {
    /*
    const ref = this.dialogService.open(SubirArchivoModalComponent, {
      data:
      {
        caso: caso,
        tipoArchivo: TIPO_ARCHIVO
      },
      width: '40%',
      showHeader: false,
      dismissableMask: true,
      baseZIndex: 1030
    });

*/
    const ref = this.dialogService.open(EditarModalComponent, {
      data:
      {
        caso: caso,
        tipoArchivo: TIPO_ARCHIVO
      },
      width: '40%',
      showHeader: false,
      dismissableMask: true,
      baseZIndex: 1030
    });
    ref.onClose.subscribe(() => {
      this.ngOnInit()
    })
  }

  openVerArchivoModal(caso, TIPO_ARCHIVO) {
    const ref = this.dialogService.open(ActualizacionesModalComponent, {
      data:
      {
        caso: caso,
        tipoArchivo: TIPO_ARCHIVO
      },
      width: '90%',
      height: '90%',
      showHeader: true,
      dismissableMask: true,
      baseZIndex: 1030,
      style: { "position": "fixed", "overflow": "auto", "max-height": "91%" }
    });
  }

  

  nuevoComentario(evt) {
    const ref = this.dialogService.open(EditarModalComponent, {
      data:
      {
        caso: this.casoSeleccionado,
      },
      width: '40%',
      showHeader: false,
      dismissableMask: true,
      baseZIndex: 1030
    });
    ref.onClose.subscribe(() => {
      this.ngOnInit()
    })
    //console.log(evt)
  }

  editarCaso2(evt) {
    const ref = this.dialogService.open(Editar2ModalComponent, {
      data:
      {
        caso: this.casoSeleccionado,
      },
      width: '85%',
      height: '100%',
      showHeader: true,
      dismissableMask: true,
      baseZIndex: 1030,
      style: { "position": "fixed", "overflow": "auto", "max-height": "91%" }
    });
    ref.onClose.subscribe(() => {
      this.ngOnInit()
    })
  }

  historial(evt) {
    const ref = this.dialogService.open(HistorialModalComponent, {
      data:
      {
        caso: this.casoSeleccionado,
      },
      width: '60%',
      showHeader: false,
      dismissableMask: true,
      baseZIndex: 1030
    });
    ref.onClose.subscribe(() => {
      this.ngOnInit()
    })
  }

  cambiarEstado(evt) {
    const ref = this.dialogService.open(CambiarEstadoComponent, {
      data:
      {
        caso: this.casoSeleccionado,
      },
      width: '60%',
      showHeader: false,
      dismissableMask: true,
      baseZIndex: 1030
    });
    ref.onClose.subscribe(() => {
      this.ngOnInit()
    })
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //console.log(excelBuffer)
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    fileSaver.saveAs(data, fileName  + EXCEL_EXTENSION);
  }
  ngOnInit() {
    this.items = [
      { label: 'Editar', icon: 'pi pi-pencil', command: (event) => this.editarCaso2(event) },
      { label: 'Nuevo Comentario', icon: 'fa fa-commenting-o ', command: (event) => this.nuevoComentario(event) },
      { label: 'Cambiar Estado', icon: 'fa fa-gavel', command: (event) => this.cambiarEstado(event) },
    ];
    this.casoapi.find({ where: { idUsuario: this.usuarioapi.getCurrentId() }, include: [{ relation: 'comentarios', scope: { order: 'createdAt DESC' } }, { relation: 'gastos' }, { relation: 'embargos' }, { relation: 'garantias' }] }).subscribe((casosResponse: Caso[]) => {
      //console.log(casosResponse)
      this.casos = casosResponse;

      this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
        var r: Log = new Log();
        r.createdAt = new Date()
        r.updatedAt = new Date()
        r.idUsuario = us.idUsuario
        r.tipo = 2
        r.contenido = ''
        this.logapi.create(r).subscribe((d) => {
        })
      })

      if (!this.popupClosed) {
        if (!sessionStorage.getItem('popupClosed')) {
          const ref = this.dialogService.open(PopupAbogadoModalComponent, {
            data:
            {
              casos: casosResponse,
            },
            width: '40%',
            showHeader: false,
            dismissableMask: true
          });
          ref.onClose.subscribe(() => {
            this.popupClosed = true
            sessionStorage.setItem('popupClosed', 'y')
          })
        }
      }

      const uniqueacreditado = [...new Set(casosResponse.map(item => item.acreditado))];
      uniqueacreditado.sort();
      uniqueacreditado.forEach(element => {
        this.dropdownacreditado.push({ label: element, value: element })
      });

      const uniquegrupo = [...new Set(casosResponse.map(item => item.grupo))];
      uniquegrupo.sort();
      uniquegrupo.forEach(element => {
        this.dropdowngrupo.push({ label: element, value: element })
      });
    })
    this.usuarioapi.find({ fields: { idUsuario: true, username: true }, include: 'casos' }).subscribe((usuariosl: Usuario[]) => {
      this.usuarios = usuariosl
    })
  }

  update() {
  }

  onEditComplete(event) {
    //console.log("onEditComplete", event);
  }
  getGastos(gastoList: Gasto[]) {
    var value = 0
    if (gastoList.length > 0) {
      value = gastoList.filter(e=>e.isAprobado==true).map(a => a.monto).reduce((a, b) => a + b, 0);
    }
    return value
  }
  log_in() {
    var u: Usuario = new Usuario();
    u.username = "plazola",
      u.password = "123"
    this.usuarioapi.login(u).subscribe((res) => {
      //console.log(res)
    })
  }
}
class tableCaso {
  id: number;
  acreditado: String;
  Castigo_fira: String; //NUEVA COLUMNA
  Castigo_accedde: String;//NUEVA COLUMNA
  Etapa_procesal: String;//NUEVA COLUMNA
  Estatus_mercantil: String;
  Impugnacion_mercantil: String;
  Juzgado_mercantil: String;
  Expediente_mercantil: String;
  Estatus_civil: String;
  Impugnacion_civil: String;
  Juzgado_civil: String;
  Expediente_civil: String;
  Expediente_TOCA: String;
  Sala: String;
  Capital: number;
  Intereses_ordinarios: number;
  Intereses_moratorios: number;
  total: number;
  Capital_efsy: number; //NUEVA COLUMNA
  Intereses_ordinarios_efsy: number; //NUEVA COLUMNA
  Intereses_moratorios_efsy: number; //NUEVA COLUMNA
  total_efsy: number; //NUEVA COLUMNA
  Monto_castigo_por_castigar: number; // NUEVA COLUMNA
  Concepto_castigo: String; //NUEVA COLUMNA
  comentarios: String;
  Bienes_embargados: String;
  inscripcionEmbargo: String;
  garantia: String;
  valorEstimado: number;
  fechaRecuperacion: String;
  fechaAsignacion: String;
  grupo: String;
  honorarios: number;
  honorarios_perito: number; //NUEVA COLUMNA
  honorarios_perito_en_rebeldia: number;//NUEVA COLUMNA
 
}