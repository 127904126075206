import { Mensaje } from './../shared/sdk/models/Mensaje';
import { MensajeApi } from './../shared/sdk/services/custom/Mensaje';
import { Component, OnInit } from '@angular/core';
import { Caso, CasoApi, Gasto, GastoApi, Log, UsuarioApi, LogApi, Usuario } from '../shared/sdk';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-nuevo-gasto',
  templateUrl: './nuevo-gasto.component.html',
  styleUrls: ['./nuevo-gasto.component.scss']
})
export class NuevoGastoComponent implements OnInit {
  text: string;
  results: Caso[];
  selectedCaso: Caso
  msgs: any[] = [];
  gastos: Gasto[]
  u: Usuario = null
  historial = []
  tempMessage: Mensaje;
  messagesList: Mensaje[] = []
  ADMIN_ID = 4
  constructor(public messagesapi: MensajeApi, public casoapi: CasoApi, public gastoapi: GastoApi, public logapi: LogApi, public usuarioapi: UsuarioApi) {
    this.tempMessage = new Mensaje(); //inicializa el nuevo mensaje en caso de que los gastos
    //este deshabilitado 
  }

  ngOnInit() {
    this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
      //console.log(us)
      this.u = us
      if (!this.u.gastosHabilitados) {
        //si tiene los gastos habilitados buscar los mensajes entre el abogado y angel
        this.messagesapi.find({
          where: {
            or:[{and:
          [{ idRemitente:this.ADMIN_ID}, { idDestinatario: this.u.idUsuario }]},{and:
            [{ idRemitente: this.u.idUsuario }, { idDestinatario: this.ADMIN_ID }]}]
          }, order: 'createdAt ASC'
        }).subscribe((mensajesResponse: Mensaje[]) => {
          this.messagesList = mensajesResponse
          //console.log(mensajesResponse)
        }, (err) => {
         // console.log(err)
        });
      }
      //console.log(this.u)
    })
  }
//escribir nuevo mensaje a Angel y enviar correo
  postMessage() {
    this.tempMessage.idRemitente = this.u.idUsuario
    this.tempMessage.idDestinatario = this.ADMIN_ID  ////JETSSY ID
    this.messagesapi.create(this.tempMessage).subscribe((r) => {
      //console.log(r);
     // this.usuarioapi.sendEmail("jvazquez","jvazques@accedde.com",this.u.username).subscribe((r)=>
     //this.usuarioapi.sendEmail("jprieto","jprieto@accedde.com",this.u.username).subscribe((r)=>
     this.usuarioapi.sendEmail("jprieto","jprieto@accedde.com",this.u.username).subscribe((r)=>

      {

        //console.log("RESPONSE");
        //console.log(r);
        
      })
      this.ngOnInit() 
    }, (err) => {
      console.log(err)
    });
  }

  //buscar a que caso seleccionar para agregar el gasto
  search(event) {
    var u = this.usuarioapi.getCurrentId();
    var t: String = "%" + this.text + "%"
    if (this.text.length > 2) {
      this.casoapi.find({ where: { acreditado: { like: t } }, include: [{ relation: 'usuario', scope: { fields: ['username', 'idUsuario'] } }] }).subscribe((c: Caso[]) => {
        var tempr: Caso[] = []
        for (var h of c) {
          if (h.usuario.idUsuario == u) {
            tempr.push(h)
          }
        }
        this.results = tempr
      })
    }
  }
//si es la misma persona que mando el mensaje poner mensajes a la derecha (whastaapp style)
  isSamePerson(m: Mensaje) {
    if (m.idRemitente == this.u.idUsuario) {
      return true
    } else {
      return false;
    }
  }
  //llamado cuando selecciona un caso de la lista
  isSelected(evt) {
    this.selectedCaso = evt
    this.gastos = [{ aplicarMediante: 0, tipoGasto: 0, caso: this.selectedCaso, monto: 0, concepto: '', archivo: null, idCaso: this.selectedCaso.idCaso, createdAt: new Date(), updatedAt: new Date(), idGasto: null, isAprobado: false }]
  }

  //agregar el gasto a la lista para rellenar
  addGasto() {
    this.gastos.push({ aplicarMediante: 0, tipoGasto: 0, caso: this.selectedCaso, monto: 0, concepto: '', archivo: null, idCaso: this.selectedCaso.idCaso, createdAt: new Date(), updatedAt: new Date(), idGasto: null, isAprobado: false })
  }

  uploadGastos() {
    //eliminar gasto si tiene 0 de monto para evitar errores
    function isNotZero(element: Gasto, index, array) {
      return (element.monto > 0);
    }

    this.gastos = this.gastos.filter(isNotZero);
    this.gastoapi.createMany(this.gastos).subscribe((d) => {
      //crea los gastos en la base 
      this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
        //log de la accion
        var r: Log = new Log();
        r.createdAt = new Date()
        r.updatedAt = new Date()
        r.idUsuario = us.idUsuario
        r.tipo = 18
        r.contenido = JSON.stringify(d)
        this.logapi.create(r).subscribe((d) => {
        })
      })
      this.msgs.push({ severity: 'success', summary: 'Exito', detail: 'Gastos Generados Exitosamente' });
    }, (error) => {
      this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Ocurrio un problema al actualizar caso, contactar a sistemas' });
    })
  }
}
