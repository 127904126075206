import { Component, OnInit } from "@angular/core";
import { NotasApi, Notas, Caso, Usuario, UsuarioApi } from "../shared/sdk";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/api";
import * as moment from "moment";

@Component({
  selector: "crear-notas",
  templateUrl: "./crear-notas.component.html",
  styleUrls: ["./crear-notas.component.scss"]
})
export class CrateNoteComponent implements OnInit {
  casoSelecionado: Caso;
  usuario: Usuario;
  objNota: Notas;
  diasTotales: Number;
  msg = [];
  username: String;
  constructor(
    public notasApi: NotasApi,
    public config: DynamicDialogConfig,
    public usuarioapi: UsuarioApi
  ) {
    this.objNota = new Notas();
  }

  ngOnInit() {
    this.casoSelecionado = this.config.data.caso;
    this.usuario = this.config.data.usuario;
    this.usuarioapi.getCurrent().subscribe((user) => {
      this.username = user.username;
    });
  }

  createNote() {
    let note = {
      asunto: this.objNota.asunto,
      createdAt: new Date(),
      idCaso: this.casoSelecionado.idCaso,
      nota: this.objNota.nota,
      role: this.username
    };
    this.notasApi.create(note).subscribe(
      (response) => {
        this.objNota.asunto = null;
        this.objNota.nota = null;
        this.msg.push({
          severity: "success",
          summary: "Exito",
          detail: "Nota creada correctamente"
        });
      },
      (err) =>
        this.msg.push({
          severity: "danger",
          summary: "Error",
          detail: "No se pudo guardar la nformacion"
        })
    );
  }
}
