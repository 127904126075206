import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/api';
import { Caso, Gasto, GastoApi, CasoApi, LogApi, Log, UsuarioApi, Usuario, GastoEliminadoApi, GastoEliminado } from '../shared/sdk';
import * as fileSaver from 'file-saver';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DesaprobarModalComponent } from '../desaprobar-modal/desaprobar-modal.component';

@Component({
  selector: 'app-aprobar-modal',
  templateUrl: './aprobar-modal.component.html',
  styleUrls: ['./aprobar-modal.component.scss'],
  providers: [DialogService]

})
export class AprobarModalComponent implements OnInit {
  
  msgs = []
  caso: Caso
  constructor(public dialogService: DialogService, public gastoeliminadoapi: GastoEliminadoApi, private http: HttpClient, public usuarioapi: UsuarioApi, public logapi: LogApi, public ref: DynamicDialogRef, public config: DynamicDialogConfig, public gastoapi: GastoApi, public casoapi: CasoApi) {
    this.caso = this.config.data.caso; //agarra el caso pasado

  }

  
  ngOnInit() {
   
  }
//si se elimina refresc la pantalla
//SE HACE TAMBIEN "location.reload" EN EL ELSE PARA QUE SE PUEDA AGREGAR SIN ERROR A LA SECCION DE "gasto eliminado"
  refresh() {
    this.casoapi.findById(this.config.data.caso.idCaso, { include: 'gastos' }).subscribe((c: Caso) => {
      if (c.gastos.length == 0) {
        location.reload();
      } else {
        this.caso = c
        location.reload();
      }

    })
  }
//cierra el modal/popup
  close() {
    this.ref.close()
  }
//metodo de descarga
  download(f, u) {
    this.downloadFile(f, u).subscribe(response => {
      let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      //window.location.href = response.url;
      this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
        var r: Log = new Log();
        r.createdAt = new Date()
        r.updatedAt = new Date()  
        r.idUsuario = us.idUsuario
        r.tipo = 3
        r.contenido = JSON.stringify(f)
        this.logapi.create(r).subscribe((d) => {
        })
      })
      fileSaver.saveAs(blob, f);
    }), error => //console.log('Error downloading the file'),
      () => {
 
      }
  }
//parte del metodo de descarga
  downloadFile(f, u): Observable<Blob> {
    var i = u
    var url = 'https://api1.accedde.mx/api/containers/' + i + '/download/' + f
    //var url = 'http://localhost:3000/api/containers/' + i + '/download/' + f
    return this.http.get(url, { responseType: 'blob' });
  }
//si se presiona "no aprobar"  se abre otro modal para escribir el "motivo de desaprobacion"
  noAprobar(caso: Caso, i: number) {


    const ref2 = this.dialogService.open(DesaprobarModalComponent, {

      width: '50%',
      showHeader: false,
      dismissableMask: true,
      baseZIndex: 30001
    });
    ref2.onClose.subscribe((comentario: string) => {
      //una vez se escriba y cierre el modal se agrra el comentario y se usa para construit
      //el objecto de "gastodeliminado", se sube a la base y se crea el log de este
      if (comentario != null && comentario != '') {
        var g = new GastoEliminado()
        g.idCaso = caso.idCaso
        g.monto = this.caso.gastos[i].monto
        g.concepto = this.caso.gastos[i].concepto
        g.monto = this.caso.gastos[i].monto
        g.aplicarMediante = this.caso.gastos[i].aplicarMediante
        g.comentarioDesaprobacion = comentario
        this.gastoeliminadoapi.create(g).subscribe((ga)=>
        {
          this.gastoapi.deleteById(this.caso.gastos[i].idGasto).subscribe((d) => {
            this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
              var r: Log = new Log()
              r.createdAt = new Date()
              r.updatedAt = new Date()
              r.idUsuario = us.idUsuario
              r.tipo = 4
      
              r.contenido = JSON.stringify(this.caso.gastos[i])
              this.logapi.create(r).subscribe((d) => {
              })
              //console.log(d)
              this.refresh()
            })
          }, (error) => {
            //console.log(error)
          })
        })
        
      }
    })
  }
//si se aprueba se actualiza el objeto de gasto, lo sube a la base y genera el log
  aprobar(casoa: Caso, i: number) {
    this.caso.gastos[i].isAprobado = true
    this.gastoapi.upsert(this.caso.gastos[i]).subscribe((d) => {
      this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
        //console.log(d)
        var r: Log = new Log()
        r.createdAt = new Date()
        r.updatedAt = new Date()
        r.idUsuario = us.idUsuario
        r.tipo = 5
        r.contenido =JSON.stringify(this.caso.gastos[i])
        this.logapi.create(r).subscribe((d) => {
        })
      })
    }, (error) => {
      //console.log(error)
    })
    
  }

  //cambia el monto en caso de que lo apruebe pero cambie el monto aprobado
  onContentChanged(evt, i) {
    this.caso.gastos[i].monto = Number.parseFloat(evt.target.textContent)

    //console.log(evt)
  }
}
