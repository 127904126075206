import { Component, OnInit } from '@angular/core';
import { DiasInhabilesApi, DiasInhabiles, UsuarioApi, CasoApi, Caso } from '../shared/sdk';
import { DialogService, MessageService } from 'primeng/primeng';
import { AsignarDiasInahabilesComponent } from '../asignar-dias-inhabiles/asignar-dias-inhabiles.component';
import * as moment from 'moment';


@Component({
  selector: 'app-dias-inhabiles',
  templateUrl: './dias-inhabiles.component.html',
  styleUrls: ['./dias-inhabiles.component.scss'],
  providers: [DialogService, MessageService]
})

export class DiasInahabilesComponent implements OnInit{
  diasInhabiles: DiasInhabiles [];
  diasPendientes:DiasInhabiles[]; 
  objDiasInhabiles: DiasInhabiles;
  diasTotales: Number;
  msg = [];
  indexRow: Number;
  showTable: boolean = false;
  registerId:any;
  updateRecoveryDate: any;
  casoId: Caso;
  BloqueadorBoton: boolean = false;
  constructor(public diasInhabilesApi: DiasInhabilesApi, public casoApi: CasoApi, public dialogService: DialogService) {
    this.objDiasInhabiles = new DiasInhabiles()
  }
  
  //obtener historico de dias inhabiles
  ngOnInit() {
    this.getDaysOff();
  }           

  getDaysOff(){
    this.diasInhabilesApi.find().subscribe((dias: DiasInhabiles[]) => {
    this.diasInhabiles = dias;
    })
  }

  getCountDays(periodoI, periodoF): any{
    if(periodoI && periodoF){            
      let periodoInicial = moment(periodoI)            
      let periodoFinal = moment(periodoF)
      this.diasTotales = periodoFinal.diff(periodoInicial, 'days')
      return `${this.diasTotales}  dias Inhabiles`;
    }
  }

  dateFormat(date:Date): any{
    return moment(date).format('L');
  }

  //abre el modal para ver archivo con los datos necesarios 
  openVerModal(){
  
    const ref = this.dialogService.open(AsignarDiasInahabilesComponent, {
      width: '40%',
      header: "Asignar dias inhabiles",
      showHeader: true,
      dismissableMask: true,
      baseZIndex: 3000,
      style: { "position": "fixed", "overflow": "auto","max-height": "50%" }
    });
  }

}