import { Archivo } from './../shared/sdk/models/Archivo';
import { ArchivoApi } from './../shared/sdk/services/custom/Archivo';
import { Caso } from './../shared/sdk/models/Caso';
import { LogApi } from './../shared/sdk/services/custom/Log';
import { UsuarioApi } from './../shared/sdk/services/custom/Usuario';
import { HttpClient } from '@angular/common/http';
import { Usuario } from './../shared/sdk/models/Usuario';
import { Log } from './../shared/sdk/models/Log';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';
import { Observable } from 'rxjs';
import * as fileSaver from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-ver-archivos-modal',
  templateUrl: './ver-archivos-modal.component.html',
  styleUrls: ['./ver-archivos-modal.component.scss']
})
export class VerArchivosModalComponent implements OnInit {
  caso: Caso
  tipoArchivo
  id;
  listaArchivos: any[] = []
  archivoSeleccionado:Archivo = null
  isSelected:boolean[]=[]

  
  constructor(public sanitizer: DomSanitizer, public archivoapi: ArchivoApi, private usuarioapi: UsuarioApi, public logapi: LogApi, private http: HttpClient, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
  //intercepta los datos del caso pasados al modal
    this.caso = this.config.data.caso;
    this.tipoArchivo = this.config.data.tipoArchivo;
    //console.log(this.caso);
    //console.log(this.tipoArchivo);
    //tipo de archivo se usa para no crear varias vistas de lo mismo, si se pone 0 significa que
    //traiga todos los archivos, sino entonces filtre por el numero que contenga "tipoArchivo"
    //que es una propiedad del objeto archivo
  }

  ngOnInit() {

    this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
      
      this.id = us.idUsuario
      if(this.tipoArchivo == 0)
      {
        this.archivoapi.find({ where: {  idCaso: this.caso.idCaso } }).subscribe((r: Archivo[]) => {
          this.listaArchivos = r
          //inicializacion de arrays para hacer funcionar el front
          for(var archivo of this.listaArchivos)
          {
            this.isSelected.push(false)
          }

          this.isSelected[0] = true
          this.archivoSeleccionado = this.listaArchivos[0];
     
          //console.log(r)
        }, (err) => {
          console.log(err)
        });
      }else{
        this.archivoapi.find({ where: { and: [{ idCaso: this.caso.idCaso }, { tipo: this.tipoArchivo }] } }).subscribe((r: Archivo[]) => {
          this.listaArchivos = r
           //inicializacion de arrays para hacer funcionar el front
          for(var archivo of this.listaArchivos)
          {
            this.isSelected.push(false)
          }
          this.isSelected[0] = true
          this.archivoSeleccionado = this.listaArchivos[0];//selecciona el primer archivo y muestralo en el front
     
          //console.log(r)
        }, (err) => {
          console.log(err)
        });
      }
    
    })
  }

  //cambiar el archivo mostrado cuando se seleccione otro
setArchivoSeleccionado(archivo:Archivo)
{
this.archivoSeleccionado = archivo
}
 //sanitiza la url
  getSource(idUsuario, filename) {
    var url = 'https://api1.accedde.mx/api/containers/' + idUsuario + '/download/' + filename
    //var url = 'http://localhost:3000/api/containers/' + idUsuario + '/download/' + filename
    //console.log(this.sanitizer.bypassSecurityTrustUrl(url))
    return url
  }


//descarga el archivo seleccionado
  download(f, u) {
    this.downloadFile(f, u).subscribe(response => {
      let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
        var r: Log = new Log();
        r.createdAt = new Date()
        r.updatedAt = new Date()
        r.tipo = 3
        r.idUsuario = us.idUsuario
        r.contenido = JSON.stringify(f)
        this.logapi.create(r).subscribe((d) => {
        })
      })
      fileSaver.saveAs(blob, f);
    }), error => console.log('Error downloading the file'),
      () => {
      }
  }
//descarga el archivo seleccionado
  downloadFile(f, u): Observable<Blob> {
    var i = u
    var url = 'https://api1.accedde.mx/api/containers/' + i + '/download/' + f
    //var url = 'http://localhost:3000/api/containers/' + i + '/download/' + f
    return this.http.get(url, { responseType: 'blob' });
  }

  isPDF(archivoseleccionado: Archivo) {
    if (archivoseleccionado.path.endsWith('pdf') || archivoseleccionado.path.endsWith('PDF')) {
      return true
    } else {
      return false
    }
  }
  
}
