import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';

@Component({
  selector: 'app-desaprobar-modal',
  templateUrl: './desaprobar-modal.component.html',
  styleUrls: ['./desaprobar-modal.component.scss']
})
export class DesaprobarModalComponent implements OnInit {
  comentario = ''
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit() {
  }
  //tldr solo es un campo de texto donde pone el motivo de la desaprobacion
  agregar() {
    this.ref.close(this.comentario)
  }
}
