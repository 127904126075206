import { Component, OnInit } from '@angular/core';
import { Caso, Notas, NotasApi } from '../shared/sdk';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';
import * as moment from 'moment';


@Component({
  selector: 'historial-notas',
  templateUrl: './historial-notas.component.html',
  styleUrls: ['./historial-notas.component.scss']
})

export class NoteHistoryComponent implements OnInit{
  notas: Notas;
  casoSeleccionado: Caso;
  showIdNote:Boolean = false;
  notaSelecionada: Number;
  constructor(public notasApi: NotasApi, public config: DynamicDialogConfig) {} 
  
  ngOnInit() {
    this.casoSeleccionado = this.config.data.caso.idCaso;
     this.notasApi.find({where:{idCaso: this.casoSeleccionado, order: 'idDiasInhabiles ASC'}}).subscribe((notas:any) => {
        this.notas = notas;
     })
  }   
  
  selectedIdNote(noteData){
    this.notaSelecionada = noteData;
    this.showIdNote = true;
  }
   
  setDate(date){
    return moment(date).format('lll');
  }
}
