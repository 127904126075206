import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';
import {CalendarModule} from 'primeng/calendar';
import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';
import { FormsModule } from '@angular/forms';
import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';
import { TableModule } from 'primeng/table'
import { SidebarModule } from 'primeng/sidebar'
import { DropdownModule } from 'primeng/dropdown'
import { MultiSelectModule } from 'primeng/multiselect'
import { ContextMenuModule } from 'primeng/contextmenu'
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import {InputSwitchModule} from 'primeng/inputswitch';
import { SDKBrowserModule } from './shared/sdk';
import { Angular2FontawesomeModule } from 'angular2-fontawesome/angular2-fontawesome'
import { UserIdleModule } from 'angular-user-idle';

/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CarteraComponent } from './cartera/cartera.component';
import { LogoutComponent } from './logout/logout.component';
import { PopupModalComponent } from './popup-modal/popup-modal.component';
import { CarteraAbogadoComponent } from './cartera-abogado/cartera-abogado.component';
import { EditarModalComponent } from './editar-modal/editar-modal.component';
import { Editar2ModalComponent } from './editar2-modal/editar2-modal.component';
import { HistorialModalComponent } from './historial-modal/historial-modal.component';
import { NuevoCasoComponent } from './nuevo-caso/nuevo-caso.component';
import { CambiarEstadoComponent } from './cambiar-estado/cambiar-estado.component';
import { AsignarCasoComponent } from './asignar-caso/asignar-caso.component';
import { NuevoDespachoComponent } from './nuevo-despacho/nuevo-despacho.component';
import { InhabilitarDespachoComponent } from './inhabilitar-despacho/inhabilitar-despacho.component';
import { DiasInahabilesComponent } from './dias-inhabiles/dias-inhabiles.component';
import { BitacoraComponent } from './bitacora/bitacora.component';
import { ResumenComponent } from './resumen/resumen.component';
import { EditarCasoComponent } from './editar-caso/editar-caso.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { GastosAbogadoComponent } from './gastos-abogado/gastos-abogado.component';
import { GastosComponent } from './gastos/gastos.component';
import { NuevoGastoComponent } from './nuevo-gasto/nuevo-gasto.component';
import { InitComponent } from './init/init.component';
import { AprobarGastosComponent } from './aprobar-gastos/aprobar-gastos.component';
import { AprobarModalComponent } from './aprobar-modal/aprobar-modal.component';
import { ComprobarModalComponent } from './comprobar-modal/comprobar-modal.component';
import { AlertasComponent } from './alertas/alertas.component';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import {ProgressBarModule} from 'primeng/progressbar';
import { DesaprobarModalComponent } from './desaprobar-modal/desaprobar-modal.component';
import { ReportePrincipalComponent } from './reporte-principal/reporte-principal.component';
import { NuevoUsuarioComponent } from './nuevo-usuario/nuevo-usuario.component';
import { BloquearUsuarioComponent } from './bloquear-usuario/bloquear-usuario.component';
import { SubirArchivoModalComponent } from './subir-archivo-modal/subir-archivo-modal.component';
import { VerArchivosModalComponent } from './ver-archivos-modal/ver-archivos-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MensajesModalComponent } from './mensajes-modal/mensajes-modal.component';
import { ActualizacionesModalComponent } from './actualizaciones-modal/actualizaciones-modal.component';
import { VerMensajesComponent } from './ver-mensajes/ver-mensajes.component';
import { PopupAbogadoModalComponent } from './popup-abogado-modal/popup-abogado-modal.component';
import { VerInformeComponent } from './ver-informe/ver-informe.component';
import { AsignarDiasInahabilesComponent } from './asignar-dias-inhabiles/asignar-dias-inhabiles.component';
import { CrateNoteComponent } from './crear-nota/crear-notas.component';
import { NoteHistoryComponent } from './historial-notas/historial-notas.component'


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    CarteraComponent,
    LogoutComponent,
    PopupModalComponent,
    CarteraAbogadoComponent,
    EditarModalComponent,
    Editar2ModalComponent,
    HistorialModalComponent,
    NuevoCasoComponent,
    CambiarEstadoComponent,
    AsignarCasoComponent,
    NuevoDespachoComponent,
    InhabilitarDespachoComponent,
    DiasInahabilesComponent,
    BitacoraComponent,
    ResumenComponent,
    EditarCasoComponent,
    GastosAbogadoComponent,
    GastosComponent,
    NuevoGastoComponent,
    InitComponent,
    AprobarGastosComponent,
    AprobarModalComponent,
    ComprobarModalComponent,
    AlertasComponent,
    DesaprobarModalComponent,
    ReportePrincipalComponent,
    NuevoUsuarioComponent,
    BloquearUsuarioComponent,
    SubirArchivoModalComponent,
    VerArchivosModalComponent,
    MensajesModalComponent,
    ActualizacionesModalComponent,
    VerMensajesComponent,
    PopupAbogadoModalComponent,
    VerInformeComponent,
    AsignarDiasInahabilesComponent,
    CrateNoteComponent,
    NoteHistoryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    TableModule,
    SidebarModule,
    DropdownModule,
    ContextMenuModule,
    DynamicDialogModule,
    MultiSelectModule,
    SDKBrowserModule.forRoot(),
    Angular2FontawesomeModule,
    AutoCompleteModule,
    ProgressBarModule,
    MessageModule,
    MessagesModule,
    CalendarModule,
    InputSwitchModule,
    UserIdleModule.forRoot({idle: 590, timeout: 10, ping: 120}),
    PdfViewerModule

  ],
  providers: [
    NavigationItem,
    {provide: LocationStrategy, useClass: HashLocationStrategy}

  ], entryComponents: [
    EditarModalComponent,
    HistorialModalComponent,
    Editar2ModalComponent,
    CambiarEstadoComponent,
    PopupModalComponent,
    AprobarModalComponent,
    ComprobarModalComponent,
    DesaprobarModalComponent,
    SubirArchivoModalComponent,
    VerArchivosModalComponent,
    ActualizacionesModalComponent,
    MensajesModalComponent,
    PopupAbogadoModalComponent,
    VerInformeComponent,
    AsignarDiasInahabilesComponent,
    CrateNoteComponent,
    NoteHistoryComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
