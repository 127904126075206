import { Usuario } from 'src/app/shared/sdk';
import { Component, OnInit } from '@angular/core';
import { UsuarioApi, CasoApi, Caso } from '../shared/sdk';

@Component({
  selector: 'app-resumen',
  templateUrl: './resumen.component.html',
  styleUrls: ['./resumen.component.scss']
})
export class ResumenComponent implements OnInit {

  usuarios: Usuario[]
  usersxcasos: any[][] = []
  userxtotales: any[][] = []
  casos: Caso[] = []
  t1: number[] = []
  t2: number[] = []
  t3: number[] = []
  tff: number[] = []
  pgb: number[] = []
  pbb: number[] = []
  ae: number[] = [] //ae es GRUPAL
  xf: number[] = [] // ya no se mostrara

  global: number[] = [0, 0, 0, 0, 0, 0, 0, 0] //SE QUITA DE MOEMENTO UN "0" EL CUAL PERTENECIA A XF.
  gastos: any[] = []
  totalHonorario: any[] = [] //-- Se agrega para mostrar sumatoria de honorarios por abogado
  totalGastosAbo: any[] = [] //-- Se agrega para mostrar sumatoria de gastos pedidos por el abogado y aprobados
  totalGastosAsign: any[] = [] //-- Se agrega para mostar sumatoria de gastos asignados al abogado
  honorarioPandemia: any
  honorarioPrePandemia: any
  honorarioPostPandemia: any

  actualDate = new Date()

  constructor(public casoapi: CasoApi, public usuarioapi: UsuarioApi) {

  }

  ngOnInit() {
    //hace lo mismo que los popup-modal de arriba, divide el capital de los casos por cada abogado en distintos tramos 
    this.usuarioapi.find({ where: { tipo: 2 } }).subscribe((users: Usuario[]) => {
      this.usuarios = users
      for (var x of users) {

        this.usersxcasos.push([]);
        this.t1.push(0)
        this.t2.push(0)
        this.t3.push(0)
        this.pbb.push(0)
        this.pgb.push(0)
        this.tff.push(0)
        this.ae.push(0)
        this.xf.push(0)
      }

      this.casoapi.find({ include: [{ relation: 'usuario' }, { relation: 'gastos' }, { relation: 'gastosAsignacion' }] }).subscribe((casos: Caso[]) => {
        var tempglobal = [0, 0, 0, 0, 0, 0, 0, 0] 
        var tempgastos = []
        var tempHonorario = []
        var tempGastosAbo = []
        var tempGastosAsign = []
        
        for (var c of casos) {
          if(c.recuperado2==false)
          {
            tempglobal[c.tramo] += c.capital +c.cap_efsy
           

          }
        }

        //todo esto es mas para formatear y sanitizar los datos para su visualizacion
        //convertir arrays, sumarlos, castearlos etc, sugiero no mover mucho mas que lo necesario

        this.global = tempglobal
        
        for (var i = 0; i < users.length; i++) {
          //hace lo mismo que el capital pero con los gastos  para sacar un total de los gastos de cada abogado
          tempgastos.push(0)
          tempHonorario.push(0)
          tempGastosAbo.push(0)
          tempGastosAsign.push(0)
          for (var c of casos) {
            if (c.idUsuario == users[i].idUsuario) {
              //console.log(users[i].username);
              var gastosabogado = c.gastos.map(b => b.monto).reduce((a, b) => a + b, 0)
              var gastosHonorarios = this.getAcumuladoHonorarios(c.fechaAsignacion, c.honorario, c.fechaDescPandemia, c.honorarioPandemia, c.fechaFinPandemia)
              //console.log(gastosHonorarios);
              var gastosAsignacion = c.gastosAsignacion.map(b => b.monto).reduce((a, b) => a + b, 0)
              tempHonorario[i] += gastosHonorarios //muestra sumatoria de el total de honorario del abogado
              tempGastosAbo[i] += gastosabogado //muestra sumatoria de los gastos pedidos por el abogado y aprobados
              tempGastosAsign[i] += gastosAsignacion // Muestra sumatoria de los gastos asignados al abogado
              tempgastos[i] += gastosabogado + gastosHonorarios + gastosAsignacion
              //console.log(tempgastos[i])
              if(c.recuperado2==false)
              {
                this.usersxcasos[i].push(c)

              }
            }
          }
          
        }
        this.gastos = tempgastos
        this.totalHonorario = tempHonorario
        this.totalGastosAbo = tempGastosAbo
        this.totalGastosAsign = tempGastosAsign
        this.divide()
      })
    })
  }
  getGastos(user: Usuario) {
    var data: any = {};

  }
  //acumulador conforme a la fecha actual a la fecha de creacion del caso, obtiene los meses de diferencia y
  //multiplica por el monto que se le paga al abogado para saber cuanto se le ha pagado por ese caso desde
  //que comenzo a trabajar en el.
  // Se agrega las condiciones para hacer la sumatoria de honorarios con descuento de la pandemia + honorarios sin descuento
  getAcumuladoHonorarios(fecha, monto, fechaIniPandemia, montoPandemia, fechaFinPandemia) {
    let prepandemiaINT: any
    let pandemiaINT: any
    let postpandemiaINT: any
  if(fecha){
   
          //Primero verifica que el caso no tenga fecha de inicio de pandemia
          if(fechaIniPandemia != null && montoPandemia != null){
            this.honorarioPrePandemia = 0
            this.honorarioPandemia = 0
            this.honorarioPostPandemia = 0
            
            //-- Verifica que la fecha en que inicio el caso sea menor a la fecha del inicio de la pandemia
            if(new Date(fecha) < new Date(fechaIniPandemia)){

              //--Aqui verifica si la fecha actual es menor a la fecha del inicio de la pandemia
              //-- Seria raro que entre en esta condicion ya que la pandemia inicio en el 2020.
              if(this.actualDate<new Date(fechaIniPandemia)){
                let m = Math.abs(+new Date(fecha) - +this.actualDate)
                m = m / 1000 / 60 / 60 / 24 / 30
                this.honorarioPrePandemia = (Number.parseInt(m.toString()) * monto).toFixed(0)
              }
              //--Si la fecha actual ya es mayor a la fecha de inicio de la pandemia, entonces ya hara el calculo para obtener
              // el honorario pre-pandemia
              else if(this.actualDate>=new Date(fechaIniPandemia)){
                let m = Math.abs(+new Date(fecha) - +new Date(fechaIniPandemia))
                m = m / 1000 / 60 / 60 / 24 / 30
                this.honorarioPrePandemia = (Number.parseInt(m.toString()) * monto).toFixed(0)
              }
            }

            //-- Si la fecha actual es mayor o igual a la fecha de inicio de la pandemia, hara el calculo
            // desde la fecha del inicio de la pandemia con la fecha actual.
            if(this.actualDate >= new Date(fechaIniPandemia)){
              let m = Math.abs(+new Date(fechaIniPandemia) - +this.actualDate)
              m = m / 1000 / 60 / 60 / 24 / 30
              this.honorarioPandemia =(Number.parseInt(m.toString()) * montoPandemia).toFixed(0)
            }
            //---- HASTA ESTE PUNTO SE DA EL HONORARIO PRE PANDEMIA Y HONORARIO EN PANDEMIA ----//
            
            //-- Aqui verifica que el caso tenga fecha para finalizar el honorario por pandemia
            
                  if(fechaFinPandemia != null){
                    
                    //-- Aqui verifica que la fecha actual este dentro del rango entre la fecha de inicio de pandemia
                    //  y la fecha de finalizacion de la pandemia, si la fecha actua estuviera en ese rango
                    // entonces el honorario por pandemia seguiria vigente.
                    if(new Date(fechaFinPandemia)>this.actualDate && new Date(fechaIniPandemia)<= this.actualDate){
                      this.honorarioPandemia = this.honorarioPandemia
                      this.honorarioPostPandemia = 0;
                    }
                    
                    // -- Aqui en caso de que la fecha actual ya este fuera del rango de la fecha de inicio y fin de pandemia
                    //   se hara el calculo para el honorario por pandemia total y ademas el calculo 
                    //   del honorario post pandemia para el caso
                    else if(new Date(fechaFinPandemia)<=this.actualDate && new Date(fechaIniPandemia)< this.actualDate){
                      let m = Math.abs(+new Date(fechaIniPandemia) - +new Date(fechaFinPandemia))
                      let m2 = Math.abs(+new Date(fechaFinPandemia) - +this.actualDate)
                      m = m / 1000 / 60 / 60 / 24 / 30
                      this.honorarioPandemia = (Number.parseInt(m.toString()) * montoPandemia).toFixed(0)

                      m2 = m2 / 1000 / 60 / 60 / 24 / 30
                      this.honorarioPostPandemia = (Number.parseInt(m2.toString()) * monto).toFixed(0)
                    }

                  }
                  else{
                    this.honorarioPostPandemia = 0;
                  }
            
                  //-- Al final capta las sumatorias de cada tipo de honorario
            prepandemiaINT = parseFloat(this.honorarioPrePandemia)
            pandemiaINT = parseFloat(this.honorarioPandemia)
            postpandemiaINT = parseFloat(this.honorarioPostPandemia)

                  //-- Por ultimo suma las sumatorias de los 3 honorarios.
            return prepandemiaINT+pandemiaINT+postpandemiaINT
          }
          else{
            var m = Math.abs(+new Date(fecha) - +this.actualDate)
            m = m / 1000 / 60 / 60 / 24 / 30
            //console.log(m);
            return Number.parseInt((Number.parseInt(m.toString()) * monto).toFixed(0))
          }
  }
  
  else{
  return 0;
}
    
}


  divide() {
    //va organizando los gastos por tramo
    //SE AGREGA EL CAPITAL EFSY A LA SUMATORIA
    for (var i = 0; i < this.usersxcasos.length; i++) {
      for (var c of this.usersxcasos[i]) {
       
          switch (c.tramo) {
            case 0:
              this.t1[i] = this.t1[i] + c.capital + c.cap_efsy
              break;
            case 1:
              this.t2[i] = this.t2[i] + c.capital + c.cap_efsy
              break;
            case 2:
              this.t3[i] = this.t3[i] + c.capital + c.cap_efsy
              break;
            case 3:
              this.tff[i] = this.tff[i] + c.capital + c.cap_efsy
              break;
            case 4:
              this.pgb[i] = this.pgb[i] + c.capital + c.cap_efsy
              break;
            case 5:
              this.pbb[i] = this.pbb[i] + c.capital + c.cap_efsy
              break;
            case 6:
              this.ae[i] = this.ae[i] + c.capital + c.cap_efsy
              break;
            case 7:
             this.xf[i] = this.xf[i] + c.capital + c.cap_efsy
              break;
            default:
              break;
          }
        }
        
      
    }
  }
}
