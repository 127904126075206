import { Component, OnInit } from '@angular/core';
import { UsuarioApi, LogApi, Usuario, Log } from '../shared/sdk';

@Component({
  selector: 'app-bloquear-usuario',
  templateUrl: './bloquear-usuario.component.html',
  styleUrls: ['./bloquear-usuario.component.scss']
})
export class BloquearUsuarioComponent implements OnInit {
  usuarios: Usuario[]
  msgs = []
  constructor(public usuarioapi: UsuarioApi, public logapi: LogApi) { }
  ngOnInit() {
    
    this.usuarioapi.find({ where: { tipo: { neq: 1 } } }).subscribe((u: Usuario[]) => {
      this.usuarios = u
    })
  }
bloquearUsuario(u)
{
  //TLDR: cambia la variable "activo" de un usuario a false/true y genera el log
  this.usuarioapi.patchOrCreate(u).subscribe((u:Usuario)=>
  {
    if(u.activo)
    {
      this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
        var r: Log = new Log();
        r.createdAt = new Date()
        r.updatedAt = new Date()
        r.idUsuario = us.idUsuario
        r.tipo = 20
        r.contenido = JSON.stringify(u)
        this.logapi.create(r).subscribe((d) => {
      
        })	
      })
      this.msgs.push({severity:'success', summary:'Exito', detail:'Usuario Rehabilitado '+u.username});
    }else{
      this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
        var r: Log = new Log();
        r.createdAt = new Date()
        r.updatedAt = new Date()
        r.idUsuario = us.idUsuario
        r.tipo = 21
        r.contenido = JSON.stringify(u)
        this.logapi.create(r).subscribe((d) => {
      
        })	
      })
      this.msgs.push({severity:'success', summary:'Exito', detail:'Usuario '+u.username+' deshabilitado '});
    }
  },(error)=>
  {
    this.msgs.push({severity:'error', summary:'error', detail:'No se pudo modificar el abogado '+u.username});

  })
}
}
