import { Component, OnInit } from "@angular/core";
import {
  DiasInhabilesApi,
  DiasInhabiles,
  Caso,
  CasoApi,
  ComentarioApi,
  Comentario,
} from "../shared/sdk";
import * as moment from "moment";

@Component({
  selector: "app-dias-inhabiles",
  templateUrl: "./asignar-dias-inhabiles.component.html",
  styleUrls: ["./asignar-dias-inhabiles.component.scss"],
})
export class AsignarDiasInahabilesComponent implements OnInit {
  diasInhabiles: DiasInhabiles[];
  diasPendientes: DiasInhabiles[];
  objDiasInhabiles: DiasInhabiles;
  diasTotales: Number;
  msg = [];
  msg2 = [];
  comentarios: Comentario[];
  updateRecoveryDate: any;
  constructor(
    public diasInhabilesApi: DiasInhabilesApi,
    public casoApi: CasoApi,
    public comentarioApi: ComentarioApi
  ) {
    this.objDiasInhabiles = new DiasInhabiles();
  }

  ngOnInit() {}

  assignDays() {
    let objectAssign = {
      diasInhabiles: Number(this.diasTotales),
      periodoInicial: this.objDiasInhabiles.periodoInicial,
      periodoFinal: this.objDiasInhabiles.periodoFinal,
      createdAt: new Date(),
      updatedAt: new Date(),
      estado: "Aprobado"
    };
    let error = false;
    if (objectAssign.periodoInicial && objectAssign.periodoFinal) {
      if (objectAssign.diasInhabiles > 0) {
        this.comentarioApi.find().subscribe((comentarios: any) => {
            comentarios.map((comentario) => {
              this.updateRecoveryDate = moment(comentario.createdAt)
                .add(objectAssign.diasInhabiles, "days");
                this.comentarioApi.upsert({ ...comentario, createdAt: this.updateRecoveryDate })
                .subscribe((response) => {},
                  (err) => { error = true }
            )});
            error ? this.msg2.push({ severity: "danger", summary: "Error", detail: "No se pudieron actualizar la fechas" })
            : this.msg2.push({ severity: "success", summary: "Exito", detail: "Fechas Actualizadas correctamente" });
        },
        (err) => console.log("error", err));
        this.diasInhabilesApi.create(objectAssign).subscribe(
          (response) => {
            this.diasTotales = null;
            this.objDiasInhabiles.periodoInicial = null;
            this.objDiasInhabiles.periodoFinal = null;
            this.msg.push({severity: "success", summary: "Exito", detail: "dias asignados correctamente" });
          },
          (err) =>
            this.msg.push({ severity: "danger", summary: "Error", detail: "No se pudo guardar la informacion" })
        ),(err) => console.log("error", err);
      } else {
        this.msg.push({ severity: "danger", summary: "Error", detail: "El periodo final no puede ser anterior o igual al periodo inicial" });
      }
    } else {
      this.msg.push({ severity: "danger", summary: "Error", detail: "Las Fechas no pueden estar vacias" });
    }
  }
  
  getCountDays(periodoI, periodoF): any {
    if (periodoI && periodoF) {
      let periodoInicial = moment(periodoI);
      let periodoFinal = moment(periodoF);
      this.diasTotales = periodoFinal.diff(periodoInicial, "days");
      return `${this.diasTotales}  dias Inhabiles`;
    }
  }
}
