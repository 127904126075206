/* tslint:disable */
declare var Object: any;
export interface MensajeNotificacionInterface {
  "idMensajeNotificacion": number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
}

export class MensajeNotificacion implements MensajeNotificacionInterface {
  "idMensajeNotificacion": number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  constructor(data?: MensajeNotificacion) {
    Object.assign(this, data);
  }

  public static getModelName() {
    return "MensajeNotificacion";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Mail for dynamic purposes.
  **/
  public static factory(data: MensajeNotificacion): MensajeNotificacion{
    return new MensajeNotificacion(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MensajeNotificacion',
      plural: 'MensajeNotificacion',
      path: 'MensajeNotificacion',
      idName: 'idMensajeNotificacion',
      properties: {
        "idMensajeNotificacion": {
          name: "idMensajeNotificacion",
          type: "number"
        },
        "createdAt": {
          name: "createdAt",
          type: "Date"
        },
        "updatedAt": {
          name: "updatedAt",
          type: "Date"
        }
      },
      relations: {}
    }
  }
}