import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';
import { Caso, ContainerApi, UsuarioApi, GastoApi, Log, LogApi, Usuario } from '../shared/sdk';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-comprobar-modal',
  templateUrl: './comprobar-modal.component.html',
  styleUrls: ['./comprobar-modal.component.scss']
})
export class ComprobarModalComponent implements OnInit {

  msgs = []
  caso: Caso
  file: any[] = []
  filename: any[] = []
  value: number[] = []
  data
  //declarando componentes usados

  constructor(public logapi: LogApi, public gastosapi: GastoApi, public containerapi: ContainerApi, public usuarioapi: UsuarioApi, public http: HttpClient, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.caso = this.config.data.caso; //agarra el caso pasado por el componente que llamó

    //genera un registro vacio por cada gasto
    for (var i = 0; i < this.caso.gastos.length; i++) {
      this.file.push('')
      this.filename.push('')
      this.value.push(0)
    }


  }

  ngOnInit() {

  }

  onFileSelect(event, i) {
    //cuando se examine un archivo lo carga al array 
    //console.log('on MODAL')
    if (event.target.files.length > 0) {
      this.file[i] = event.target.files[0];
      this.filename[i] = event.target.files[0].name;

    }
    //console.log(this.file)
  }
  upload(index) {
    //segun el boton seleccionado utiliza ese index para subir el archivo en esa fila utilizando el id del usuario como 
    //identificador
    //console.log(this.file[index + 1])
    let formData: FormData = new FormData();
    formData.append('file', this.file[index], this.file[index].name);
    let headerst = new HttpHeaders();
    headerst.append('Content-Type', 'multipart/form-data');
    headerst.append('Accept', 'application/json');
    var i: number = this.usuarioapi.getCurrentId();
    var url = 'https://api1.accedde.mx/api/containers/' + i + '/upload'
    //var url = 'http://localhost:3000/api/containers/' + i + '/upload'
    this.http.post(url, formData, { headers: headerst, reportProgress: true, observe: 'events' }).pipe(map((event: any) => {
      switch (event.type) {
        //eventos para "pintar" el proceso de la subida
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          this.value[index] = progress;
          //console.log(progress)
          return { status: 'progress', message: progress };

        case HttpEventType.Response:

          //console.log("FINISHED")
          return event.body;
        default:
          //console.log("unhandled")
          return `Unhandled event: ${event.type}`;
      }
    }))

      .subscribe(
        (data: any) => {
          //console.log(data)
          this.data = data
        },
        error => {
          var s = i.toString()
          this.containerapi.create({ name: s }).subscribe((s) => {
            //console.log(s)
            this.upload(index)
          })
        }, () => {
          this.caso.gastos[index].archivo = this.data.result.files.file[0].name; //guarda el nombre del archivo en el
          //objeto gastos una vez se haya subido

          
          this.gastosapi.upsert(this.caso.gastos[index]).subscribe((d) => {
//sube a la base el gasto y escribe el log de este
            this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
              var r2: Log = new Log()
              r2.createdAt = new Date()
              r2.updatedAt = new Date()
              r2.idUsuario = us.idUsuario
              r2.tipo = 8
              r2.contenido = JSON.stringify(this.data.result.files.file[0])
              this.logapi.create(r2).subscribe((d) => {

              })

              var r: Log = new Log()
              r.createdAt = new Date()
              r.updatedAt = new Date()
              r.idUsuario = us.idUsuario
              r.tipo = 9
              r.contenido = JSON.stringify(d)
              this.logapi.create(r).subscribe((d) => {

              })
            })


            //console.log(d)
          }, (error) => {
            console.log(error)
          })

        }
      )
  }
  close() {
    this.ref.close()
  }
}
