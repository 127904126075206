import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ver-mensajes',
  templateUrl: './ver-mensajes.component.html',
  styleUrls: ['./ver-mensajes.component.scss']
})
export class VerMensajesComponent implements OnInit {
//never used
  constructor() { }

  ngOnInit() {
  }

}
