import { Component, OnInit } from '@angular/core';
import { CasoApi, UsuarioApi, Caso, Gasto } from '../shared/sdk';
import { MessageService, DialogService } from 'primeng/api';
import { ComprobarModalComponent } from '../comprobar-modal/comprobar-modal.component';

@Component({
  selector: 'app-gastos-abogado',
  templateUrl: './gastos-abogado.component.html',
  styleUrls: ['./gastos-abogado.component.scss'],
  providers: [DialogService, MessageService]
})
export class GastosAbogadoComponent implements OnInit {

  constructor(public casoapi: CasoApi, public usuarioapi: UsuarioApi,public dialogService: DialogService) { }
  casos: Caso[]
  //enlistar todos los casos del abogado con sus gastos.
  ngOnInit() {
    this.casoapi.find({ where: { idUsuario: this.usuarioapi.getCurrentId() }, include: ['gastos','gastosEliminados'] }).subscribe((c: Caso[]) => {
      //console.log(c)
      this.casos = c
    })
  }

  //contador de criterio
  countAprobados(g: Gasto[])
  {
    var contador = 0
    for (var ga of g) {
      if (ga.isAprobado!=null) {
        if(ga.isAprobado)
        {
          contador++
        }
      
      }
    }
    return contador
  }
  //contador de criterio
  countComprobados(g: Gasto[]) {
    var contador = 0
    for (var ga of g) {
      if (ga.archivo != null) {
        contador++
      }
    }
    return contador
  }
  //acumulador de criterio
  cashGastos(g: Gasto[]) {
    var contador = 0
    for (var ga of g) {
      if (ga.archivo == null) {
        contador = contador + ga.monto
      }
    }
    return contador
  }
//acumulador de criterio
  cashGastosComprobados(g: Gasto[]) {
    var contador = 0
    for (var ga of g) {

        contador = contador + ga.monto
     
    }
    return contador
  }
  //cuando da click a una tarjeta abrir el modal para que pueda comprobar los gastos
  //SE LE AGREGA ESTILO A LA VENTANA PARA QUE TENGA UNA BARRA A LA DERECHA Y PUEDA MOVERSE POR EL MODAL
  openDialog(c) {
    const ref = this.dialogService.open(ComprobarModalComponent, {
      data:
      {
        caso: c,
      },
      width: '70%',
      showHeader: true,
      dismissableMask: true,
      baseZIndex:3000,
      style: { "position": "fixed", "overflow": "auto", "max-height": "91%" }
    });
    //console.log(c)
  }
}
