import { Comentario } from './../shared/sdk/models/Comentario';
import { ComentarioApi } from './../shared/sdk/services/custom/Comentario';
import { Observable } from 'rxjs';
import { Log } from './../shared/sdk/models/Log';
import { Usuario } from './../shared/sdk/models/Usuario';
import { LogApi } from './../shared/sdk/services/custom/Log';
import { HttpClient } from '@angular/common/http';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';
import { UsuarioApi } from './../shared/sdk/services/custom/Usuario';
import { ArchivoApi } from './../shared/sdk/services/custom/Archivo';
import { DomSanitizer } from '@angular/platform-browser';
import { Archivo } from './../shared/sdk/models/Archivo';
import { Caso } from './../shared/sdk/models/Caso';
import { Component, OnInit } from '@angular/core';
import * as fileSaver from 'file-saver';
@Component({
  selector: 'app-actualizaciones-modal',
  templateUrl: './actualizaciones-modal.component.html',
  styleUrls: ['./actualizaciones-modal.component.scss']
})

export class ActualizacionesModalComponent implements OnInit {
  caso: Caso
  tipoArchivo
  id;
  listaArchivos: any[] = []
  archivoSeleccionado: Comentario = null
  isSelected: boolean[] = []


  constructor(public sanitizer: DomSanitizer, public comentarioapi: ComentarioApi, private usuarioapi: UsuarioApi, public logapi: LogApi, private http: HttpClient, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.caso = this.config.data.caso; //agarra el caso pasado y lo aisgna a este componente
  }

  ngOnInit() {
    this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
      this.id = us.idUsuario
      this.listaArchivos = this.caso.comentarios
      for (var com of this.listaArchivos) {
        //agrega un item por cada archivo , meramente para hacer funcionar el html
        this.isSelected.push(false)
      }
      this.isSelected[0] = true
      this.archivoSeleccionado = this.listaArchivos[0];
    })
  }


  setArchivoSeleccionado(archivo: Comentario) {
    //cambiar el archivo seleccionado y asi actualizar el visor
    this.archivoSeleccionado = archivo
  }

  getSource(idCaso, filename) {
    //obtiene la url sanitizada, importante 
    var url = 'https://api1.accedde.mx/api/containers/' + idCaso + '/download/' + filename
    //var url = 'http://localhost:3000/api/containers/' + idCaso + '/download/' + filename
    //console.log(this.sanitizer.bypassSecurityTrustUrl(url))
    return url
  }

  download(f, u) {
    //descarga el arhcivo confrma a los parametros de f : file y u: usuarioid
    this.downloadFile(f, u).subscribe(response => {
      let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
        //crea entrada en el log
        var r: Log = new Log();
        r.createdAt = new Date()
        r.updatedAt = new Date()
        r.tipo = 3
        r.idUsuario = us.idUsuario
        r.contenido = JSON.stringify(f)
        this.logapi.create(r).subscribe((d) => {
        })
      })
      fileSaver.saveAs(blob, f);
    }), error => //console.log('Error downloading the file'),
      () => {
      }
  }
//si es un pdf carga el visualizador, sino no muestra nada para evitar erorres 
  isPDF(archivoseleccionado: Comentario) {
    if (archivoseleccionado.archivo.endsWith('pdf') || archivoseleccionado.archivo.endsWith('PDF')) {
      return true
    } else {
      
      return false
    }
  }
  //parte del metodo de descarga
  downloadFile(f, u): Observable<Blob> {
    var i = u
    var url = 'https://api1.accedde.mx/api/containers/' + i + '/download/' + f
    //var url = 'http://localhost:3000/api/containers/' + i + '/download/' + f
    return this.http.get(url, { responseType: 'blob' });
  }
}
