import { Component, OnInit } from '@angular/core';
import { Usuario, UsuarioApi } from '../shared/sdk';

@Component({
  selector: 'app-nuevo-usuario',
  templateUrl: './nuevo-usuario.component.html',
  styleUrls: ['./nuevo-usuario.component.scss']
})
export class NuevoUsuarioComponent implements OnInit {
  usuario:Usuario
  msgs: any[] = [];
  constructor(public usuarioapi:UsuarioApi) { 

    this.usuario = new Usuario()
  }

  ngOnInit() {
  }
  createUser() {
    //creacion de nuevo usuario con los datos del front, as simple as that
    this.usuario.activo = true
    this.usuarioapi.create(this.usuario).subscribe((d)=>{
      this.msgs.push({ severity: 'success', summary: 'Exito', detail: 'Usuario Creado Exitosamente' });
      this.usuario.nombres=''
    this.usuario.aPaterno = ''
    this.usuario.aMaterno =''
    this.usuario.email = ''
    this.usuario.tipo = null
    this.usuario.username = ''
    this.usuario.password = ''

//console.log(d)
    },(error)=>{
      this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Ocurrio un problema al crear usuario, verifique los datose intente de nuevo' });

      console.log(error)
    })
    
  }
}
