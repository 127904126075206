import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  enableProdMode
} from "@angular/core";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/api";
import { Caso, CasoApi, Log, LogApi, UsuarioApi, Usuario } from "../shared/sdk";
import { TouchSequence } from "selenium-webdriver";
import { InformeApi } from "../shared/sdk/services/custom/InformeMens";
import { InformeMens } from "../shared/sdk/models/InformeMens";
import { ServicioInformeService } from "../servicio-informe.service"; //sirve para pasar valores de un componente a otro componente si estan en la misma jerarquia
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: "app-ver-informe",
  templateUrl: "./ver-informe.component.html",
  styleUrls: ["./ver-informe.component.scss"]
})
export class VerInformeComponent implements OnInit {
  selectedCaso: Caso; //NOS SIRVE PARA OBTENER EL ID DEL CASO SELECCIONADO

  mesInput: Date; //VARIABLE QUE GUARDARA EL VALOR DADO DEL MES DESDE EL INPUT
  añoInput: Date; //VARIABLE QUE GUARDARA EL VALOR DEL AÑO DADO DESDE EL INPUT
  principioMes: any; //NOS SIRVE PARA GUARDAR CUALQUER MES Y CUALQUIER AÑO PERO SIEMPRE EN EL DIA 01
  arreglo: any; //GUARDARA EL VALOR SPLITEADO DEL INPUT CALENDARIO DADO POR EL USUARIO
  finalMes: any; //NOS SIRVE PARA GUARDAR CUALQUER MES Y CUALQUIER AÑO PERO SIEMPRE EN EL DIA 31
  elementoHTML: any; //LEERA EL VALOR DEL INPUT CALENDARIO QUE ESTA EN EL HTML
  //fechaFinal: any
  //ArrayInformes: InformeMens[]
  //selectedInforme: InformeMens
  respuestaFinal: any; //VARIABLE QUE GUARDARA LA FECHA Y EL CONTENIDO DEL INFORME MENSUAL EN CASO DE ENCONTRARLO
  ids: String; //VARIABLE QUE GUARDARA EL ID DE CADA CASO EN FORMA DE STRING, YA QUE PARA LA CONSULTA ESE NECESARIO COMPARAR
  //STRING CON STRING

  activarBoton: boolean; //VARIABLE QUE SIRVE COMO BOOLEANO, EN CASO DE QUE SE ENCUENTRE UN INFORM MENSUAL EN EL MES SELECCIONADO
  // SE ACTIVARA EL BOTON PARA PODER DESCARGAR EL PDF

  //@Output() informePost= new EventEmitter();

  constructor(
    private servicioInforme: ServicioInformeService,
    public informeApi: InformeApi,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public casoapi: CasoApi
  ) {}

  ngOnInit() {
    this.selectedCaso = this.config.data.caso;
    //console.log(this.selectedCaso.idCaso)
    //this.selectedInforme = this.config.data.informeMens;
  }

  //FUNCION PARA CONSEGUIR LA FECHA DEL INPUT Y EN BASE A ESTA PODER HACER LA CONSULTA
  getInputFecha() {
    this.respuestaFinal = "ESTE MES NO CUENTA CON INFORME MENSUAL";
    this.ids = this.selectedCaso.idCaso.toString();
    this.elementoHTML;
    this.elementoHTML = document.getElementById("fecha-input");
    this.activarBoton = false;
    this.arreglo = this.elementoHTML.value.split("-");
    this.finalMes = this.arreglo[0] + "-" + this.arreglo[1] + "-" + "31";
    this.principioMes = this.arreglo[0] + "-" + this.arreglo[1] + "-" + "01";
    this.mesInput = this.arreglo[1];
    this.añoInput = this.arreglo[0];
    if (this.elementoHTML.value == null || this.elementoHTML.value == "") {
        this.respuestaFinal = null;
    } else {
      //INICIO DE LA CONSULTA
      this.informeApi
        .findOne({where: {idCaso: this.ids, createdAt: {between: [new Date(this.principioMes).toISOString(),new Date(this.finalMes).toISOString(),] as [string, string]}, groupBy: "idCaso"}, include: "caso" })
        .subscribe((i: InformeMens) => {
          this.respuestaFinal = this.arreglo[1] + "/" + this.arreglo[0] + " | " + i.Contenido + " // ";
          if (this.respuestaFinal != "ESTE MES NO CUENTA CON INFORME MENSUAL" || this.respuestaFinal != "") {
              this.activarBoton = true;
          }
          /*for(var x of i){
                  let index 
                index = this.ArrayInformes.findIndex(obj =>obj.createdAt ==x.createdAt)
                //console.log(index)
                this.ArrayInformes[index].Fecha = this.arreglo[1]+'/'+this.arreglo[0];
                console.log(this.ArrayInformes[index].idCaso+" "+this.ArrayInformes[index].Fecha+ " "+ this.ArrayInformes[index].Contenido)
                //this.informeFinal = `${this.fechaFinal} | ${x.Contenido}`
                //this.contenidoInformes.push(this.informeFinal)
                //console.log(x)
                //this.arreglito.push(x);
                //console.log(this.arreglito)
                }*/
        });
    }
    //FIN DE LA CONSULTA
  }

  //FUNCION QUE MUESTRA EL RESULTADO DE LA CONSULTA
  resultadoInf() {
    if (this.respuestaFinal == null) {
        this.respuestaFinal = "Porfavor Seleccione Un Mes";
    }
    return this.respuestaFinal;
  }

  //FUNCION QUE NOS SIRVE PARA CREAR EL PDF DEL INFORME MENSUAL SELECCIONADO
  //SE UTILIZA LA LIBRERIA PDFMAKE
  enviarInforme() {
    const documentDefinition = {
      content: [
        {
          text: "INFORME MENSUAL DE " + this.selectedCaso.acreditado,
          style: "header",
        },
        {
          text: "Num. de control " + this.selectedCaso.numeroControl,
          style: "numControl",
        },
        { text: "----------------------------- " },
        {
          text:
            "FECHA SELECCIONADA: " + this.arreglo[1] + "/" + this.arreglo[0],
        },
        { text: "Informe mensual: " },
        { text: "----------------------------- " },
        { text: this.respuestaFinal, styel: "contenidoInf" }
      ],

      styles: {
        header: {
          fontSize: 21,
          bold: true
        },
        numControl: {
          fontSize: 15,
          bold: true
        },

        contenidoInf: {
          fontsize: 13
        }
      }
    }
    pdfMake.createPdf(documentDefinition).open();
  }
}
