/* tslint:disable */
import {
  Caso
} from '../index';

declare var Object: any;
export interface EmbargoInterface {
  "idEmbargo"?: number;
  "contenido"?: any;
  "archivo"?: any;
  "valorEstimado"?: number;
  "inscripcionEmbargo"?: boolean;
  "latitud"?: number;
  "longitud"?: number;
  "idCaso"?: number;
  "createdAt": Date;
  "updatedAt": Date;
  caso?: Caso;
}

export class Embargo implements EmbargoInterface {
  "idEmbargo": number;
  "contenido": any;
  "archivo": any;
  "valorEstimado": number;
  "inscripcionEmbargo": boolean;
  "latitud": number;
  "longitud": number;
  "idCaso": number;
  "createdAt": Date;
  "updatedAt": Date;
  caso: Caso;
  constructor(data?: EmbargoInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Embargo`.
   */
  public static getModelName() {
    return "Embargo";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Embargo for dynamic purposes.
  **/
  public static factory(data: EmbargoInterface): Embargo{
    return new Embargo(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Embargo',
      plural: 'Embargos',
      path: 'Embargos',
      idName: 'idEmbargo',
      properties: {
        "idEmbargo": {
          name: 'idEmbargo',
          type: 'number'
        },
        "contenido": {
          name: 'contenido',
          type: 'any'
        },
        "archivo": {
          name: 'archivo',
          type: 'any'
        },
        "valorEstimado": {
          name: 'valorEstimado',
          type: 'number'
        },
        "inscripcionEmbargo": {
          name: 'inscripcionEmbargo',
          type: 'boolean'
        },
        "latitud": {
          name: 'latitud',
          type: 'number'
        },
        "longitud": {
          name: 'longitud',
          type: 'number'
        },
        "idCaso": {
          name: 'idCaso',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        caso: {
          name: 'caso',
          type: 'Caso',
          model: 'Caso',
          relationType: 'belongsTo',
                  keyFrom: 'idCaso',
          keyTo: 'idCaso'
        },
      }
    }
  }
}
