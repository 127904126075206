import { Component, OnInit } from '@angular/core';
import { GastoApi, Gasto, CasoApi, Caso, UsuarioApi, Usuario } from '../shared/sdk';
import { DialogService, MessageService } from 'primeng/api';
import { AprobarModalComponent } from '../aprobar-modal/aprobar-modal.component';


const TODOS_SELECCIONADO_FLAG = 0
@Component({
  selector: 'app-aprobar-gastos',
  templateUrl: './aprobar-gastos.component.html',
  styleUrls: ['./aprobar-gastos.component.scss'],
  providers: [DialogService, MessageService]
})
export class AprobarGastosComponent implements OnInit {
  msgs: any[] = [];
  casosOriginalList: Caso[] = []
  casosBackupList: Caso[] = []
  antesFiltrocasos: Caso[] = []
  abogadosList: Usuario[] = []

  hideFinished = "false";
  selectedAbogadoId;
  showEliminados = "false"



  constructor(public dialogService: DialogService, public casoapi: CasoApi, public usuarioapi: UsuarioApi) {

  }

  //filtros
  filtrarAbogado() {
    if (this.selectedAbogadoId ==   TODOS_SELECCIONADO_FLAG) {
      this.filtrarTodosSeleccionado()
    } else {
      this.filtrarAbogadoSeleccionado()
    }
  }
//filtros
  filtrarTodosSeleccionado() {
    this.casosOriginalList = this.casosBackupList
  }
//filtros
  filtrarAbogadoSeleccionado() {
    this.casosOriginalList = this.casosBackupList
    var tempcasos = []
    this.casosOriginalList.forEach(c => {
      if (c.usuario.idUsuario == this.selectedAbogadoId) {
        tempcasos.push(c)
      }
    });
    this.casosOriginalList = tempcasos
    this.antesFiltrocasos = tempcasos
  }
filtrarEliminados()
{

}
//cuenta los aprobados de cada caso para visualizarlos en el html
  countAprobados(g: Gasto[]) {
    var contador = 0
    for (var ga of g) {
      if (ga.isAprobado != null) {
        if (ga.isAprobado) {
          contador++
        }
      }
    }
    return contador
  }
//cuenta los comprobados de cada caso para visualizarlos en el html
  countComprobados(g: Gasto[]) {
    var contador = 0
    for (var ga of g) {
      if (ga.archivo != null || ga.archivo != undefined) {
        contador++
      }
    }
    return contador
  }
//concatenaciones
  cashGastos(g: Gasto[]) {
    var contador = 0
    for (var ga of g) {
      if (ga.archivo == null) {
        contador = contador + ga.monto
      }
    }
    return contador
  }
//concatenaciones
  cashGastosComprobados(g: Gasto[]) {
    var contador = 0
    for (var ga of g) {
      contador = contador + ga.monto
    }
    return contador
  }

  ngOnInit() {
    //llamada al metodo
    this.getCasos();
  }
  
  getCasos() {
      //enlista todos los casos y jala las relaciones de gastos, usuario y gatsos eliminados
      //que son utilizadas en esta vista
    this.casoapi.find({ include: ['gastos', 'usuario','gastosEliminados'] }).subscribe((casos: Caso[]) => {
      this.casosOriginalList = casos //realia un respaldo de los casos para utilizar cuando se agreguen /quiten filtros
      this.casosBackupList = casos
      this.getUsuarios()
       //console.log(casos)
    })
  }
//obtener la lista de abogados (tipo 2 = abogadoFlag)
  getUsuarios() {
    this.usuarioapi.find().subscribe((users: Usuario[]) => {
      users.forEach(element => {
        if (element.tipo == 2) {
          this.abogadosList.push(element)
        }
      });
    })
  }

  //abrir modal de aprobacion
  //SE LE AGREGA ESTILO AL MODAL, YA QUE EN CASO DE TENER MUCHOS GASTOS, EL MODAL SE HACIA ENORME
  openAprobarModal(c: Caso) { 
    // console.log(c)
    const ref = this.dialogService.open(AprobarModalComponent, {
      data:
      {
        caso: c,
      },
      width: '70%',
      showHeader: true,
      dismissableMask: true,
      baseZIndex:3000,
      style: { "position": "fixed", "overflow": "auto", "max-height": "91%" }
    });
   
  }
//metodo que busca lo que se escriba en el campo de search 
  search(v: string) {
    if (v == '') {
      if (this.hideFinished == "true") {
        this.casosOriginalList = this.antesFiltrocasos
      } else {
        this.casosOriginalList = this.casosBackupList
      }
    } else {
      this.casosOriginalList = this.casosOriginalList.filter(function (item) {
        var finder = v.toUpperCase()
        return eval('/' + finder + '/').test(item.acreditado); //busca por nombre de acreditado, si se quiere cambiar el criterio es aqui
      })
    }
  }
 //si se quiere ocultar lo suqe ya no tienen gastos por aprobar 
  ocultarTerminados() {
    if (this.hideFinished == "true") {
      var tempcasos = []
      this.casosOriginalList.forEach(c => {
        if (c.gastos.length > 0) {
          if (c.gastos.length != this.countAprobados(c.gastos)
            && c.gastos.length != this.countComprobados(c.gastos)) {
            tempcasos.push(c)
          }
        }
      });
      this.casosOriginalList = tempcasos
      this.antesFiltrocasos = tempcasos
    } else {
      this.casosOriginalList = this.casosBackupList
    }
  }

}
