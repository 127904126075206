import { EmbargoApi } from './../shared/sdk/services/custom/Embargo';
import { LogInterface } from './../shared/sdk/models/Log';
import { Embargo } from './../shared/sdk/models/Embargo';
import { Component, OnInit } from '@angular/core';
import { InformeMens, CasoApi, Caso, Log, LogApi, UsuarioApi, Usuario } from '../shared/sdk';
import { DialogService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { InformeApi } from './../shared/sdk/services/custom/InformeMens';
import { ServicioInformeService } from '../servicio-informe.service';

@Component({
  selector: 'app-editar-caso',
  templateUrl: './editar-caso.component.html',
  styleUrls: ['./editar-caso.component.scss'],
  providers: [DialogService]
})
export class EditarCasoComponent implements OnInit {
  comentario = '' //VARIABLE QUE CAPTURA EL CONTENIDO PARA EL INFORME INFORME MENSUAL DEL CASO
  d = true
  text: string;
  sumAccedde: any; //VARIABLE PARA RECUPERADO ACCEDDE
  mesReal: any; //VA A CAPTURAR EL MES QUE ESTE MARCADO EN EL OBJETO DATE
  añoReal: any; // VA A CAPTURAR EL AÑO QUE ESTE MARCADO EN EL OBJETO DATE 
  fechaRealPrincio: any 
  fechaRealFinal: any
  actualDate: Date = new Date(); //TIEMPO REAL CON EL QUE SE HARA LA VERIFICACION
  results: Caso[];
  selectedCaso: Caso
  recuAccedde: number
  BloqueadorInforme: boolean = false;
  selectedInforme: InformeMens; //SELECCIONA EL INFORME MENSUAL 
  fechaAmostrar: any //MUESTRA EL MES Y EL AÑO DEL INFORME MENSUAL EN CASO DE QUE DICHO CASO YA CUENTE CON UN INFORME 
  laFechaRecu: any //GUARDA LA FECHA DE RECUPERACION DEL CASO Y MUESTRA LA FECHA
  laFechaAsign: any //RECIBIRA LA FECHA DE ASIGNACION DEL CASO Y LA MOSTRARA
  laFechaPande: any //GUARDA LA FECHA DEL NUEVO HONORARIO POR PANDEMIA
  FinPandemia: any //RECIBIRA LA FECHA DE FINALIZACION DEL DESCUENTO DE HONORARIO POR PANDEMIA

  Et_procesalList: any[] = [
    { v: 0, c: 'Demanda' },
    { v: 1, c: 'Admision de demanda' },
    { v: 2, c: 'Diligencia de requerimiento de pago y embargo' },
    { v: 3, c: 'Contestacion de demanda' },
    { v: 4, c: 'Desahogo de vista de contestacion de demanda' },
    { v: 5, c: 'Pruebas' },
    { v: 6, c: 'Desahogo de pruebas' },
    { v: 7, c: 'Alegatos' },
    { v: 8, c: 'Sentencia' },
    { v: 9, c: 'Ejecucion de sentencia' },
    { v: 10, c: 'Procedimiento de remate' },
    { v: 11, c: 'Adjudicacion' },
    { v: 12, c: '' },
  ];
  tramoList: any[] = [
    { v: 0, c: '1 Tramo' },
    { v: 1, c: '2 Tramo' },
    { v: 2, c: '3 Tramo' },
    { v: 3, c: 'FEGA/FONAGA' },
    { v: 4, c: 'Propia Good Bank' },
    { v: 5, c: 'Propia Bad Bank' },
    { v: 6, c: 'A.E' },
    //{ v: 7, c: 'X.F.' },
  ]

  msgs: any[] = [];
  tempEmbargos: Embargo[] = []
  constructor(private servicioInforme: ServicioInformeService,private route: ActivatedRoute,public embargoapi: EmbargoApi, public casoapi: CasoApi, public logapi: LogApi, public usuarioapi: UsuarioApi, public informeapi: InformeApi) {


   }

  ngOnInit() {
   
  //agarramos el id de la ruta de la direccion http
    this.route.params.subscribe(params => {
   // (+) converts string 'id' to a number
      if(params['id'])
      {
   
        //console.log(params['id'])
        this.searchById(+params['id'])
        this.searchInforme(+params['id'])
       
      }
      // In a real app: dispatch action to load the details here.
   });
   
  }
  
  searchInforme(id){
    this.informeapi.findOne({where:{idCaso:id}}).subscribe((i: InformeMens) => {
      this.selectedInforme = i
      //console.log(i)
    })

  }

  

  searchById(id)
  {
    //usando la api para buscar ese id  y desplegar los datos del resultado con el html
    this.casoapi.findOne({where:{idCaso:id}}).subscribe((c: Caso) => {
    
      this.selectedCaso = c
  
      //console.log(c)
    })
  }
  search(event) {
    //busca caso por acreditado, despliega resultados en el dropdown

    var t: String = "%" + this.text + "%"
    if (this.text.length > 2) {
      this.casoapi.find({ where: { acreditado: { like: t } }, include: 'embargos'}).subscribe((c: Caso[]) => {
        this.results = c
        //console.log(c)
      })
  
    }
   
  }
  addEmbargo() {
    var tempembargo2 = new Embargo()
    tempembargo2.idCaso = this.selectedCaso.idCaso
    tempembargo2.inscripcionEmbargo = true
    this.selectedCaso.embargos.push(tempembargo2)
    //agrega un objeto embargo al array para agregar una nueva fila a la tabla
  }

  borrarFecha(){
    this.laFechaPande = null
  }

  convertirFechaRecu(evt){
    this.selectedCaso =evt

    //EL PRIMER IF Y ELSE ES PARA LA FECHA DE INICIO DEL DESCUENTO POR PANDEMIA
    if(this.selectedCaso.fechaDescPandemia != null){
      let año_y_Mes = this.selectedCaso.fechaDescPandemia
      let fechaString = año_y_Mes.toString()
      let spliteando_año_mes = fechaString.split("-")
      let spliteado_dia = spliteando_año_mes[2].split("")

      this.laFechaPande = spliteado_dia[0]+spliteado_dia[1]+"/"+spliteando_año_mes[1]+"/"+spliteando_año_mes[0]
    }
    else{
      this.laFechaPande = null
    }

    //EL SEGUNDO IF Y ELSE ES PARA LA FECHA DE FINALIZACION DEL DESCUENTO POR PANDEMIA
    if(this.selectedCaso.fechaFinPandemia != null){
      let año_y_Mes = this.selectedCaso.fechaFinPandemia
      let fechaString = año_y_Mes.toString()
      let spliteando_año_mes = fechaString.split("-")
      let spliteado_dia = spliteando_año_mes[2].split("")

      this.FinPandemia = spliteado_dia[0]+spliteado_dia[1]+"/"+spliteando_año_mes[1]+"/"+spliteando_año_mes[0]
    }

    else{
      this.FinPandemia = null
    }

    //EL TERCER IF Y ELSE ES PARA MOSTRAR LA FECHA DE RECUPERACION QUE TIENE EL CASO.
    if(this.selectedCaso.fechaRecuperacion != null){
      let año_y_Mes = this.selectedCaso.fechaRecuperacion
      let fechaString = año_y_Mes.toString()
      let spliteando_año_mes = fechaString.split("-")
      let spliteado_dia = spliteando_año_mes[2].split("")

      this.laFechaRecu = spliteado_dia[0]+spliteado_dia[1]+"/"+spliteando_año_mes[1]+"/"+spliteando_año_mes[0]
    }
    else{
      this.laFechaRecu = null
    }

    //EL CUARTO IF Y ELSE ES PARA MOSTRAR LA FECHA DE ASIGNACION QUE TIENE EL CASO
    if(this.selectedCaso.fechaAsignacion != null){
      let año_y_Mes = this.selectedCaso.fechaAsignacion
      let fechaString = año_y_Mes.toString()
      let spliteando_año_mes = fechaString.split("-")
      let spliteado_dia = spliteando_año_mes[2].split("")

      this.laFechaAsign = spliteado_dia[0]+spliteado_dia[1]+"/"+spliteando_año_mes[1]+"/"+spliteando_año_mes[0]
    }
    else{
      this.laFechaAsign = null
    }
  }
  
  isSelected(evt) {
    this.selectedCaso = evt
    /*if (this.selectedCaso.embargos.length <= 0) {
      this.tempEmbargos[0].idCaso = this.selectedCaso.idCaso
      this.selectedCaso.embargos = this.tempEmbargos;
    }*/ 
    //SE DEJA COMENTADO YA QUE PARECE SER NO APORTA NADA Y SOLO CAUSA ERROR 
    //console.log(this.selectedCaso.idCaso+" "+this.selectedCaso.acreditado)
    
  }

  onType() {
    //si quiere subir el cambio tiene que tener un comentario y seleccionar un archivo
    //console.log(this.comentario)
    if (this.comentario != '') {
      this.d = false;
    } else {
      this.d = true
    }
  }

  checarSiHayInforme(evt){
    this.selectedCaso = evt
    let id: Number
    let stringId = ''
    let fechaInforme: any
    let spliteadoInforme
    this.BloqueadorInforme = false 
    this.mesReal = this.actualDate.getUTCMonth()+1;
    this.añoReal = this.actualDate.getUTCFullYear();
    this.fechaRealPrincio = this.añoReal+'-'+this.mesReal+'-'+'01'
    this.fechaRealFinal = this.añoReal+'-'+this.mesReal+'-'+'31'
    id = this.selectedCaso.idCaso
    stringId = id.toString();

  //----------CONSULTA DE INFORME DEPENDIENDO DEL ID CASO----
    this.informeapi.findOne({
      where:{
            idCaso:stringId,
            createdAt:{
              between:[new Date(this.fechaRealPrincio).toISOString(),
                new Date(this.fechaRealFinal).toISOString(),] as [string, string],
            },
          }}).subscribe((i: InformeMens) => {
      if(i.idCaso == null){
        this.BloqueadorInforme = false
      }
      else{
          this.selectedInforme = i //i AHORA TIENE EL INFORME SELECCIONADO POR ID
          //console.log("VALOR DE i "+ i.createdAt)
          fechaInforme = i.createdAt //AQUI SE OBTIENE LA FECHA DEL INFORME 
          spliteadoInforme = fechaInforme.split("-") //SE SPLITEA EL AÑO Y MES, YA QUE SON LOS UNICOS QUE NECESITAMOS
          //console.log("VALOR DE CREATED AT: "+i.createdAt)
         // console.log("VALOR DE spliteadoInforme: AÑO "+spliteadoInforme[0]);
          //console.log("VALOR DE spliteadoInforme: MES "+spliteadoInforme[1]);
          //console.log("VALOR DEL DATO TIPO DATE "+this.actualDate)
          if(spliteadoInforme[1]==this.mesReal && spliteadoInforme[0]==this.añoReal){
            this.BloqueadorInforme = true
            this.fechaAmostrar = "ESTE CASO YA CUENTA CON INFORME DEL MES ACTUAL: "+spliteadoInforme[1]+"/"+spliteadoInforme[0]
          }
      }
    })

  }

  //FUNCION PARA ENVIAR UN INFORME MENSUAL SOBRE EL CASO, SE ACTIVA ATRAVES DEL BOTON
  //Actualizar informe mensual
  elInforme(){
  var i: InformeMens = new InformeMens();
  //console.log("VALOR DEL BLOQUEADOR:"+this.BloqueadorInforme)
  
  if(this.comentario == null || this.comentario == '')
  {
    i.Contenido = null
  }
  else{
  i.Contenido = this.comentario
  i.idCaso = this.selectedCaso.idCaso
  //console.log("EL CONTENIDO "+i.Contenido);
  this.informeapi.upsert(i).subscribe((res) => {
    //console.log(res)
            this.casoapi.upsert(this.selectedCaso).subscribe((d) => {
              //genera el log
              this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
                var r: Log = new Log();
                r.createdAt = new Date()
                r.updatedAt = new Date()
                r.idUsuario = us.idUsuario
                r.tipo = 7
                r.contenido =  JSON.stringify(d)
                this.logapi.create(r).subscribe((d) => {
                })
              })
            })
          })
      this.BloqueadorInforme = true;
      this.comentario = '' //SE VUELVE A DEJAR EN BLANCO EL COMENTARIO 
    }
 }
//------------------------------------------------------------------------------------

  //actualiza los datos del caso con lo que se coloco en el html
  updateCaso() {
    //EL PRIMER IF Y ELSE ES PARA LA FECHA DE FINALIZACION DEL DESCUENTO DE HONORARIO POR PANDEMIA
    if(this.selectedCaso.fechaFinPandemia != null){

    }
    else{
      this.selectedCaso.fechaFinPandemia = this.FinPandemia
    }

    //EL SEGUNDO IF Y ELSE ES PARA LA FECHA DE INICIO DEL HONORARIO POR PANDEMIA
    if(this.selectedCaso.fechaDescPandemia != null){

    }
    else{
      this.selectedCaso.fechaDescPandemia = this.laFechaPande
    }

    //EL TERCER IF Y ELSE ES PARA LA FECHA DE RECUPERACION DEL CASO
    if(this.selectedCaso.fechaRecuperacion != null){

    }
    else{
      this.selectedCaso.fechaRecuperacion = this.laFechaRecu
    }

    //EL CUARTO IF Y ELSE ES PARA LA CIFRA DE RECUPERADO ACCEDDE
    if(this.selectedCaso.recuAccedde !=null){
    
    }
    else{
      this.selectedCaso.recuAccedde = this.sumAccedde
    }
    //console.log(this.selectedCaso);

    this.casoapi.upsert(this.selectedCaso).subscribe((d) => {
      var c = 0
      for (var e of this.selectedCaso.embargos) {
        this.embargoapi.upsert(e).subscribe((r) => {
          //console.log(r)
          c++;
          if (c == this.selectedCaso.embargos.length) {
            this.msgs.push({ severity: 'success', summary: 'Exito', detail: 'Caso Actualizado Exitosamente' });
          }
        }, (err) => {
          this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Ocurrio un problema al actualizar caso, contactar a sistemas' });

        });
      }
      
      //su correcpondiente log
      this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
        var r: Log = new Log();
        r.createdAt = new Date()
        r.updatedAt = new Date()
        r.idUsuario = us.idUsuario
        r.tipo = 10
        r.contenido = JSON.stringify(d)
        this.logapi.create(r).subscribe((d) => {

        })
      })
  

    }, (error) => {
      this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Ocurrio un problema al actualizar caso, contactar a sistemas' });
    })

    alert("Caso actualizado exitosamente!")
    location.reload();
  }
}
