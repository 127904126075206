import { Component, OnInit } from '@angular/core';
import { UsuarioApi } from '../shared/sdk';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
//pagina temporal para tener la parte del codigo en donde cierra sesion tanto en el 
//navegador como en la base, destruye caches del browser y elimina la key de la sesion de la base
//se accesa a traves de "/logout";
  constructor(public usuarioapi:UsuarioApi,public router:Router) { }

  ngOnInit() {
    this.logout()
  }
  logout()
  {
    this.usuarioapi.logout().subscribe((s)=>
    {
      this.router.navigateByUrl("auth")
    },(error)=>
    {
      this.router.navigateByUrl("auth")
    })
  }
}
