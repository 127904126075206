/* tslint:disable */

import {
  Usuario,
  Comentario,
  Gasto,
  Garantia,
  GastoAsignacion,
  GastoEliminado,
  Embargo,
  Archivo,
  InformeMens
} from '../index';


declare var Object: any;
export interface CasoInterface {
  "idCaso"?: number;
  "id"?: number;
  "tramo"?: number;
  "numeroControl"?: string;
  "acreditado"?: string;//NUEVA COLUMNA
  "Cast_fira"?: string;//NUEVA COLUMNA
  "Cast_accedde"?: string;//NUEVA COLUMNA
  "Et_procesal"?: number;
  "estatusM"?: number;
  "impugnacionM"?: number;
  "estatusC"?: number;
  "impugnacionC"?: number;
  "juzgadoM"?: number;
  "expedienteM"?: string;
  "juzgadoC"?: number;
  "expedienteC"?: string;
  "expToca"?: string;
  "sala"?: string;
  "capital"?: number; //PENDIENTE DE BORRAR EN LA DB
  "intOrdinarios"?: number; //PENDIENTE DE BORRAR EN LA DB
  "intMoratorios"?: number; //PENDIENTE DE BORRAR EN LA DB
  "cap_efsy"?: number;//NUEVA COLUMNA
  "intOrdinarios_efsy"?: number;//NUEVA COLUMNA
  "intMoratorios_efsy"?: number;//NUEVA COLUMNA
  "saldoCont"?: number; //NUEVA COLUMNA
  "reservas"?: string; //NUEVA COLUMNA
  "montoCast"?: number;//NUEVA COLUMNA
  "conceptoCast"?: string; //NUEVA COLUMNA
  "garantia"?: any;
  "valorEstimado"?: number;
  "fechaRecuperacion"?: Date;
  "fechaAsignacion"?: Date;
  "fechaDescPandemia"?: Date;
  "fechaFinPandemia"?: Date; 
  "grupo"?: any;
  "gastosEfectuados"?: number;
  "tratamiento"?: any;
  "boletinM"?: any;
  "boletinC"?: any;
  "mailEnviado"?: boolean;//PENDIENTE DE BORRAR EN LA DB
  "recuperado"?: number;
  "recuperado2"?: boolean;
  "recuAccedde"?: number;
  "honorario"?: number;
  "honorarioPandemia"?: number;
  //"honorariosAbo"?: number;//NUEVA COLUMNA
  "honorariosPeri"?: number;//NUEVA COLUMNA
  "honorariosRebel"?: number;//NUEVA COLUMNA
  "gPorErogar"?: number;//NUEVA COLUMNA
  "montoRecup"?: number//NUEVA COLUMNA
  "idUsuario"?: number;
  "createdAt": Date;
  usuario?: Usuario;
  comentarios?: Comentario[];
  gastos?: Gasto[];
  garantias?: Garantia[];
  gastosAsignacion?: GastoAsignacion[];
  gastosEliminados?: GastoEliminado[];
  embargos?: Embargo[];
  archivos?: Archivo[];
  InformesMens?: InformeMens[];//NUEVA TABLA
}

export class Caso implements CasoInterface {
  "idCaso": number;
  "id": number;
  "tramo": number;
  "numeroControl": string;
  "acreditado": string;
  "Cast_fira": string; //NUEVA COLUMNA
  "Cast_accedde": string; //NUEVA COLUMNA
  "Et_procesal": number;//NUEVA COLUMNA
  "estatusM": number;
  "impugnacionM": number;
  "estatusC": number;
  "impugnacionC": number;
  "juzgadoM": number;
  "expedienteM": string;
  "juzgadoC": number;
  "expedienteC": string;
  "expToca": string;
  "sala": string;
  "capital": number; //PENDIENTE DE BORRAR EN LA DB
  "intOrdinarios": number;//PENDIENTE DE BORRAR EN LA DB
  "intMoratorios": number;//PENDIENTE DE BORRAR EN LA DB
  "cap_efsy": number; //NUEVA COLUMNA
  "intOrdinarios_efsy": number;//NUEVA COLUMNA
  "intMoratorios_efsy": number;//NUEVA COLUMNA
  "saldoCont": number; //NUEVA COLUMNA
  "reservas": string; //NUEVA COLUMNA
  "montoCast": number;//NUEVA COLUMNA
  "conceptoCast": string; //NUEVA COLUMNA
  "garantia": any;
  "valorEstimado": number;
  "fechaRecuperacion": Date;
  "fechaAsignacion": Date;
  "fechaDescPandemia": Date;
  "fechaFinPandemia": Date; 
  "grupo": any;
  "gastosEfectuados": number;
  "tratamiento": any;
  "boletinM": any;
  "boletinC": any;
  "mailEnviado": boolean;
  "recuperado": number;
  "recuperado2": boolean;
  "recuAccedde": number; 
  "honorario": number;
  "honorarioPandemia": number;
  //"honorariosAbo": number;//NUEVA COLUMNA
  "honorariosPeri": number;//NUEVA COLUMNA
  "honorariosRebel": number;//NUEVA COLUMNA
  "gPorErogar": number;//NUEVA COLUMNA
  "montoRecup": number; //NUEVA COLUMNA
  "idUsuario": number;
  "createdAt": Date;
  "updatedAt": Date;
  usuario: Usuario;
  comentarios: Comentario[];
  gastos: Gasto[];
  garantias: Garantia[];
  gastosAsignacion: GastoAsignacion[];
  gastosEliminados: GastoEliminado[];
  embargos: Embargo[];
  archivos: Archivo[];
  InformesMens: InformeMens[];//NUEVA TABLA
  constructor(data?: CasoInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Caso`.
   */
  public static getModelName() {
    return "Caso";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Caso for dynamic purposes.
  **/
  public static factory(data: CasoInterface): Caso{
    return new Caso(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Caso',
      plural: 'Casos',
      path: 'Casos',
      idName: 'idCaso',
      properties: {
        "idCaso": {
          name: 'idCaso',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "tramo": {
          name: 'tramo',
          type: 'number'
        },
        "numeroControl": {
          name: 'numeroControl',
          type: 'string'
        },
        "acreditado": {
          name: 'acreditado',
          type: 'string'
        },
        "Cast_fira":{
          name: 'Cast_fira',
          type: 'string'
        },
        "Cast_accedde":{
          name: 'Cast_accedde',
          type: 'string'
        },
        "Et_procesal":{
          name: 'Et_procesal',
          type: 'number'
        },
        "estatusM": {
          name: 'estatusM',
          type: 'number'
        },
        "impugnacionM": {
          name: 'impugnacionM',
          type: 'number'
        },
        "estatusC": {
          name: 'estatusC',
          type: 'number'
        },
        "impugnacionC": {
          name: 'impugnacionC',
          type: 'number'
        },
        "juzgadoM": {
          name: 'juzgadoM',
          type: 'number'
        },
        "expedienteM": {
          name: 'expedienteM',
          type: 'string'
        },
        "juzgadoC": {
          name: 'juzgadoC',
          type: 'number'
        },
        "expedienteC": {
          name: 'expedienteC',
          type: 'string'
        },
        "expToca": {
          name: 'expToca',
          type: 'string'
        },
        "sala": {
          name: 'sala',
          type: 'string'
        },
        "capital": {
          name: 'capital',
          type: 'number'
        },
        "intOrdinarios": {
          name: 'intOrdinarios',
          type: 'number'
        },
        "intMoratorios": {
          name: 'intMoratorios',
          type: 'number'
        },
        "cap_efsy":{
          name: 'cap_efsy',
          type: 'number'
        },
        "intOrdinarios_efsy":{
          name: 'intOrdinarios_efsy',
          type: 'number'
        },
        "intMoratorios_efsy":{
          name: 'intOrdinarios_efsy',
          type: 'number'
        },
        "saldoCont":{
          name: 'saldoCont',
          type: 'number'
        },
        "garantia": {
          name: 'garantia',
          type: 'any'
        },
        "valorEstimado": {
          name: 'valorEstimado',
          type: 'number'
        },
        "fechaRecuperacion": {
          name: 'fechaRecuperacion',
          type: 'Date'
        },
        "fechaAsignacion": {
          name: 'fechaAsignacion',
          type: 'Date'
        },
        "fechaDescPandemia": {
          name: 'fechaAsignacion',
          type: 'Date'
        },
        "fechaFinPandemia": {
          name: 'fechaAsignacion',
          type: 'Date'
        },
        "grupo": {
          name: 'grupo',
          type: 'any'
        },
        "gastosEfectuados": {
          name: 'gastosEfectuados',
          type: 'number'
        },
        "tratamiento": {
          name: 'tratamiento',
          type: 'any'
        },
        "boletinM": {
          name: 'boletinM',
          type: 'any'
        },
        "boletinC": {
          name: 'boletinC',
          type: 'any'
        },
        "mailEnviado": {
          name: 'mailEnviado',
          type: 'boolean'
        },
        "recuperado": {
          name: 'recuperado',
          type: 'number'
        },
        "recuperado2":{
          name: 'recuperado2',
          type: 'boolean'
        },
        "recuAccedde":{
          name: 'recuAccedde',
          type: 'number'
        },
        "honorario": {
          name: 'honorario',
          type: 'number'
        },
        /*"honorariosAbo":{
          name: 'honorariosAbo',
          type: 'number'
        },*/
        "honorariosPeri":{
          name: 'honorariosPeri',
          type: 'number'
        },
        "honorariosRebel":{
          name: 'honorariosRebel',
          type: 'number'
        },
        "honorarioPandemia":{
          name: 'honorariosRebel',
          type: 'number'
        },
        "gPorErogar":{
          name: 'gPorErogar',
          type: 'number'
        },
        "montoRecup":{
          name: 'montoRecup',
          type: 'number'
        },
        "idUsuario": {
          name: 'idUsuario',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        usuario: {
          name: 'usuario',
          type: 'Usuario',
          model: 'Usuario',
          relationType: 'belongsTo',
                  keyFrom: 'idUsuario',
          keyTo: 'idUsuario'
        },
        comentarios: {
          name: 'comentarios',
          type: 'Comentario[]',
          model: 'Comentario',
          relationType: 'hasMany',
                  keyFrom: 'idCaso',
          keyTo: 'idCaso'
        },
        gastos: {
          name: 'gastos',
          type: 'Gasto[]',
          model: 'Gasto',
          relationType: 'hasMany',
                  keyFrom: 'idCaso',
          keyTo: 'idCaso'
        },
        garantias: {
          name: 'garantias',
          type: 'Garantia[]',
          model: 'Garantia',
          relationType: 'hasMany',
                  keyFrom: 'idCaso',
          keyTo: 'idCaso'
        },
        gastosAsignacion: {
          name: 'gastosAsignacion',
          type: 'GastoAsignacion[]',
          model: 'GastoAsignacion',
          relationType: 'hasMany',
                  keyFrom: 'idCaso',
          keyTo: 'idCaso'
        },
        gastosEliminados: {
          name: 'gastosEliminados',
          type: 'GastoEliminado[]',
          model: 'GastoEliminado',
          relationType: 'hasMany',
                  keyFrom: 'idCaso',
          keyTo: 'idCaso'
        },
        embargos: {
          name: 'embargos',
          type: 'Embargo[]',
          model: 'Embargo',
          relationType: 'hasMany',
                  keyFrom: 'idCaso',
          keyTo: 'idCaso'
        },
        archivos: {
          name: 'archivos',
          type: 'Archivo[]',
          model: 'Archivo',
          relationType: 'hasMany',
                  keyFrom: 'idCaso',
          keyTo: 'idCaso'
        },
        InformesMens: {
          name: 'InformesMens',
          type: 'InformeMens[]',
          model: 'InformeMens',
          relationType: 'hasMany',
                  keyFrom: 'idCaso',
          keyTo: 'idCaso'
        },
      }
    }
  }
}
