import { Component, OnInit } from '@angular/core';
import { UsuarioApi, Usuario, Log, LogApi } from '../shared/sdk';

@Component({
  selector: 'app-nuevo-despacho',
  templateUrl: './nuevo-despacho.component.html',
  styleUrls: ['./nuevo-despacho.component.scss']
})
export class NuevoDespachoComponent implements OnInit {

  usuario: Usuario = new Usuario() //inicializa el ojeto usuario para ir agregando las propiedades desde el html
  constructor(public usuarioapi: UsuarioApi, public logapi: LogApi) { }

  ngOnInit() {
  }
  createUser() {
    //agrega propiedades necesarias como el tipo de usuario, correo (fake, pero necesario) y que esta activo
    this.usuario.email = this.usuario.username + '@gmail.com'
    this.usuario.tipo = 2
    this.usuario.activo = true
    this.usuarioapi.create(this.usuario).subscribe((d) => {
      this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
        //log de la accion
        var r: Log = new Log();
        r.createdAt = new Date()
        r.updatedAt = new Date()
        r.idUsuario = us.idUsuario
        r.tipo = 17
        r.contenido = JSON.stringify(d)
        this.logapi.create(r).subscribe((d) => {

        })
      })

      //console.log(d)
    }, (error) => {
      console.log(error)
    })
  }
}
