import { Component, OnInit, ɵbypassSanitizationTrustResourceUrl } from '@angular/core';
import { UsuarioApi, Usuario, User } from '../shared/sdk';

@Component({
  selector: 'app-init',
  templateUrl: './init.component.html',
  styleUrls: ['./init.component.scss']
})
export class InitComponent implements OnInit {

  constructor(public usuarioapi: UsuarioApi) { }

  ngOnInit() {
  }
  generateUsuarios() {
    //1: admins, 2:abogado, 3:Workout
    var users: Usuario[] = []

//ADMINS
    var tu: Usuario = new Usuario();
    tu.tipo = 1;
    tu.username = 'direccion'
    tu.password = 'QPuL7q6g'
    tu.email = 'direccion@gmail.com'
    tu.aMaterno = 'a'
    tu.aPaterno = 'b'
    tu.nombres = 'c'
    tu.activo = true

    var tu2: Usuario = new Usuario();
    tu2.tipo = 1;
    tu2.username = 'sistemas'
    tu2.password = 'XbDPV5DU'
    tu2.email = 'jprieto@accedde.com'
    tu2.aMaterno = 'prieto'
    tu2.aPaterno = 'solis'
    tu2.nombres = 'jose'
    tu2.activo = true

//WORKOUT
    var tu3: Usuario = new Usuario();
    tu3.tipo = 3;
    tu3.username = 'jetssy'
    tu3.password = '2qDmWweC'
    tu3.email = 'jvazquez@accedde.com'
    tu3.aMaterno = 'López'
    tu3.aPaterno = 'Vázquez'
    tu3.nombres = 'Jetssy'
    tu3.activo = true

  

//ABOGADOS
    var tu4: Usuario = new Usuario();
    tu4.tipo = 2;
    tu4.username = 'plazola'
    tu4.password = '3xHeGRzc'
    tu4.email = 'b.tonatiuhplazola@gmail.com'
    tu4.aMaterno = 'Pacheco'
    tu4.aPaterno = 'Plazola'
    tu4.nombres = 'Bernardo T.'
    tu4.activo = true

    var tu5: Usuario = new Usuario();
    tu5.tipo = 2;
    tu5.username = 'decavi'
    tu5.password = '3GQndH8F'
    tu5.email = 'juristas_corporativos@hotmail.com'
    tu5.aMaterno = 'Díaz'
    tu5.aPaterno = 'Delgadillo'
    tu5.nombres = 'Yolanda'
    tu5.activo = true

    var tu6: Usuario = new Usuario();
    tu6.tipo = 2;
    tu6.username = 'sepulveda'
    tu6.password = '6QMuQu5v'
    tu6.email = 'jasepulveda7@gmail.com'
    tu6.aMaterno = 'Lopez'
    tu6.aPaterno = 'Sepulveda'
    tu6.nombres = 'Jose Antonio'
    tu6.activo = true


    users.push(tu)
    users.push(tu2)
    users.push(tu3)
    users.push(tu4)
    users.push(tu5)
    users.push(tu6)
    this.usuarioapi.createMany(users).subscribe((d) => {
      //console.log(d)
    })
  }
}
