import { Component, OnInit } from '@angular/core';
import { Caso, CasoApi, Gasto, GastoAsignacion, GastoAsignacionApi, Log, LogApi, UsuarioApi, Usuario } from '../shared/sdk';
import { concat } from 'rxjs';

@Component({
  selector: 'app-gastos',
  templateUrl: './gastos.component.html',
  styleUrls: ['./gastos.component.scss']
})
export class GastosComponent implements OnInit {
  text: string;
  results: Caso[];
  selectedCaso: Caso
  msgs: any[] = [];
  gastos: GastoAsignacion[]
  gastosOtros: GastoAsignacion[]

  constructor(public usuarioapi:UsuarioApi, public logapi:LogApi,public casoapi: CasoApi, public gastoasignacionapi: GastoAsignacionApi) {

  }

  ngOnInit() {}

  search(event) {
    var t: String = "%" + this.text + "%"
    if (this.text.length > 2) {
      this.casoapi.find({ where: { acreditado: { like: t } }, include: { relation: 'gastosAsignacion', scope: { orderBy: 'tipo ASC' } } }).subscribe((c: Caso[]) => {
        this.results = c
      })
    }
  }
//genera un nuevo objeto gasto conforme al caso que se haya seleccionado, da la lista de gastos de asignacion para que se
//visualize en las tablas, en cas de que ya tenga estos gastos los carga a los input para modificacion
  isSelected(evt) {
    this.selectedCaso = evt
    //console.log(this.selectedCaso)
    var tg:GastoAsignacion[] = []
    var og:GastoAsignacion[] = []
    if (this.selectedCaso.gastosAsignacion.length > 0) {
      for (var g of this.selectedCaso.gastosAsignacion) {
        if (g.tipo == 5) {
          og.push(g)
        } else {
          tg.push(g)
        }
      }
      this.gastos = tg
      this.gastosOtros = og;
    } else {
      this.gastos = [
        { tipo: 1, monto: 0, caso: this.selectedCaso, idGastoAsignacion: null, concepto: null, idCaso: this.selectedCaso.idCaso, createdAt: new Date(), updatedAt: new Date() },
        { tipo: 2, monto: 0, caso: this.selectedCaso, idGastoAsignacion: null, concepto: null, idCaso: this.selectedCaso.idCaso, createdAt: new Date(), updatedAt: new Date() },
        { tipo: 3, monto: 0, caso: this.selectedCaso, idGastoAsignacion: null, concepto: null, idCaso: this.selectedCaso.idCaso, createdAt: new Date(), updatedAt: new Date() },
        { tipo: 4, monto: 0, caso: this.selectedCaso, idGastoAsignacion: null, concepto: null, idCaso: this.selectedCaso.idCaso, createdAt: new Date(), updatedAt: new Date() },
      ]
      this.gastosOtros = [{ tipo: 5, monto: 0, caso: this.selectedCaso, idGastoAsignacion: null, concepto: null, idCaso: this.selectedCaso.idCaso, createdAt: new Date(), updatedAt: new Date() }]
    }
  }

  //agrega otro ojeto de gasto de asignacion al array
  addGasto() {
    var ga = new GastoAsignacion();
    ga.caso = this.selectedCaso
    ga.createdAt = new Date()
    ga.updatedAt = new Date()
    ga.tipo = 5
    ga.idCaso = this.selectedCaso.idCaso
    this.gastosOtros.push(ga)
    //console.log(this.gastos)
  }
  //si no esta en cero el gasto lo agrega
  setGastos() {
    function isNotZero(element: GastoAsignacion, index, array) {
      return (element.monto > 0);
    }
    var finalg:GastoAsignacion[] = this.gastos.concat(this.gastosOtros).filter(isNotZero);
    //console.log(typeof(finalg))
//crea el array final de gastos antes de subirlo a la base,
    if (this.selectedCaso.gastosAsignacion.length > 0) {

      for(var g of this.gastosOtros)
      {
        //crea uno por uno los gastos del array agregadnno el log de casa uno
        this.gastoasignacionapi.upsert(g).subscribe((d) => {
          this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
            var r: Log = new Log();
            r.createdAt = new Date()
            r.updatedAt = new Date()
            r.idUsuario = us.idUsuario
            r.tipo = 13
            r.contenido = 'Username: ' +us.username + '| Creo Gastos Asignacion: '+JSON.stringify(d)
            this.logapi.create(r).subscribe((d) => {
      
            })
            //console.log(d)
            this.msgs.push({ severity: 'success', summary: 'Exito', detail: 'Caso Actualizado Exitosamente' });
    
          })
        
        },
          (error) => {
            //console.log(error)
            this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Ocurrio un problema al actualizar caso, contactar a sistemas' });
  
          })
      }
  

    } else {
      //si ya estaban creados y solo los actualizaba entonces utiliza este metodo.
      this.gastoasignacionapi.createMany(finalg).subscribe((d) => {
        //console.log(d)
        this.msgs.push({ severity: 'success', summary: 'Exito', detail: 'Caso Actualizado Exitosamente' });

      },
        (error) => {
          this.msgs.push({ severity: 'error', summary: 'Error', detail: 'Ocurrio un problema al actualizar caso, contactar a sistemas' });

        })
    }


  }

}
