import { Garantia } from "./../shared/sdk/models/Garantia";
import { Embargo } from "./../shared/sdk/models/Embargo";
import { EmbargoApi } from "./../shared/sdk/services/custom/Embargo";
import { VerArchivosModalComponent } from "./../ver-archivos-modal/ver-archivos-modal.component";
import { SubirArchivoModalComponent } from "./../subir-archivo-modal/subir-archivo-modal.component";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
  CasoApi,
  Caso,
  UsuarioApi,
  Usuario,
  ContainerApi,
  Comentario,
  ComentarioApi,
  GastoAsignacion,
  Gasto,
  Log,
  LogApi,
  MailApi,
  MensajeNotificacionApi,
  MensajeNotificacion
} from "../shared/sdk";
import { HttpHeaders } from "@angular/common/http";
import { ContextMenu } from "primeng/contextmenu";
import {
  MenuItem,
  DialogService,
  MessageService,
  TTBody
} from "primeng/primeng";
import { EditarModalComponent } from "../editar-modal/editar-modal.component";
import { HistorialModalComponent } from "../historial-modal/historial-modal.component";
import { Editar2ModalComponent } from "../editar2-modal/editar2-modal.component";
import { CambiarEstadoComponent } from "../cambiar-estado/cambiar-estado.component";
import { PopupModalComponent } from "../popup-modal/popup-modal.component";
import { Router } from "@angular/router";
import { from, Observable } from "rxjs";
import * as fileSaver from "file-saver";
import { HttpResponse, HttpClient } from "@angular/common/http";
import * as XLSX from "xlsx";
import { ActualizacionesModalComponent } from "../actualizaciones-modal/actualizaciones-modal.component";
import { InformeMens } from "../shared/sdk/models/InformeMens";
import { InformeApi } from "../shared/sdk/services/custom/InformeMens";
import { THIS_EXPR, ThrowStmt } from "@angular/compiler/src/output/output_ast";
import { VerInformeComponent } from "../ver-informe/ver-informe.component";
import { ServicioInformeService } from "../servicio-informe.service";
import { NoteHistoryComponent } from "../historial-notas/historial-notas.component";
import { CrateNoteComponent } from "../crear-nota/crear-notas.component";
import { data, event } from "jquery";
import { async } from "@angular/core/testing";
import { createOptional } from "@angular/compiler/src/core";
import { Moment } from "moment";
import * as moment from "moment";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
const EXCEL_EXTENSION = ".xlsx";

@Component({
  selector: "app-cartera",
  templateUrl: "./cartera.component.html",
  styleUrls: ["./cartera.component.css"],
  providers: [DialogService, MessageService],
})
export class CarteraComponent implements OnInit {
  actualDate = new Date();
  rowIndex;
  mesInput: Date; //VARIABLE QUE GUARDARA EL VALOR DADO DEL MES DESDE EL INPUT
  añoInput: Date; //VARIABLE QUE GUARDARA EL VALOR DEL AÑO DADO DESDE EL INPUT
  principioMes: any;
  arreglo: any;
  finalMes: any; //VARIABLE PARA BUSCAR INFORME MENSUAL
  elementoHTML: any;
  rompeCiclos: boolean;
  fechaFinal: any; //VARIABLE PARA BUSCAR INFORME MENSUAL
  imprimirInforme: any;
  compararId: number;
  fechaAsignSplit: any;
  honorarioPandemia: any;
  honorarioPrePandemia: any;
  honorarioPostPandemia: any;
  sticker: Boolean;
  honorarios: number;
  showTotals = false;
  intOrdinarios: any;
  MNDate: any;
  //importando todos los modelos de sdk
  constructor(
    private servicioInforme: ServicioInformeService,
    public informeApi: InformeApi,
    public embargoapi: EmbargoApi,
    public logapi: LogApi,
    private http: HttpClient,
    public router: Router,
    public comentarioapi: ComentarioApi,
    public dialogService: DialogService,
    public casoapi: CasoApi,
    public usuarioapi: UsuarioApi,
    public containerapi: ContainerApi,
    public mailApi: MailApi,
    public mensajenotificacionApi: MensajeNotificacionApi
  ) {}

  ngOnInit() {
    //inicializando componentes
    this.items = [
      {
        label: "Ver Historico",
        icon: "pi pi-search",
        command: (event) => this.historial(event)
      },
      {
        label: "Crear Nota",
        icon: "fa fa-address-book-o",
        command: (event) => this.createNotas(event)
      },
      {
        label: "Ver Notas",
        icon: "fa fa-eye",
        command: (event) => this.historialNotas(event)
      },
      {
        label: "Editar",
        icon: "pi pi-pencil",
        command: (event) => this.editarCaso2(event)
      },
      {
        label: "Cambiar Estado",
        icon: "fa fa-gavel",
        command: (event) => this.cambiarEstado(event)
      }
    ];
    
    /*Servicio para obtener el contenio de la ventana modal de Informe Mensual
    this.servicioInforme.disparadorDeInformes.subscribe(data =>{
      console.log("datos desde el modal de ver informes " + data)
      this.imprimirInforme = data
    })
    this.servicioInforme.envioId.subscribe(data =>{
      this.compararId = data
    })*/
    //cuando abre la pagina llama a todos los casos
    //ejemplo de query
    this.casoapi
      .find({
        include: [
          {
            relation: "usuario",
            scope: {
              fields: ["username", "idUsuario", "honorario", "archivos"],
            },
          },
          { relation: "comentarios", scope: { order: "createdAt DESC" } },
          { relation: "gastos" },
          { relation: "gastosAsignacion" },
          { relation: "embargos" },
          { relation: "garantias" },
          { relation: "InformesMens" }
        ],
      })
      .subscribe((casosResponse: Caso[]) => {
        this.sendMessage(casosResponse);
        this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
          var r: Log = new Log();
          r.createdAt = new Date();
          r.updatedAt = new Date();
          r.idUsuario = us.idUsuario;
          r.tipo = 2;
          r.contenido = "";
          this.logapi.create(r).subscribe((d) => {
            //crea una entrada en el log para esta accion
          });
        });
        
        this.casos = casosResponse;
        //en caso de que sea la primera ves que entra a esta pantalla en esta sesion del navegador
        //le mostrata el popup con el resumen de los datos financieros de los casos
        if (!this.popupClosed) {
          if (!sessionStorage.getItem("popupClosed")) {
            const ref = this.dialogService.open(PopupModalComponent, {
              data: {
                casos: casosResponse,
              },
              width: "50%",
              showHeader: false,
              dismissableMask: true,
              baseZIndex: 1030,
            });
            ref.onClose.subscribe(() => {
              this.popupClosed = true;
              sessionStorage.setItem("popupClosed", "y");
            });
          }
        }
        //mas inicializaciones
        const uniqueacreditado = [
          ...new Set(casosResponse.map((item) => item.acreditado)),
        ];
        uniqueacreditado.sort();
        uniqueacreditado.forEach((element) => {
          this.dropdownacreditado.push({ label: element, value: element });
        });

        const uniquegrupo = [
          ...new Set(casosResponse.map((item) => item.grupo)),
        ];
        uniquegrupo.sort();
        uniquegrupo.forEach((element) => {
          this.dropdowngrupo.push({ label: element, value: element });
        });
      });
    //mas inicializaciones
    this.usuarioapi
      .find({
        fields: { idUsuario: true, username: true },
        where: { tipo: 2 },
        include: "casos",
      })
      .subscribe((usuariosl: Usuario[]) => {
        this.usuarios = usuariosl;
        for (var u of usuariosl) {
          this.abogados.push({ label: u.username, value: u.idUsuario });
        }
      });
  }
  
  async sendMessage(casos: any) {
    let todosLosCasosInactivos: Array<any>;
    let casosInactivos: Array<any>;
    let indice = 0;
    this.mensajenotificacionApi
      .find({ order: "idMensajeNotificacion DESC", limit: 1 })
      .subscribe((MN: any[]) => {
        this.MNDate = MN[0] ? MN[0].createdAt : null;
        if (moment().diff(this.MNDate, "days") > 0 || MN.length == 0) {
          todosLosCasosInactivos = casos.filter((caso) => {
            return caso.comentarios != 0;
          });
          casosInactivos = todosLosCasosInactivos.map((casoI) => {
            return casoI.comentarios;
          });
          for (const iterator of casosInactivos) {
            if (iterator[0] != null) {
              if (this.diasHastaCaducidad(iterator[0].createdAt) == 90) {
                indice++;
                let headerst = new HttpHeaders();
                headerst.append("Content-Type", "application/json");
                headerst.append("Access-Control-Allow-Origin", "*");
                let url =
                  "https://chat.hisala.mx/hooks/gb15qdd8x7guuj9y3oo7c5qpgo";
                let body = JSON.stringify({
                  text: `El caso ${iterator[0].idCaso} del abogado ${todosLosCasosInactivos[indice].usuario.username} con el acreditado ${todosLosCasosInactivos[indice].acreditado} con el capital ${todosLosCasosInactivos[indice].capital} tiene 90 dias de inactividad`,
                });
                this.http.post(url, body, { headers: headerst }).subscribe(
                  (response) => {},
                  (err) => console.log("response", err)
                );
              }
            }
          }
          this.mensajenotificacionApi.create({ createdAt: new Date() }).subscribe((r) => {});
        }
      });
  }

  openHistorialAsignacionModal(caso) {
    /* Agregar Funcionalidad Aqui */
  }
  //la mayoria de estos metodos son para acondicionar los datos crudos de la base para visualizacion enn
  //la tabla

  //LO SIGUIENTE SE DEJA COMENTADO YA QUE PUEDE SERVIR A FUTURO!!
  //FUNCION PARA CAPTURAR LO VALORES DADOS EN EL INPUT
  //elementoHTML ES ES INPUT DE TIPO DATE Y SUS VALORES SE GUARDAN EN LAS VARIABLES
  //mesInput Y añoInput
  /*getInputFecha(){
    
   this.elementoHTML
    this.elementoHTML =  document.getElementById("fecha-input");
   this.arreglo = this.elementoHTML.value.split("-")
    this.finalMes = this.arreglo[0]+'/'+this.arreglo[1]+'-'+'31'
    this.principioMes = this.arreglo[0]+'/'+this.arreglo[1]+'-'+'01'
    this.mesInput = this.arreglo[1];
    this.añoInput = this.arreglo[0];
    if(this.elementoHTML.value == null ||this.elementoHTML.value ==""){
      this.rompeCiclos = false
    }
    else{
      this.rompeCiclos = true
    }
    //INICIO DE LA CONSULTA
    /*for(var ids of this.casos){
    this.informeApi.find({
      where:{idCaso:ids.idCaso.toString,
            createdAt:{
              between:[new Date(this.principioMes).toISOString(),
              new Date(this.finalMes).toISOString(),] as [string, string],
            },
        groupBy: 'idCaso'
      }, 
      include: 'caso',
    
      
      }).subscribe((i: InformeMens[]) => {
        
        //this.informeFinal = `${this.fechaFinal} | ${i.Contenido}`
        this.ArrayInformes = i
            for(var x of i){
              let index 
            index = this.ArrayInformes.findIndex(obj =>obj.createdAt ==x.createdAt)
            //console.log(index)
            this.ArrayInformes[index].Fecha = this.arreglo[1]+'/'+this.arreglo[0];
            console.log(this.ArrayInformes[index].idCaso+" "+this.ArrayInformes[index].Fecha+ " "+ this.ArrayInformes[index].Contenido)
            //this.informeFinal = `${this.fechaFinal} | ${x.Contenido}`
            //this.contenidoInformes.push(this.informeFinal)
            //console.log(x)
            //this.arreglito.push(x);
            //console.log(this.arreglito)
            }      
      });
      }
    }*/

  /*//FUNCION PARA MOSTRAR MES Y AÑO DEL INFORME EN LA TABLA DE LA APLICACION WEB
  concatenarMeses(InformesMens: InformeMens[]){
    let elmes: Date
    let elmesSpliteado: any
    let x = ''
    let spliteado
    let MuestraFinal

    if(this.principioMes != null && this.finalMes != null && this.rompeCiclos ==true){
    elmesSpliteado = this.arreglo[1]+'/'+this.arreglo[0]
    this.informeApi.find({
      where:{
            createdAt:{
              between:[new Date(this.principioMes).toISOString(),
              new Date(this.finalMes).toISOString(),] as [string, string],
            },
        groupBy: 'idCaso'
      }, 
      include: 'caso',
    
      
      }).subscribe((i: InformeMens[]) => {
        
        //this.informeFinal = `${this.fechaFinal} | ${i.Contenido}`
        this.ArrayInformes = i
            for(var x of i){
            let index 
            index = this.ArrayInformes.findIndex(obj =>obj.createdAt ==x.createdAt)
            this.ArrayInformes[index].Fecha = this.arreglo[1]+'/'+this.arreglo[0];
            //console.log(index+" "+this.ArrayInformes[index].idCaso+" "+this.ArrayInformes[index].Fecha+" "+this.ArrayInformes[index].Contenido)
            if(elmesSpliteado==this.ArrayInformes[index].Fecha){
              this.fechaFinal= this.ArrayInformes[index].Fecha+" | "+this.ArrayInformes[index].Contenido
            }
            else{
              this.fechaFinal = null
            }
            //console.log(this.ArrayInformes[index].idCaso+" "+this.ArrayInformes[index].Fecha+ " "+ this.ArrayInformes[index].Contenido)
            //this.informeFinal = `${this.fechaFinal} | ${x.Contenido}`
            //this.contenidoInformes.push(this.informeFinal)
            //console.log(x)
            //this.arreglito.push(x);
            //console.log(this.arreglito)
            }
            
      });
    /*for (var h of InformesMens) {
      let index 
      if(this.principioMes != null && this.finalMes != null){
      elmes = h.createdAt
      x += elmes
      spliteado = x.split("-")
      elmesSpliteado = this.arreglo[1]+'-'+this.arreglo[0]
      //console.log(h.idCaso +" "+spliteado[1]+" "+this.mesInput+" "+h.Contenido);
      if(spliteado[1] == this.mesInput && spliteado[0] == this.añoInput){
    
        //console.log(h.idCaso +" "+h.createdAt+" "+this.mesInput+" "+h.Contenido);
        fechaFinal = spliteado[1]+ '/'+spliteado[0]+' | ' +h.Contenido
        //debugger
        //fechaFinal = this.informeFinal
      }
      else{
        fechaFinal = null; 
      }
    }
    else{
      fechaFinal = null;
    }
    }
    //console.log(" "+this.ArrayInformes[index].Contenido+" "+this.ArrayInformes[index].Fecha)  
    this.rompeCiclos = false
    for (var h of InformesMens) {
      for(var y of this.casos){
      elmes = h.createdAt
      x += elmes
      spliteado = x.split("-")
      console.log(h.idCaso)
      console.log(y.idCaso)
      //console.log(h.idCaso +" "+spliteado[1]+" "+this.mesInput+" "+h.Contenido);
      if(h.idCaso == y.idCaso){
        //console.log(h.idCaso +" "+h.createdAt+" "+this.mesInput+" "+h.Contenido);
        MuestraFinal = 'holi'
        //debugger
        //fechaFinal = this.informeFinal
      }
      else{
        MuestraFinal = null; 
      }
    }
    }
    
    }
    return MuestraFinal
  
}*/
  //FUNCIONA PARA MOSTRAR EL CONTENIDO DEL INFORME MENSUAL EN LA APLICACION WEB
  // Y EN EL EXCEL
  /*concatenarInformes(InformesMens: InformeMens[]){
    var str = ''
    for (var h of InformesMens) {
          if(h.Contenido != null  || h.Contenido != ''){
          str +=' | ' + h.Contenido + '\n'
          }
          else{
            str = null
          }
        }
    return str;
  }*/

  //FUNCION PARA MOSTRAR LOS MESES EN EL EXCEL
  /*concatenarMesesExcel(InformesMens: InformeMens[]){
    let fecha: Date
    let str =''
    let año: any
    let mes: any
    let x = ''
    let spliteadoExcel
    for (var m of InformesMens){
      fecha = m.createdAt
      x += fecha
      spliteadoExcel = x.split("-")
      if(spliteadoExcel[1] == this.mesInput && spliteadoExcel[0] == this.añoInput)
      {
      año = this.añoInput;
      mes = this.mesInput;
      str += mes + '/' + año+' ';
      }
      else{
        str = '';
      }
    }
    return str;
  }*/


  id;
  objStyleFixed;
  indexEvent;
  saldoContable;
  unfix() {
    this.sticker = false;
  }
  fixed($event, rowIndex) {
    this.indexEvent = 0;
    let y = String($event.y - 80 + "px");
    let x = String($event.x - 30.3 + "px");
    this.id = rowIndex;
    if ($event.y > 0 && $event.x > 0 && this.indexEvent < 1) {
      this.objStyleFixed = {
        position: "fixed",
        top: y,
        left: x,
        padding: "35px",
        background: "#F0FFFF",
        border: "1px solid grey",
        boxShadow: "7px 7px 3px #aaaaaa",
        zIndex: "10"
      };
    }
    y = "";
    x = "";
    this.indexEvent++;
    this.sticker = true;
  }
  capital;
  totalgastos;
  recuperado_juicio;
  recuperado_Accedde;
  async summation(event) {
    let suma = [];
    this.showTotals = true;
    if (event.length != 0) {
      let casosFiltrados = [];
      casosFiltrados = this.casos.filter((caso) => {
        for (let index = 0; index < event.length; index++) {
          if (caso.idUsuario == event[index]) {
            return true;
          }
        }
      });
      await casosFiltrados.forEach((data: any) => {
        if (data.honorarioPandemia && data.fechaDescPandemia) {
          suma.push(Number(this.getAcumuladoHonorarios(data.fechaAsignacion,data.honorario,data.fechaDescPandemia,data.honorarioPandemia,data.fechaFinPandemia)));
        } else {
          suma.push(Number(this.getAcumuladoHonorarios(data.fechaAsignacion,data.honorario,null,null,null)));
        }
      });
      this.honorarios = await suma.reduce((total, num) => { return total + num });
      this.saldoContable = casosFiltrados.map((num: any) => { return num.saldoCont });
      this.saldoContable = await this.saldoContable.reduce((total, num) => { return total + num });
      this.capital = casosFiltrados.map((num: any) => { return num.capital });
      this.capital = await this.capital.reduce((total, num) => { return total + num });
      this.intOrdinarios = casosFiltrados.map((num: any) => { return num.intOrdinarios });
      this.intOrdinarios = await this.intOrdinarios.reduce((total, num) => { return total + num });
      this.recuperado_juicio = casosFiltrados.map((num: any) => { return num.recuperado });
      this.recuperado_juicio = await this.recuperado_juicio.reduce((total, num) => { return total + num });
      this.recuperado_Accedde = casosFiltrados.map((num: any) => { return num.recuAccedde });
      this.recuperado_Accedde = await this.recuperado_Accedde.reduce((total, num) => { return total + num });
    } else {
      this.honorarios = 0;
      this.saldoContable = 0;
      this.capital = 0;
      this.intOrdinarios = 0;
      this.recuperado_juicio = 0;
      this.recuperado_Accedde = 0;
    }
  }

  idRow: number;
  selectId(rowIndex) {
    this.idRow = rowIndex;
  }

  concatenarComentarios(comentarios: Comentario[]) {
    var str = "";
    for (var c of comentarios) {
      str += c.createdAt + "|" + c.contenido + "|" + c.archivo + " \n";
    }
    return str;
  }

  //Funcion que nos dara la sumatoria total de los gastos y gastos de asignacion por caso
  getTotalGastos(fecha, gastos, gastosasign) {
    var diferenciaEnMeses = Math.abs(+new Date(fecha) - +this.actualDate);
    diferenciaEnMeses = diferenciaEnMeses / 1000 / 60 / 60 / 24 / 30;
    //var acumuladoHonorarios = parseInt((Number.parseInt(diferenciaEnMeses.toString()) * caso.honorario).toFixed(0))
    var acumuladoGastos = this.getAcumuladoGastos(gastos);
    var acumuladoGastosAsignacion =
      this.getAcumuladoGastosAsignacion(gastosasign);

    return acumuladoGastos + acumuladoGastosAsignacion;
  }

  //FUNCION QUE DETECTA SI LA FECHA DE RECUPERACION YA ESTA CERCA DEL LIMITE
  // PONDRA EN ROJO LA FECHA DE RECUPRACION CUANDO FALTEN MENOS O IGUAL DE 91.2501 DIAS (3 MESES) PARA RECUPERARLO.
  ponerFechaRecRojo(caso: Caso) {
    let dias = +new Date(caso.fechaRecuperacion) - +this.actualDate;
    dias = dias / 1000 / 60 / 60 / 24;
    let diasFaltantes: any = Number.parseInt(dias.toString()).toFixed(0);

    if (diasFaltantes <= 91.2501) {
      return true;
    } else {
      return false;
    }
  }

  //para exportar hay que de nuevo acondicionar los datos para que se impriman correctamente en el excel
  //muchos switches e ifs para convertir un 0,1,2 en 'si, no, talvz' por decir un ejemplo
  export(tt) {
    var casolist: tableCaso[] = [];
    var listacasos: Caso[] =
      tt.bodyTemplate._projectedViews[0].parent.oldValues[3];
    for (var d of listacasos) {
      var c = new tableCaso();
      c.id = d.id;
      c.abogado = d.usuario.username;

      if (d.comentarios.length == 0) {
        c.dias_ultima_actualizacion = "Sin Comentario";
      } else {
        c.dias_ultima_actualizacion = this.diasHastaCaducidad(
          d.comentarios[0].createdAt
        ).toString();
      }

      switch (d.tramo) {
        case 0:
          c.tramo = "1 Tramo";
          break;
        case 1:
          c.tramo = "2 Tramo";
          break;
        case 2:
          c.tramo = "3 Tramo";
          break;
        case 3:
          c.tramo = "Propia Bad Banking";
          break;
        case 4:
          c.tramo = "FEGA/FONAGA";
          break;
        case 5:
          c.tramo = "Propia Good Banking";
          break;
        case 6:
          c.tramo = "GRUPAL";
          break;
        /*case 7:
          c.tramo = "X.F."
          break;*/
        default:
          c.tramo = "";
          break;
      }

      c.numero_de_Control = d.numeroControl;
      c.acreditado = d.acreditado;
      c.Castigo_fira = d.Cast_fira; //COLUMNA NUEVA
      c.Castigo_accedde = d.Cast_accedde; // COLUMNA NUEVA

      //SE AGREGAN LAS OPCIONES DE LOS ESTADOS DE ETAPA PROCESAL
      switch (d.Et_procesal) {
        case 0:
          c.Etapa_procesal = "Demanda";
          break;
        case 1:
          c.Etapa_procesal = "Admision de demanda";
          break;
        case 2:
          c.Etapa_procesal = "Diligencia de requerimiento de pago y embargo";
          break;

        case 3:
          c.Etapa_procesal = "Contestacion de demanda";
          break;

        case 4:
          c.Etapa_procesal = "Desahogo de vista de contestacion de demanda";
          break;

        case 5:
          c.Etapa_procesal = "Pruebas";
          break;

        case 6:
          c.Etapa_procesal = "Desahogo de pruebas";
          break;

        case 7:
          c.Etapa_procesal = "Alegatos";
          break;

        case 8:
          c.Etapa_procesal = "Sentencia";
          break;

        case 9:
          c.Etapa_procesal = "Ejecucion de sentencia";
          break;

        case 10:
          c.Etapa_procesal = "Procedimiento de remate";
          break;

        case 11:
          c.Etapa_procesal = "Adjudicacion";
          break;

        default:
          c.Etapa_procesal = "";
          break;
      }

      switch (d.estatusM) {
        case 0:
          c.Estatus_mercantil = "Presentacion de la Demanda";
          break;
        case 1:
          c.Estatus_mercantil = "Admision";
          break;
        case 2:
          c.Estatus_mercantil = "Diligencia de los Requerimientos, Emplazamiento y Embargo";
          break;
        case 3:
          c.Estatus_mercantil = "Etapa Probatoria";
          break;
        case 4:
          c.Estatus_mercantil = "Alegatos";
          break;
        case 5:
          c.Estatus_mercantil = "Sentencia de la Instancia";
          break;
        case 6:
          c.Estatus_mercantil = "Ejecucion de la Sentencia";

          break;
        case 7:
          c.Estatus_mercantil = "Procedimiento de Remate";
          break;
        default:
          c.Estatus_mercantil = "";
          break;
      }
      switch (d.impugnacionM) {
        case 0:
          c.Impugnacion_mercantil = "Incidente";
          break;
        case 1:
          c.Impugnacion_mercantil = "Recurso de revocacion";
          break;
        case 2:
          c.Impugnacion_mercantil = "Recurso de apelacion";
          break;
        case 3:
          c.Impugnacion_mercantil = "Amparo";
          break;
        case 4:
          c.Impugnacion_mercantil = "Amparo en revision";
          break;
        default:
          c.Impugnacion_mercantil = "";
          break;
      }

      switch (d.juzgadoM) {
        case 26:
          c.Juzgado_mercantil = "J°1 M.";
          break;
        case 27:
          c.Juzgado_mercantil = "J°2 M.";
          break;
        case 28:
          c.Juzgado_mercantil = "J°3 M.";
          break;
        case 29:
          c.Juzgado_mercantil = "J°4 M.";
          break;
        case 30:
          c.Juzgado_mercantil = "J°5 M.";
          break;
        case 31:
          c.Juzgado_mercantil = "J°6 M.";
          break;
        case 32:
          c.Juzgado_mercantil = "J°7 M.";
          break;
        case 33:
          c.Juzgado_mercantil = "J°8 M.";
          break;
        case 34:
          c.Juzgado_mercantil = "J°9 M.";
          break;
        case 35:
          c.Juzgado_mercantil = "J°10 M.";
          break;
        case 36:
          c.Juzgado_mercantil = "J°1 O.M.";
          break;
        case 37:
          c.Juzgado_mercantil = "J°2 O.M.";
          break;
        case 38:
          c.Juzgado_mercantil = "J°3 O.M.";
          break;
        case 39:
          c.Juzgado_mercantil = "J°4 O.M.";
          break;
        case 40:
          c.Juzgado_mercantil = "J°5 O.M.";
          break;
        case 41:
          c.Juzgado_mercantil = "J°6 O.M.";
          break;
        case 42:
          c.Juzgado_mercantil = "J°7 O.M.";
          break;
        case 43:
          c.Juzgado_mercantil = "J°8 O.M.";
          break;
        case 44:
          c.Juzgado_mercantil = "J°9 O.M.";
          break;
        case 45:
          c.Juzgado_mercantil = "J°10 O.M.";
          break;
        default:
          c.Juzgado_mercantil = "";
          break;
      }
      switch (d.estatusC) {
        case 0:
          c.Estatus_civil = "Contestacion de la Demanda";
          break;
        case 1:
          c.Estatus_civil = "Reconvencion";
          break;
        case 2:
          c.Estatus_civil = "Etapa Probatoria";
          break;
        case 3:
          c.Estatus_civil = "Alegatos";
          break;
        case 4:
          c.Estatus_civil = "Sentencia de la Instancia";
          break;
        default:
          c.Estatus_civil = "";
          break;
      }
      switch (d.impugnacionC) {
        case 0:
          c.Impugnacion_civil = "Apelacion";
          break;
        case 1:
          c.Impugnacion_civil = "Revocacion";
          break;
        case 2:
          c.Impugnacion_civil = "Queja";
          break;
        case 3:
          c.Impugnacion_civil = "Amparo Directo";
          break;
        case 4:
          c.Impugnacion_civil = "Amparo Indirecto";
          break;
        case 5:
          c.Impugnacion_civil = "Suspendido";
          break;
        case 6:
          c.Impugnacion_civil = "Caducado";
          break;
        default:
          c.Impugnacion_civil = "";
          break;
      }

      switch (d.juzgadoC) {
        case 0:
          c.Juzgado_civil = "J°1 C.";
          break;
        case 1:
          c.Juzgado_civil = "J°2 C.";
          break;
        case 2:
          c.Juzgado_civil = "J°3 C.";
          break;
        case 3:
          c.Juzgado_civil = "J°4 C.";
          break;
        case 4:
          c.Juzgado_civil = "J°5 C.";
          break;
        case 5:
          c.Juzgado_civil = "J°6 C.";
          break;
        case 6:
          c.Juzgado_civil = "J°7 C.";
          break;
        case 7:
          c.Juzgado_civil = "J°8 C.";
          break;
        case 8:
          c.Juzgado_civil = "J°9 C.";
          break;
        case 9:
          c.Juzgado_civil = "J°10 C.";
          break;
        case 10:
          c.Juzgado_civil = "J°11 C.";
          break;
        case 11:
          c.Juzgado_civil = "J°12 C.";
          break;
        case 12:
          c.Juzgado_civil = "J°13 C.";
          break;
        case 13:
          c.Juzgado_civil = "J°14 C.";
          break;
        case 14:
          c.Juzgado_civil = "J°1 O.C.";
          break;
        case 15:
          c.Juzgado_civil = "J°2 O.C.";
          break;
        case 16:
          c.Juzgado_civil = "J°3 O.C.";
          break;
        case 17:
          c.Juzgado_civil = "J°4 O.C.";
          break;
        case 18:
          c.Juzgado_civil = "J°5 O.C.";
          break;
        case 19:
          c.Juzgado_civil = "J°6 O.C.";
          break;
        case 20:
          c.Juzgado_civil = "J°7 O.C.";
          break;
        case 21:
          c.Juzgado_civil = "J°8 O.C.";
          break;
        case 22:
          c.Juzgado_civil = "J°9 O.C.";
          break;
        case 23:
          c.Juzgado_civil = "J°10 O.C.";
          break;
        case 24:
          c.Juzgado_civil = "J°11 O.C.";
          break;
        case 25:
          c.Juzgado_civil = "J°12 O.C.";
          break;
        case 26:
          c.Juzgado_civil = "J°13 O.C.";
          break;
        case 27:
          c.Juzgado_civil = "J°14 O.C.";
        default:
          c.Juzgado_civil = "";
          break;
      }
      c.Expediente_mercantil = d.expedienteM;
      c.Expediente_civil = d.expedienteC;
      c.Expediente_TOCA = d.expToca;
      c.Saldo_contable = d.saldoCont;
      c.Capital = d.capital;

      c.Intereses_ordinarios = d.intOrdinarios;
      c.Intereses_moratorios = d.intMoratorios;
      c.total = d.capital + d.intOrdinarios + d.intMoratorios;
      c.Capital_efsy = d.cap_efsy; //NUEVA COLUMNA
      c.Intereses_ordinarios_efsy = d.intOrdinarios_efsy; //NUEVA COLUMNA
      c.Intereses_moratorios_efsy = d.intMoratorios_efsy; //NUEVA COLUMNA
      c.total_efsy = d.cap_efsy + d.intOrdinarios_efsy + d.intMoratorios_efsy; //NUEVA COLUMNA

      c.Sala = d.sala;
      c.reservas = d.reservas;
      c.Monto_castigo_por_castigar = d.montoCast;
      c.Concepto_castigo = d.conceptoCast;

      c.Bienes_embargados = this.concatEmbargos(d.embargos);
      c.inscripcionEmbargo = this.getInscripcion(d.embargos);
      //-------------------------------------------------------------
      //SI ALGUNO DE LOS 2 METODOS ENVIA DATO NULO, NO IMPRIME NADA EN EL ARCHIVO DE EXCEL
      /*if(this.concatenarMeses(d.InformesMens) == null || this.concatenarInformes(d.InformesMens) == null){
      c.InformesMens = ''
    }
    //CASO CONTRARIO DONDE SI TODO ESTA BIEN, ENVIA LOS INFORMES MENSUALES CORRESPONDIENTES
    else{
    c.InformesMens = this.concatenarMesesExcel(d.InformesMens) + this.concatenarInformes(d.InformesMens);
    }*/
      //------------------------------------------------------------

      if (d.comentarios.length == 0) {
        c.comentarios = "";
      } else {
        c.comentarios = this.concatenarComentarios(d.comentarios);
      }
      //SE CAMBIA EL VALOR PARA QUE LO LEA DIRECTO DE LAS COLUMNAS "garantia" y "valorEstimado"
      c.garantia = d.garantia;
      c.valorEstimado = d.valorEstimado;

      c.fechaRecuperacion =
        (d.fechaRecuperacion && d.fechaRecuperacion.toString()) || null; //
      //  c.fechaRecuperacion = d.fechaRecuperacion.toString()?//
      c.fechaAsignacion = (d.fechaAsignacion && d.fechaAsignacion.toString()) || null; //
      c.grupo = d.grupo;

      c.Gastos_efectuados_en_el_juicio = this.getTotalGastos(
        d.fechaAsignacion,
        d.gastos,
        d.gastosAsignacion
      ); // NUEVA COLUMNA

      c.honorarios = Number.parseInt(
        this.getAcumuladoHonorarios(
          d.fechaAsignacion,
          d.honorario,
          d.fechaDescPandemia,
          d.honorarioPandemia,
          d.fechaFinPandemia
        )
      );
      c.honorarios_perito = Number.parseInt(
        this.getAcumuladoPeritoYRebeldia(d.fechaAsignacion, d.honorariosPeri)
      );
      c.honorarios_perito_en_rebeldia = Number.parseInt(
        this.getAcumuladoPeritoYRebeldia(d.fechaAsignacion, d.honorariosRebel)
      );
      //c.gastosAsignacion = 0//
      //c.gastosAbogado = 0//
      //c.totalGastos = c.honorarios
      c.Estatus_boletin_mercantil = d.boletinM;
      c.Estatus_boletin_civil = d.boletinC;
      c.tratamiento = d.tratamiento;

      c.Gasto_erogado =
        c.Gastos_efectuados_en_el_juicio +
        c.honorarios +
        c.honorarios_perito +
        c.honorarios_perito_en_rebeldia; //NUEVA COLUMNA
      c.Gasto_por_erogar = d.gPorErogar;
      c.Monto_estimado_de_recuperacion = d.montoRecup;
      c.Recuperado_en_juicio = d.recuperado;
      if (d.recuAccedde <= 0 || d.recuAccedde == null) {
        c.Recuperado_Accedde = d.recuAccedde;
      } else {
        c.Recuperado_Accedde =
          d.recuAccedde -
          c.Gastos_efectuados_en_el_juicio -
          c.honorarios -
          c.honorarios_perito -
          c.honorarios_perito_en_rebeldia; // NUEVA COLUMNA
      }
      casolist.push(c);
    }
    this.exportAsExcelFile(casolist, "Casos");
  }

  //mas acondicionamiento de datos
  getGarantias(caso: Caso) {
    var str = " ";
    if (caso.garantias.length > 0) {
      for (var g of caso.garantias) {
        if (g.descripcion != null) {
          str += g.descripcion;
        }
      }
    }

    return str;
  }
  //acumulaciones de valores para imprimir totales de algun tipo
  getValorGarantias(caso: Caso) {
    var v = 0;
    for (var g of caso.garantias) {
      v += g.valorEstimado;
    }
    //console.log(caso.idUsuario)
    return v;
  }

  //acumulaciones de valores para imprimir totales de algun tipo
  //Aqui se haran los calculos de honorarios, tambien se hara el calculo del honorario por pandemia en caso de que haya
  getAcumuladoHonorarios(
    fecha,
    monto,
    fechaIniPandemia,
    montoPandemia,
    fechaFinPandemia
  ) {
    let prepandemiaINT: any;
    let pandemiaINT: any;
    let postpandemiaINT: any;

    //Primero verifica que el caso no tenga fecha de inicio de pandemia
    if (fechaIniPandemia != null && montoPandemia != null) {
      this.honorarioPrePandemia = 0;
      this.honorarioPandemia = 0;
      this.honorarioPostPandemia = 0;
      //-- Verifica que la fecha en que inicio el caso sea menor a la fecha del inicio de la pandemia
      if (new Date(fecha) < new Date(fechaIniPandemia)) {
        //--Aqui verifica si la fecha actual es menor a la fecha del inicio de la pandemia
        //-- Seria raro que entre en esta condicion ya que la pandemia inicio en el 2020.
        if (this.actualDate < new Date(fechaIniPandemia)) {
          let m = Math.abs(+new Date(fecha) - +this.actualDate);
          m = m / 1000 / 60 / 60 / 24 / 30;
          this.honorarioPrePandemia = (Number.parseInt(m.toString()) * monto).toFixed(0);
        }
        //--Si la fecha actual ya es mayor a la fecha de inicio de la pandemia, entonces ya hara el calculo para obtener
        // el honorario pre-pandemia
        else if (this.actualDate >= new Date(fechaIniPandemia)) {
          let m = Math.abs(+new Date(fecha) - +new Date(fechaIniPandemia));
          m = m / 1000 / 60 / 60 / 24 / 30;
          this.honorarioPrePandemia = (Number.parseInt(m.toString()) * monto).toFixed(0);
        }
      }

      //-- Si la fecha actual es mayor o igual a la fecha de inicio de la pandemia, hara el calculo
      // desde la fecha del inicio de la pandemia con la fecha actual.
      if (this.actualDate >= new Date(fechaIniPandemia)) {
        let m = Math.abs(+new Date(fechaIniPandemia) - +this.actualDate);
        m = m / 1000 / 60 / 60 / 24 / 30;
        this.honorarioPandemia = (
          Number.parseInt(m.toString()) * montoPandemia
        ).toFixed(0);
      }

      //---- HASTA ESTE PUNTO SE DA EL HONORARIO PRE PANDEMIA Y HONORARIO EN PANDEMIA ----//

      //-- Aqui verifica que el caso tenga fecha para finalizar el honorario por pandemia

      if (fechaFinPandemia != null) {
        //-- Aqui verifica que la fecha actual este dentro del rango entre la fecha de inicio de pandemia
        //  y la fecha de finalizacion de la pandemia, si la fecha actua estuviera en ese rango
        // entonces el honorario por pandemia seguiria vigente.
        if (
          new Date(fechaFinPandemia) > this.actualDate &&
          new Date(fechaIniPandemia) <= this.actualDate
        ) {
          this.honorarioPandemia = this.honorarioPandemia;
          this.honorarioPostPandemia = 0;
        }

        // -- Aqui en caso de que la fecha actual ya este fuera del rango de la fecha de inicio y fin de pandemia
        //   se hara el calculo para el honorario por pandemia total y ademas el calculo
        //   del honorario post pandemia para el caso
        else if (
          new Date(fechaFinPandemia) <= this.actualDate &&
          new Date(fechaIniPandemia) < this.actualDate
        ) {
          let m = Math.abs(+new Date(fechaIniPandemia) - +new Date(fechaFinPandemia)
          );
          let m2 = Math.abs(+new Date(fechaFinPandemia) - +this.actualDate);
          m = m / 1000 / 60 / 60 / 24 / 30;
          this.honorarioPandemia = (Number.parseInt(m.toString()) * montoPandemia).toFixed(0);

          m2 = m2 / 1000 / 60 / 60 / 24 / 30;
          this.honorarioPostPandemia = (Number.parseInt(m2.toString()) * monto).toFixed(0);
        }
      } else {
        this.honorarioPostPandemia = 0;
      }

      //-- Al final capta las sumatorias de cada tipo de honorario
      prepandemiaINT = parseFloat(this.honorarioPrePandemia);
      pandemiaINT = parseFloat(this.honorarioPandemia);
      postpandemiaINT = parseFloat(this.honorarioPostPandemia);

      //-- Por ultimo suma las sumatorias de los 3 honorarios.
      return prepandemiaINT + pandemiaINT + postpandemiaINT;
    }

    //-- Si el caso no cuenta con honorario por pandemia,
    //   simplemente hara el calculo normal del honorario dado inicialmente
    else {
      var m = Math.abs(+new Date(fecha) - +this.actualDate);
      m = m / 1000 / 60 / 60 / 24 / 30;
      return (Number.parseInt(m.toString()) * monto).toFixed(0);
    }
  }

  //acumulacion de los honorarios perito y perito en rebeldia
  getAcumuladoPeritoYRebeldia(fecha, monto) {
    var m = Math.abs(+new Date(fecha) - +this.actualDate);
    m = m / 1000 / 60 / 60 / 24 / 30;
    return (Number.parseInt(m.toString()) * monto).toFixed(0);
  }
  //acumulaciones de valores para imprimir totales de algun tipo

  //FUNCION PARA MOSTRAR LA FECHA CORRECTA DE ASIGNACION DEL CASO
  getFechaAsign(caso: Caso) {
    let spliteando;
    let fechaAsignSplit;
    let fechaAsignReal;
    let diaSplit;
    if (caso.fechaAsignacion == null) {
      fechaAsignReal = "";
      return fechaAsignReal;
    } else {
      spliteando = caso.fechaAsignacion;
      fechaAsignSplit = spliteando.split("-");
      diaSplit = fechaAsignSplit[2].split("");
      fechaAsignReal = diaSplit[0] + diaSplit[1] + "/" + fechaAsignSplit[1] + "/" + fechaAsignSplit[0];
      return fechaAsignReal;
    }
  }

  //FUNCION PARA MOSTRAR LA FECHA CORRECTA DE RECUPERACION DEL CASO
  getFechaRecup(caso: Caso) {
    let spliteando;
    let fechaAsignSplit;
    let fechaRecupReal;
    let diaSplit;
    if (caso.fechaRecuperacion == null) {
      fechaRecupReal = "";
      return fechaRecupReal;
    } else {
      spliteando = caso.fechaRecuperacion;
      fechaAsignSplit = spliteando.split("-");
      diaSplit = fechaAsignSplit[2].split("");
      fechaRecupReal = diaSplit[0] + diaSplit[1] + "/" + fechaAsignSplit[1] + "/" + fechaAsignSplit[0];
      return fechaRecupReal;
    }
  }

  getGastoErogado(caso: Caso) {
    let gastoJuicio = this.getTotalGastos(
      caso.fechaAsignacion,
      caso.gastos,
      caso.gastosAsignacion
    );
    let honorario = Number.parseInt(
      this.getAcumuladoHonorarios(
        caso.fechaAsignacion,
        caso.honorario,
        caso.fechaDescPandemia,
        caso.honorarioPandemia,
        caso.fechaFinPandemia
      )
    );
    let honorariosPeri = Number.parseInt(
      this.getAcumuladoPeritoYRebeldia(
        caso.fechaAsignacion,
        caso.honorariosPeri
      )
    );
    let honorariosRebel = Number.parseInt(
      this.getAcumuladoPeritoYRebeldia(
        caso.fechaAsignacion,
        caso.honorariosRebel
      )
    );
    return gastoJuicio + honorario + honorariosPeri + honorariosRebel;
  }

  getRecuAccedde(caso: Caso) {
    let recuperadoAccedde;
    let gastoJuicio;
    let honorario;
    let honorariosPeri;
    let honorariosRebel;
    if (caso.recuAccedde <= 0 || caso.recuAccedde == null) {
      recuperadoAccedde = caso.recuAccedde;
      return recuperadoAccedde;
    } else if (caso.recuAccedde > 0) {
      recuperadoAccedde = caso.recuAccedde;
      gastoJuicio = this.getTotalGastos(
        caso.fechaAsignacion,
        caso.gastos,
        caso.gastosAsignacion
      );
      honorario = Number.parseInt(
        this.getAcumuladoHonorarios(
          caso.fechaAsignacion,
          caso.honorario,
          caso.fechaDescPandemia,
          caso.honorarioPandemia,
          caso.fechaFinPandemia
        )
      );
      honorariosPeri = Number.parseInt(
        this.getAcumuladoPeritoYRebeldia(
          caso.fechaAsignacion,
          caso.honorariosPeri
        )
      );
      honorariosRebel = Number.parseInt(
        this.getAcumuladoPeritoYRebeldia(
          caso.fechaAsignacion,
          caso.honorariosRebel
        )
      );
      return (recuperadoAccedde - gastoJuicio - honorario - honorariosPeri - honorariosRebel);
    }
  }
  //acumulaciones de valores para imprimir totales de algun tipo
  getAcumuladoGastos(gastos: Gasto[]) {
    if (gastos) {
      if (gastos.length > 0) {
        var suma = 0;
        for (var g of gastos) {
          suma += g.monto;
        }
        return suma;
      } else {
        return 0;
      }
    }
  }
  //acumulaciones de valores para imprimir totales de algun tipo
  getAcumuladoGastosAsignacion(gastos: GastoAsignacion[]) {
    if (gastos) {
      if (gastos.length > 0) {
        var str = 0;
        for (var g of gastos) {
          str += g.monto;
        }
        return str;
      } else {
        return 0;
      }
    }
  }

  test: Caso[];
  //llama al submenu que se abre cuando se presiona el boton de la izquierda
  showContext(cm: ContextMenu, event: MouseEvent, caso: any, usuario: any) {
    cm.show(event);
    event.stopPropagation();
    this.casoSeleccionado = caso;
  }
  showContext2(cm: ContextMenu, event: MouseEvent, caso: any) {
    cm.show(event);
    event.stopPropagation();
    this.casoSeleccionado = caso;
    //console.log(this.casoSeleccionado.idCaso)
  }
  //abre otra pagina y manda el id
  editarCaso(evt) {
    this.router.navigateByUrl("editar-caso/" + this.casoSeleccionado.idCaso);
  }
  //abre el modal para ubir archivo con los datos necesarios
  openSubirArchivoModal(caso, TIPO_ARCHIVO) {
    const ref = this.dialogService.open(SubirArchivoModalComponent, {
      data: {
        caso: caso,
        tipoArchivo: TIPO_ARCHIVO,
      },
      width: "40%",
      showHeader: false,
      dismissableMask: true,
      baseZIndex: 1030,
    });
  }
  //abre el modal para ver archivo con los datos necesarios
  openVerArchivoModal(caso, TIPO_ARCHIVO) {
    //console.log(this.casoSeleccionado)
    const ref = this.dialogService.open(VerArchivosModalComponent, {
      data: {
        caso: caso,
        tipoArchivo: TIPO_ARCHIVO,
      },
      width: "90%",
      height: "90%",
      showHeader: true,
      dismissableMask: true,
      baseZIndex: 1030,
      style: { position: "fixed", overflow: "auto", "max-height": "91%" },
    });
  }

  //Metodo que abre el modal para ver informe y a la ves envia el idCaso que corresponde el cual es necesario
  //para poder comparar el idCaso de la tabla caso con el idcaso de la tabla informeMens
  buscarInforme(event: MouseEvent, caso: any) {
    this.casoSeleccionado = caso;
    const ref = this.dialogService.open(VerInformeComponent, {
      data: {
        caso: this.casoSeleccionado,
      },

      width: "90%",
      height: "90%",
      showHeader: true,
      dismissableMask: true,
      baseZIndex: 1030,
      style: { position: "fixed", overflow: "auto", "max-height": "91%" },
    });
  }

  //abre modal del historial
  historial(evt) {
    /*
    const ref = this.dialogService.open(HistorialModalComponent, {
      data:
      {
        caso: this.casoSeleccionado,
      },
      width: '60%',
      showHeader: false,
      dismissableMask: true,
      baseZIndex: 1030
    });
    ref.onClose.subscribe(() => {
      this.ngOnInit()
    })
    */
    
    const ref = this.dialogService.open(ActualizacionesModalComponent, {
      data: {
        caso: this.casoSeleccionado,
        tipoArchivo: 0,
      },
      width: "90%",
      height: "90%",
      showHeader: true,
      dismissableMask: true,
      baseZIndex: 1030,
      style: { position: "fixed", overflow: "auto", "max-height": "91%" },
    });
  }

  createNotas(evt) {
    const ref = this.dialogService.open(CrateNoteComponent, {
      data: {
        caso: this.casoSeleccionado,
        usuario: this.usuarios,
      },
      header: "Crear Nota",
      width: "60%",
      height: "80%",
      showHeader: true,
      dismissableMask: true,
      baseZIndex: 1030,
      style: { position: "fixed", overflow: "auto", "max-height": "91%" },
    });
  }

  historialNotas(evt) {
    const ref = this.dialogService.open(NoteHistoryComponent, {
      data: {
        caso: this.casoSeleccionado,
      },
      header: "Historico de Notas",
      width: "68%",
      height: "70%",
      showHeader: true,
      dismissableMask: true,
      baseZIndex: 1030,
      style: { position: "fixed", overflow: "auto", "max-height": "91%" },
    });
  }

  openVerArchivoModalComentarios(caso, TIPO_ARCHIVO) {
    const ref = this.dialogService.open(ActualizacionesModalComponent, {
      data:
      {
        caso: caso,
        tipoArchivo: TIPO_ARCHIVO
      },
      width: '90%',
      height: '90%',
      showHeader: true,
      dismissableMask: true,
      baseZIndex: 1030,
      style: { "position": "fixed", "overflow": "auto", "max-height": "91%" }
    });
  }

  editarCaso2(evt) {
    const ref = this.dialogService.open(Editar2ModalComponent, {
      data:
      {
        caso: this.casoSeleccionado,
      },
      width: '85%',
      height: '100%',
      showHeader: true,
      dismissableMask: true,
      baseZIndex: 1030,
      style: { "position": "fixed", "overflow": "auto", "max-height": "91%" }
    });
    ref.onClose.subscribe(() => {
      this.ngOnInit()
    })
  }

  //abre modal
  cambiarEstado(evt) {
    const ref = this.dialogService.open(CambiarEstadoComponent, {
      data: {
        caso: this.casoSeleccionado,
      },
      width: "40%",
      showHeader: false,
      dismissableMask: true,
      baseZIndex: 1030,
    });
    ref.onClose.subscribe(() => {
      this.ngOnInit();
    });
  }
  //calculo vario
  diasHastaCaducidad(d1) {
    return moment().diff(d1, "days");
  }

  //llama al servidor para descargar un archivo a travez de pura llamada http
  //no usar el sdk para descargar, no funciona correctamente.
  download(f, u) {
    this.downloadFile(f, u).subscribe((response) => {
      let blob: any = new Blob([response], {
        type: "text/json; charset=utf-8",
      });
      const url = window.URL.createObjectURL(blob);
      this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
        var r: Log = new Log();
        r.createdAt = new Date();
        r.updatedAt = new Date();
        r.tipo = 3;
        r.idUsuario = us.idUsuario;
        r.contenido = JSON.stringify(f);
        this.logapi.create(r).subscribe((d) => {});
      });
      fileSaver.saveAs(blob, f);
    }),
      (error) => console.log("Error downloading the file")
  }
  //llama al servidor para descargar un archivo a travez de pura llamada http
  //no usar el sdk para descrgar, no funciona correctamente.
  downloadFile(f, u): Observable<Blob> {
    var i = u;
    var url = "https://api1.accedde.mx/api/containers/" + i + "/download/" + f;
    //var url = 'http://localhost:3000/api/containers/' + i + '/download/' + f
    return this.http.get(url, { responseType: "blob" });
  }

  //exportando a excel
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    //console.log(excelBuffer)
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  //agarra el stream de datos ya convertids con la libreria y la manda al usuario
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fileSaver.saveAs(data, fileName + "_abogados" + EXCEL_EXTENSION);
  }

  //concatenacion de datos
  concatEmbargos(embargos: Embargo[]) {
    var str = "";
    if (embargos.length > 0) {
      for (var e of embargos) {
        if (e.contenido != null) {
          str += e.contenido + " | ";
        }
      }
    }

    return str;
  }
  //concatenacion de datos
  getInscripcion(embargos: Embargo[]) {
    var str = "";
    for (var e of embargos) {
      if (e.inscripcionEmbargo) {
        str += "Si |";
      } else {
        str += "No |";
      }
    }
    return str;
  }
  //cierra sesion con la api y despues escribe en el log esto mismo
  logout() {
    this.usuarioapi.logout().subscribe((s) => {
      this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
        var r: Log = new Log();
        r.createdAt = new Date();
        r.updatedAt = new Date();
        r.idUsuario = us.idUsuario;
        r.tipo = 1;
        r.contenido = " ";
        this.logapi.create(r).subscribe((d) => {});
        this.router.navigateByUrl("login", {
          queryParams: { caso: this.casoSeleccionado },
        });
      });
    });
  }
  //declaracion de las columnas de la tabla y demas datos estaticos
  public files: Set<File> = new Set();
  filename = "";
  cols = [
    { field: "id", header: "#" }, //ESTA EN ABOGADOS
    { field: "ff", header: "" }, //ESTA EN ABOGADOS
    { field: "idUsuario", header: "Abogado" },
    { field: "diasCaducidad", header: "Dias Ultima Act." },
    { field: "tramo", header: "Tramo" }, //ESTA EN ABOGADOS
    { field: "numeroControl", header: "# de Control" }, //ESTA EN ABOGADOS
    { field: "acreditado", header: "Acreditado" }, // ESTA EN ABOGADOS
    { field: "Cast_fira", header: "Castigo FIRA" }, //NUEVA COLUMNA
    { field: "Cast_accedde", header: "Castigo Accedde" }, //NUEVA COLUMNA
    { field: "Et_procesal", header: "Etapa Procesal" }, //NUEVA COLUMNA
    { field: "estatusM", header: "Estatus Mercantil" }, //ESTA EN ABOGADOS
    { field: "impugnacionM", header: "Impugnacion Mercantil" }, //ESTA EN ABOGADOS
    { field: "estatusC", header: "Estatus Civil" }, //ESTA EN ABOGADOS
    { field: "impugnacionC", header: "Impugnacion Civil" }, //ESTA EN ABOGADOS
    { field: "juzgadoM", header: "Juzgado Mercantil" }, //ESTA EN ABOGADOS
    { field: "expedienteM", header: "Expediente Mercantil" }, //ESTA EN ABOGADOS
    { field: "juzgadoC", header: "Juzgado Civil" }, // ESTA EN ABOGADOS
    { field: "expedienteC", header: "Expediente Civil" }, //ESTA EN ABOGADOS
    { field: "expToca", header: "Expediente TOCA" }, //ESTA EN ABOGADOS
    { field: "saldoCont", header: "Saldo Contable" }, //NUEVA COLUMNA
    { field: "capital", header: "Capital" }, //ESTA EN ABOGADOS
    { field: "intOrdinarios", header: "Intereses Ordinarios" }, //ESTA EN ABOGADOS
    { field: "intMoratorios", header: "Intereses Moratorios" }, //ESTA EN ABOGADOS
    { field: "total", header: "Total" }, //ESTA EN ABOGADOS
    { field: "cap_efsy", header: "Capital EFSY" }, //NUEVA COLUMNA
    { field: "intOrdinarios_efsy", header: "Intereses Ordinarios EFSY" }, // NUEVA COLUMNA
    { field: "intMoratorios_efsy", header: "Intereses Moratorios EFSY" }, // NUEVA COLUMNA
    { field: "total_efsy", header: "Total EFSY" }, // NUEVA COLUMNA
    { field: "sala", header: "Sala" }, //ESTA EN ABOGADOS
    { field: "reservas", header: "Reservas" }, //NUEVA COLUMNA
    { field: "montoCast", header: "Monto Castigo/Por Castigar" }, //NUEVA COLUMNA
    { field: "conceptoCast", header: "Concepto Castigo" }, //NUEVA COLUMNA
    { field: "bienesEmbargados", header: "Bienes Embargados" }, //ESTA EN ABOGADOS
    { field: "inscripcionEmbargo", header: "Inscripcion de Embargo" }, //ESTA EN ABOGADOS
    { field: "informeMens", header: "Informe Mensual" }, //NUEVA COLUMNA
    { field: "comentarios", header: "Comentarios" }, //ESTA EN ABOGADOS
    { field: "garantia", header: "Garantia" }, //ESTA EN ABOGADOS
    { field: "valorEstimado", header: "Valor Estimado" }, //ESTA EN ABOGADOS
    { field: "fechaRecuperacion", header: "Fecha de Recuperacion" }, //ESTA EN ABOGADOS
    { field: "fechaAsignacion", header: "Fecha de Asignacion" }, //ESTA EN ABOGADOS
    { field: "grupo", header: "Grupo" }, //ESTA EN ABOGADOS
    { field: "gastosEfectuados", header: "Gastos Efectuados en el Juicio (gastos aprobados + gastos asignados)"}, //NUEVA COLUMNA
    { field: "honorarios", header: "Honorarios" }, //ESTA EN ABOGADOS
    /*SE DESCARTAN DE MOMENTO ESOS 3 GASTOS
    { field: 'gastosAsignacion', header: 'Gastos de Asignacion' },
    { field: 'gastos', header: 'Gastos Abogado' },
    { field: 'gastosTotal', header: 'Total de Gastos' },*/
    { field: "honorariosPeri", header: "Honorarios Perito" }, //NUEVA COLUMNA
    { field: "honorariosRebel", header: "Honorarios Perito En Rebeldia" }, //NUEVA COLUMNA
    { field: "boletinM", header: "Estatus Boletin (Mercantil)" },
    { field: "boletinC", header: "Estatus Boletin (Civil)" },
    { field: "tratamiento", header: "Tratamiento" },
    { field: "gErogado", header: "Gasto Erogado (gastos en juicio + honorario + honorarios perito + honorario perito en rebeldia)"}, // NUEVA COLUMNA
    { field: "gPorErogar", header: "Gasto Por Erogar" }, // NUEVA COLUMNA
    { field: "montoRecup", header: "Monto Estimado De Recuperacion" }, //NUEVA COLUMNA
    { field: "recuperado", header: "Recuperado En Juicio" }, //COLUMNA CREADA CON ANTERIORIDAD
    { field: "recuAccedde", header: "Recuperado Accedde" }, //COLUMNA CREADA CON ANTERIORIDAD
    { field: "base", header: "Base" },
    { field: "Avance Procesal", header: "Avance Procesal" }
  ];
  tramos: any[] = [
    { label: "1 Tramo", value: "0" },
    { label: "2 Tramo", value: "1" },
    { label: "3 Tramo", value: "2" },
    { label: "FEGA/FONAGA", value: "3" },
    { label: "Propia Good Bank", value: "4" },
    { label: "Propia Bad Bank", value: "5" },
    { label: "GRUPAL", value: "6" }
  ];
  dropdowntramos: any[] = [
    { label: "1 Tramo", value: 0 },
    { label: "2 Tramo", value: 1 },
    { label: "3 Tramo", value: 2 },
    { label: "Fega/FONAGA", value: 3 },
    { label: "Propia Good Bank", value: 4 },
    { label: "Propia Bad Bank", value: 5 },
    { label: "GRUPAL", value: 6 }
  ];
  dropdownacreditado: any[] = [];

  //OPCIONES DEL MENU DESPLEGABLE PARA ETAPA PROCESAL
  dropdownEt_procesal: any[] = [
    { label: "Demanda", value: 0 },
    { label: "Admision de demanda", value: 1 },
    { label: "Diligencia de requerimiento de pago y embargo", value: 2 },
    { label: "Contestacion de demanda", value: 3 },
    { label: "Desahogo de vista de contestacion de demanda", value: 4 },
    { label: "Pruebas", value: 5 },
    { label: "Desahogo de pruebas", value: 6 },
    { label: "Alegatos", value: 7 },
    { label: "Sentencia", value: 8 },
    { label: "Ejecucion de sentencia", value: 9 },
    { label: "Procedimiento de remate", value: 10 },
    { label: "Adjudicacion", value: 11 }
  ];

  dropdownjuzgadoM: any[] = [
    { label: "J°1 M.", value: 26 },
    { label: "J°2 M.", value: 27 },
    { label: "J°3 M.", value: 28 },
    { label: "J°4 M.", value: 29 },
    { label: "J°5 M.", value: 30 },
    { label: "J°6 M.", value: 31 },
    { label: "J°7 M.", value: 32 },
    { label: "J°8 M.", value: 33 },
    { label: "J°9 M.", value: 34 },
    { label: "J°10 M.", value: 35 },
    { label: "J°1 O.M.", value: 36 },
    { label: "J°2 O.M.", value: 37 },
    { label: "J°3 O.M.", value: 38 },
    { label: "J°4 O.M.", value: 39 },
    { label: "J°5 O.M.", value: 40 },
    { label: "J°6 O.M.", value: 41 },
    { label: "J°7 O.M.", value: 42 },
    { label: "J°8 O.M.", value: 43 },
    { label: "J°9 O.M.", value: 44 },
    { label: "J°10 O.M.", value: 45 }
  ];
  dropdownjuzgadoC: any[] = [
    { label: "J°1 C.", value: 0 },
    { label: "J°2 C.", value: 1 },
    { label: "J°3 C.", value: 2 },
    { label: "J°4 C.", value: 3 },
    { label: "J°5 C.", value: 4 },
    { label: "J°6 C.", value: 5 },
    { label: "J°7 C.", value: 6 },
    { label: "J°8 C.", value: 7 },
    { label: "J°9 C.", value: 8 },
    { label: "J°10 C.", value: 9 },
    { label: "J°11 C.", value: 10 },
    { label: "J°12 C.", value: 11 },
    { label: "J°13 C.", value: 12 },
    { label: "J°14 C.", value: 13 },
    { label: "J°1 O.C.", value: 14 },
    { label: "J°2 O.C.", value: 15 },
    { label: "J°3 O.C.", value: 16 },
    { label: "J°4 O.C.", value: 17 },
    { label: "J°5 O.C.", value: 18 },
    { label: "J°6 O.C.", value: 19 },
    { label: "J°7 O.C.", value: 20 },
    { label: "J°8 O.C.", value: 21 },
    { label: "J°9 O.C.", value: 22 },
    { label: "J°10 O.C.", value: 23 },
    { label: "J°11 O.C.", value: 24 },
    { label: "J°12 O.C.", value: 25 },
    { label: "J°13 O.C.", value: 26 },
    { label: "J°14 O.C.", value: 27 }
  ];
  dropdownimpugnacionM: any[] = [
    { label: "Incidente", value: 0 },
    { label: "Recurso de revocacion", value: 1 },
    { label: "Recurso de apelacion", value: 2 },
    { label: "Amparo", value: 3 },
    { label: "Amparo en revision", value: 4 }
  ];
  dropdowngrupo: any[] = [];
  dropdownestatus: any[] = [
    { label: "Presentacion de la Demanda", value: 0 },
    { label: "Admision de la Demanda", value: 1 },
    { label: "Diligencia de Requerimiento emplazamiento y embargo", value: 2 },
    { label: "Etapa Probatoria", value: 3 },
    { label: "Alegatos", value: 4 },
    { label: "Sentencia de la Instancia", value: 5 },
    { label: "Ejecucion de la Sentencia", value: 6 },
    { label: "Procedimiento de Remate", value: 7 }
  ];
  dropdownestatus2: any[] = [
    { label: "Contestacion de la Demanda", value: 0 },
    { label: "Reconvencion", value: 1 },
    { label: "Etapa Probatoria", value: 2 },
    { label: "Alegatos", value: 3 },
    { label: "Sentencia de la Instancia", value: 4 }
  ];

  //se agrega el dropdown de la columna impugnacion civil
  dropdownimpugnacionC: any[] = [
    { label: "Apelacion", value: 0 },
    { label: "Revocacion", value: 1 },
    { label: "Queja", value: 2 },
    { label: "Amparo Directo", value: 3 },
    { label: "Amparo Indirecto", value: 4 },
    { label: "Suspendido", value: 5 },
    { label: "Caducado", value: 6 }
  ];

  informeFinal: any;
  selectedInforme: InformeMens;
  ArrayInformes: InformeMens[];
  contenidoInformes: any[] = [];
  contenidoIds: any[] = [];
  arreglito: any[] = [];
  selectedCaso: Caso;

  casos: Caso[];
  usuarios: Usuario[];
  abogados: any[] = [];
  items: MenuItem[];
  casoSeleccionado;
  popupClosed = false;
}

//adaptador de los datos para organizarlos en el excel
class tableCaso {
  id: number;
  abogado: String;
  dias_ultima_actualizacion: String;
  tramo: String;
  numero_de_Control: String;
  acreditado: String;
  Castigo_fira: String; //NUEVA COLUMNA
  Castigo_accedde: String; //NUEVA COLUMNA
  Etapa_procesal: String; //NUEVA COLUMNA
  Estatus_mercantil: String;
  Impugnacion_mercantil: String;
  Juzgado_mercantil: String;
  Expediente_mercantil: String;
  Estatus_civil: String;
  Impugnacion_civil: String;
  Juzgado_civil: String;
  Expediente_civil: String;
  Expediente_TOCA: String;
  Saldo_contable: number; //NUEVA COLUMNA
  Capital: number;
  Intereses_ordinarios: number;
  Intereses_moratorios: number;
  total: number;
  Capital_efsy: number; //NUEVA COLUMNA
  Intereses_ordinarios_efsy: number; //NUEVA COLUMNA
  Intereses_moratorios_efsy: number; //NUEVA COLUMNA
  total_efsy: number; //NUEVA COLUMNA
  Sala: String;
  reservas: String; //NUEVA COLUMNA
  Monto_castigo_por_castigar: number; // NUEVA COLUMNA
  Concepto_castigo: String; //NUEVA COLUMNA
  Bienes_embargados: String;
  inscripcionEmbargo: String;
  comentarios: String;
  garantia: String;
  valorEstimado: number;
  fechaRecuperacion: String;
  fechaAsignacion: String;
  grupo: String;
  Gastos_efectuados_en_el_juicio: number; //NUEVA COLUMNA
  Estatus_boletin_mercantil: String;
  Estatus_boletin_civil: String;
  honorarios: number;
  honorarios_perito: number; //NUEVA COLUMNA
  honorarios_perito_en_rebeldia: number; //NUEVA COLUMNA
  //gastosAsignacion: number;
  //gastosAbogado: number;
  //totalGastos: number;
  tratamiento: String;
  Gasto_erogado: number; //NUEVA COLUMNA
  Gasto_por_erogar: number; //NUEVA COLUMNA
  Monto_estimado_de_recuperacion: number; //NUEVA COLUMNA
  Recuperado_en_juicio: number; //NUEVA COLUMNA
  Recuperado_Accedde: number; //NUEVA COLUMNA
}
