import { Component, OnInit } from '@angular/core';
import { Caso, CasoApi, UsuarioApi, Usuario, Log, LogApi } from '../shared/sdk';

@Component({
  selector: 'app-asignar-caso',
  templateUrl: './asignar-caso.component.html',
  styleUrls: ['./asignar-caso.component.scss']
})
export class AsignarCasoComponent implements OnInit {

  text: string;
  text2: string;
  results: Caso[];
  results2: Usuario[];
  selectedCaso: Caso
  selectedUsuario: Usuario
  msgs: any[] = [];

  constructor(public logapi:LogApi, public casoapi: CasoApi, public usuarioapi: UsuarioApi) {
  
  }

  ngOnInit() {
    alert("ATENCION!: Antes de asignar un caso a otro abogado, primero consulte el total de honorarios del abogado que transferira el caso")
  }
//busca con el criterio de acreditado y despliega la lista de los resultados en el dropdown
  search(event) {
    var t: String = "%" + this.text + "%"
    if (this.text.length > 1) {
      this.casoapi.find({ where: { acreditado: { like: t } } }).subscribe((c: Caso[]) => {
        this.results = c
      })
    }
  }
//lo mismo pero con username y buscando en los abogados
  search2(event) {
    var t: String = "%" + this.text2 + "%"
    if (this.text2.length > 1) {
      this.usuarioapi.find({ where: { username: { like: t } } }).subscribe((c: Usuario[]) => {
        this.results2 = c
      })
    }
  }

  isSelected(evt) {
    this.selectedCaso = evt
  }

  isSelected2(evt) {
    this.selectedUsuario = evt
  }
//actualiza el id del abogado asignado al caso y genera log
  asignarCaso() {
    this.selectedCaso.usuario = this.selectedUsuario
    this.selectedCaso.fechaAsignacion = new Date();
    this.selectedCaso.fechaDescPandemia = null
    this.selectedCaso.honorarioPandemia = null
    this.selectedCaso.fechaFinPandemia  = null
    this.casoapi.upsert(this.selectedCaso).subscribe((d) => {
      this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
        var r:Log = new Log()
        r.createdAt = new Date()
        r.updatedAt = new Date()
        r.idUsuario = us.idUsuario
        r.tipo = 6
        r.contenido =JSON.stringify(this.selectedCaso)
              this.logapi.create(r).subscribe((d)=>
              {
              })
        this.msgs.push({severity:'success', summary:'Exito', detail:'Caso Asignado Exitosamente'});
        //console.log(d)
      })
    }, (error) => {
      this.msgs.push({severity:'error', summary:'Error', detail:'Error al Asignar Caso'});
    })
  }

}
