import {
    Caso
  } from '../index';

  declare var Object: any;
export interface InformeInterface {
    "idCaso"?: number;
    "idInforme"?: number;
    "Contenido"?: any;
    "Fecha"?: any;
    "createdAt"?: Date;
    "updatedAt"?: Date;
    caso?: Caso;
}

export class InformeMens implements InformeInterface{
    "idCaso": number;
    "idInforme": number;
    "Contenido": any;
    "Fecha": any;
    "createdAt": Date;
    "updatedAt": Date;
    caso: Caso;
    constructor(data?: InformeInterface) {
        Object.assign(this, data);
      }

 /**
   * The name of the model represented by this $resource,
   * i.e. `InformeMens`.
   */
public static getModelName() {
    return "InformeMens";
  }
 /**  
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Garantia for dynamic purposes.
  **/

  public static factory(data: InformeInterface): InformeMens{
    return new InformeMens(data);
  }
/**
* @method getModelDefinition
* @author Julien Ledun
* @license MIT
* This method returns an object that represents some of the model
* definitions.
**/

public static getModelDefinition() {
    return {
        name: "InformeMens",
        plural: "InformesMens",
        path: 'InformesMens',
        idName: 'idInforme',
        properties:{
            "idInforme": {
                name: 'idInforme',
                type: 'number'
                },
            "Contenido":{
                name: 'Contenido',
                type: 'any'
                },

            "Fecha":{
                name: 'Fecha',
                type: 'any'
                
                },
            "idCaso": {
                name: 'idCaso',
                type: 'number'
                },

            "createdAt": {
                name: 'createdAt',
                type: 'Date'
              },
              "updatedAt": {
                name: 'updatedAt',
                type: 'Date'
              },
        },
    
        relations: {
            caso:{
                name: 'caso',
                type: 'Caso',
                model: 'Caso',
                relationType: 'belongsTo',
                        keyFrom: 'idCaso',
                keyTo: 'idCaso'
            },
        }
      }
    }
}