/* tslint:disable */
import {
  Caso
} from '../index';

declare var Object: any;
export interface GastoEliminadoInterface {
  "idGastoEliminado"?: number;
  "aplicarMediante"?: number;
  "concepto"?: string;
  "monto"?: number;
  "comentarioDesaprobacion"?: string;
  "idCaso"?: number;
  "createdAt": Date;
  "updatedAt": Date;
  caso?: Caso;
}

export class GastoEliminado implements GastoEliminadoInterface {
  "idGastoEliminado": number;
  "aplicarMediante": number;
  "concepto": string;
  "monto": number;
  "comentarioDesaprobacion": string;
  "idCaso": number;
  "createdAt": Date;
  "updatedAt": Date;
  caso: Caso;
  constructor(data?: GastoEliminadoInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `GastoEliminado`.
   */
  public static getModelName() {
    return "GastoEliminado";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of GastoEliminado for dynamic purposes.
  **/
  public static factory(data: GastoEliminadoInterface): GastoEliminado{
    return new GastoEliminado(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'GastoEliminado',
      plural: 'GastosEliminados',
      path: 'GastosEliminados',
      idName: 'idGastoEliminado',
      properties: {
        "idGastoEliminado": {
          name: 'idGastoEliminado',
          type: 'number'
        },
        "aplicarMediante": {
          name: 'aplicarMediante',
          type: 'number'
        },
        "concepto": {
          name: 'concepto',
          type: 'string'
        },
        "monto": {
          name: 'monto',
          type: 'number'
        },
        "comentarioDesaprobacion": {
          name: 'comentarioDesaprobacion',
          type: 'string'
        },
        "idCaso": {
          name: 'idCaso',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        caso: {
          name: 'caso',
          type: 'Caso',
          model: 'Caso',
          relationType: 'belongsTo',
                  keyFrom: 'idCaso',
          keyTo: 'idCaso'
        },
      }
    }
  }
}
