/* tslint:disable */

declare var Object: any;
export interface NotasInterface {
  "idNota"?: number;
  "role": String;
  "asunto": String;
  "nota": String;
  "idCaso": number;
  "createdAt": Date;
  "updatedAt": Date;
}

export class Notas implements NotasInterface {
    "idNota": number;
    "role": String;
    "asunto": String;
    "nota": String;
    "idCaso": number;
    "createdAt": Date;
    "updatedAt": Date;
  constructor(data?: NotasInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Mail`.
   */
  public static getModelName() {
    return "Note";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Mail for dynamic purposes.
  **/
  public static factory(data: NotasInterface): Notas{
    return new Notas(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Nota',
      plural: 'Notas',
      path: 'Notas',
      idName: 'idNotas',
        properties: {
            "idNota": {
                name: "idNota",
                type: "Number",
                precision: 10,
                scale: 0,
                id: true,
                required: false,
                generated: true
            },
            "role": {
                name: "role",
                type: "text"
            },
            "asunto": {
                name: "asunto",
                type: "text"
            },
            "nota": {
                name: "nota",
                type: "text"
            },
            idCaso:{
                name: "idCaso",
                type: "number"
            },
            "createdAt": {
                name: "createdAt",
                type: 'Date'
            },
            "updatedAt": {
                name: 'updatedAt',
                type: 'Date'
            }
        },
        validations: [],
        relations: {
            caso:{
              type: "belongsTo",
              model: "Caso",
              foreignKey: "idCaso"
            }
        }
    }
  }
}