import { Mensaje } from './../shared/sdk/models/Mensaje';
import { MensajeApi } from './../shared/sdk/services/custom/Mensaje';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-mensajes-modal',
  templateUrl: './mensajes-modal.component.html',
  styleUrls: ['./mensajes-modal.component.scss']
})


export class MensajesModalComponent implements OnInit {
  idRemitente;
  idDestinatario;
  mensajesList: Mensaje[] = []
  tempMessage: Mensaje;
  
  ADMIN_ID = 4; //id del admin, para no tomarla en cuenta en los resultados
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, public mensajesapi: MensajeApi) {
    //crea un mensaje con remitente y destinatario dados en la pantalla que llama al modal, donde haya el juego de ids
  }
    ngOnInit(){ // SE INICIA AQUI EL NG ON INIT PARA QUE LOS MENSAJES ENVIADOS SE VEAN REFLEJADOS AL INSTANTE
    this.idDestinatario = this.config.data.idDestinatario;
    this.idRemitente = this.ADMIN_ID
    //console.log(this.idRemitente);
    
    this.tempMessage = new Mensaje()
    this.mensajesapi.find({
      where: {
        or: [{
          and:
            [{ idRemitente: this.idRemitente }, { idDestinatario: this.idDestinatario }]
        }, {
          and:
            [{ idRemitente: this.idDestinatario }, { idDestinatario: this.idRemitente }]
        }]

      }, order: 'createdAt ASC'
    }).subscribe((mensajesList: Mensaje[]) => {
      this.mensajesList = mensajesList
      //console.log(mensajesList)
    }, (err) => {
      //console.log(err)
    });
}



//si es la misma persona la que manda el mensaje para poner estos mensajes a la derecha (whatsapp style)
  isSamePerson(m: Mensaje) {
    if (m.idRemitente == this.ADMIN_ID) {
      return true
    } else {
      return false;
    }
  }

//crea un nuevo mensaje y vuelve a cargar los mensajes de la base

  postMessage() {
    this.tempMessage.idRemitente = this.ADMIN_ID
    this.tempMessage.idDestinatario = this.idDestinatario  ////ADMIN ID
    this.mensajesapi.create(this.tempMessage).subscribe((r) => {
      //console.log(r);
      this.ngOnInit()
    }, (err) => {
      //console.log(err)
    });
  }
 


  
}
