/* tslint:disable */
import {
  Caso,
  Usuario
} from '../index';

declare var Object: any;
export interface MensajeInterface {
  "idMensaje"?: number;
  "contenido"?: any;
  "visto"?: boolean;
  "idCaso"?: number;
  "idRemitente"?: number;
  "idDestinatario"?: number;
  "createdAt": Date;
  "updatedAt": Date;
  caso?: Caso;
  remitente?: Usuario;
  destinatario?: Usuario;
}

export class Mensaje implements MensajeInterface {
  "idMensaje": number;
  "contenido": any;
  "visto": boolean;
  "idCaso": number;
  "idRemitente": number;
  "idDestinatario": number;
  "createdAt": Date;
  "updatedAt": Date;
  caso: Caso;
  remitente: Usuario;
  destinatario: Usuario;
  constructor(data?: MensajeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Mensaje`.
   */
  public static getModelName() {
    return "Mensaje";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Mensaje for dynamic purposes.
  **/
  public static factory(data: MensajeInterface): Mensaje{
    return new Mensaje(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Mensaje',
      plural: 'Mensajes',
      path: 'Mensajes',
      idName: 'idMensaje',
      properties: {
        "idMensaje": {
          name: 'idMensaje',
          type: 'number'
        },
        "contenido": {
          name: 'contenido',
          type: 'any'
        },
        "visto": {
          name: 'visto',
          type: 'boolean'
        },
        "idCaso": {
          name: 'idCaso',
          type: 'number'
        },
        "idRemitente": {
          name: 'idRemitente',
          type: 'number'
        },
        "idDestinatario": {
          name: 'idDestinatario',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        caso: {
          name: 'caso',
          type: 'Caso',
          model: 'Caso',
          relationType: 'belongsTo',
                  keyFrom: 'idCaso',
          keyTo: 'idCaso'
        },
        remitente: {
          name: 'remitente',
          type: 'Usuario',
          model: 'Usuario',
          relationType: 'belongsTo',
                  keyFrom: 'idRemitente',
          keyTo: 'idUsuario'
        },
        destinatario: {
          name: 'destinatario',
          type: 'Usuario',
          model: 'Usuario',
          relationType: 'belongsTo',
                  keyFrom: 'idDestinatario',
          keyTo: 'idUsuario'
        },
      }
    }
  }
}
