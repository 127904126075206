import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import { CarteraComponent } from './cartera/cartera.component';
import { LogoutComponent } from './logout/logout.component';
import { CarteraAbogadoComponent } from './cartera-abogado/cartera-abogado.component';
import { NuevoCasoComponent } from './nuevo-caso/nuevo-caso.component';
import { EditarModalComponent } from './editar-modal/editar-modal.component';
import { HistorialModalComponent } from './historial-modal/historial-modal.component';
import { AsignarCasoComponent } from './asignar-caso/asignar-caso.component';
import { NuevoDespachoComponent } from './nuevo-despacho/nuevo-despacho.component';
import { EditarCasoComponent } from './editar-caso/editar-caso.component';
import { GastosComponent } from './gastos/gastos.component';
import { GastosAbogadoComponent } from './gastos-abogado/gastos-abogado.component';
import { InitComponent } from './init/init.component';
import { NuevoGastoComponent } from './nuevo-gasto/nuevo-gasto.component';
import { InhabilitarDespachoComponent } from './inhabilitar-despacho/inhabilitar-despacho.component';
import { DiasInahabilesComponent } from './dias-inhabiles/dias-inhabiles.component';
import { BitacoraComponent } from './bitacora/bitacora.component';
import { ResumenComponent } from './resumen/resumen.component';
import { AprobarGastosComponent } from './aprobar-gastos/aprobar-gastos.component';
import { AlertasComponent } from './alertas/alertas.component';
import { NuevoUsuarioComponent } from './nuevo-usuario/nuevo-usuario.component';
import { BloquearUsuarioComponent } from './bloquear-usuario/bloquear-usuario.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        //redirectTo: 'dashboard/analytics',
        redirectTo: 'auth',
        pathMatch: 'full'
      },
      {
        
        path: 'auth',
        loadChildren: () => import('./demo/pages/authentication/authentication.module').then(module => module.AuthenticationModule)
      },
      {
        path: 'maintenance',
        loadChildren: () => import('./demo/pages/maintenance/maintenance.module').then(module => module.MaintenanceModule)
      }
    ]
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./demo/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'layout',
        loadChildren: () => import('./demo/pages/layout/layout.module').then(module => module.LayoutModule)
      },
      {
        path: 'cartera',
        component:CarteraComponent
      },
      {
        path: 'editar',
        component:EditarModalComponent
      },
      {
        path: 'historial',
        component:HistorialModalComponent
      },
      {
        path: 'cartera-abogado',
        component:CarteraAbogadoComponent
      },
      {
        path: 'nuevo-caso',
        component:NuevoCasoComponent
      },
      {
        path: 'logout',
        component:LogoutComponent
      },
      {
        path: 'asignar-caso',
        component:AsignarCasoComponent
      },
      {
        path: 'nuevo-despacho',
        component:NuevoDespachoComponent
      },
      {
        path: 'editar-caso/:id',
        component:EditarCasoComponent
      },
      {
        path: 'editar-caso',
        component:EditarCasoComponent
      },
      {
        path: 'gastos',
        component:GastosComponent
      },
      {
        path: 'aprobar-gastos',
        component:AprobarGastosComponent
      },
      {
        path: 'gastos-abogado',
        component:GastosAbogadoComponent
      },
      {
        path: 'init',
        component:InitComponent
      },
      {
        path: 'nuevo-gasto',
        component:NuevoGastoComponent
      },
      {
        path: 'inhabilitar-despacho',
        component:InhabilitarDespachoComponent
      },
      {
        path: 'Assignar-dias-inahables',
        component:DiasInahabilesComponent
      },
      {
        path: 'bitacora',
        component:BitacoraComponent
      },
      {
        path: 'resumen',
        component:ResumenComponent
      },
      {
        path: 'alertas',
        component:AlertasComponent
      },
      {
        path: 'nuevo-usuario',
        component:NuevoUsuarioComponent
      },
      {
        path: 'bloquear-usuario',
        component:BloquearUsuarioComponent
      },
      {
        path: 'basic',
        loadChildren: () => import('./demo/ui-elements/ui-basic/ui-basic.module').then(module => module.UiBasicModule)
      },
      {
        path: 'forms',
        loadChildren: () => import('./demo/pages/form-elements/form-elements.module').then(module => module.FormElementsModule)
      },
      {
        path: 'tbl-bootstrap',
        loadChildren: () => import('./demo/pages/tables/tbl-bootstrap/tbl-bootstrap.module').then(module => module.TblBootstrapModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./demo/pages/core-chart/core-chart.module').then(module => module.CoreChartModule)
      },
      {
        path: 'sample-page',
        loadChildren: () => import('./demo/pages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      },
      
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
