/* tslint:disable */
import {
  Caso
} from '../index';

declare var Object: any;
export interface GastoAsignacionInterface {
  "idGastoAsignacion"?: number;
  "tipo"?: number;
  "concepto"?: string;
  "monto"?: number;
  "idCaso"?: number;
  "createdAt": Date;
  "updatedAt": Date;
  caso?: Caso;
}

export class GastoAsignacion implements GastoAsignacionInterface {
  "idGastoAsignacion": number;
  "tipo": number;
  "concepto": string;
  "monto": number;
  "idCaso": number;
  "createdAt": Date;
  "updatedAt": Date;
  caso: Caso;
  constructor(data?: GastoAsignacionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `GastoAsignacion`.
   */
  public static getModelName() {
    return "GastoAsignacion";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of GastoAsignacion for dynamic purposes.
  **/
  public static factory(data: GastoAsignacionInterface): GastoAsignacion{
    return new GastoAsignacion(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'GastoAsignacion',
      plural: 'GastosAsignacion',
      path: 'GastosAsignacion',
      idName: 'idGastoAsignacion',
      properties: {
        "idGastoAsignacion": {
          name: 'idGastoAsignacion',
          type: 'number'
        },
        "tipo": {
          name: 'tipo',
          type: 'number'
        },
        "concepto": {
          name: 'concepto',
          type: 'string'
        },
        "monto": {
          name: 'monto',
          type: 'number'
        },
        "idCaso": {
          name: 'idCaso',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        caso: {
          name: 'caso',
          type: 'Caso',
          model: 'Caso',
          relationType: 'belongsTo',
                  keyFrom: 'idCaso',
          keyTo: 'idCaso'
        },
      }
    }
  }
}
