/* tslint:disable */
  declare var Object: any;
export interface DiasInhabilesInterface {
  "idDiasInhabiles": number;
  "diasInhabiles"?: number;
  "periodoInicial"?: Date;
  "periodoFinal"?: Date;
  "createdAt": Date;
  "updatedAt": Date;
  "estado": String;
}

export class DiasInhabiles implements DiasInhabilesInterface {
  "idDiasInhabiles": number;
  "diasInhabiles": number;
  "periodoInicial": Date;
  "periodoFinal": Date;
  "createdAt": Date;
  "updatedAt": Date;
  "estado": String;
  constructor(data?: DiasInhabilesInterface) {
    Object.assign(this, data);
  }

  public static getModelName() {
    return "DiasInhabiles";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Mail for dynamic purposes.
  **/
  public static factory(data: DiasInhabilesInterface): DiasInhabiles{
    return new DiasInhabiles(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'DiasInhabile',
      plural: 'DiasInhabiles',
      path: 'DiasInhabiles',
      idName: 'idDiasInhabiles',
      properties: {
        "idDiasInhabiles": {
          name: 'idDiasInhabiles',
          type: 'number'
        },
        "diasInhabiles": {
            name: 'diasInhabiles',
            type: 'number'
        },
        "periodoInicial": {
            name: 'periodoInicial',
            type: 'Date'
        },
        "periodoFinal": {
            name: 'periodoFinal',
            type: 'Date'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "estado": {
            name: 'estado',
            type: 'text'
        }, 
      },
      relations: {
      }
    }
  }
}