import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';
import { InformeMens, Caso, CasoApi, Log, LogApi, UsuarioApi, Usuario } from '../shared/sdk';
import { TouchSequence } from 'selenium-webdriver';
import { InformeApi } from './../shared/sdk/services/custom/InformeMens';

@Component({
  selector: 'app-editar2-modal',
  templateUrl: './editar2-modal.component.html',
  styleUrls: ['./editar2-modal.component.css']
})
export class Editar2ModalComponent implements OnInit {
selectedCaso:Caso
comentario = '' //VARIABLE QUE CAPTURA EL CONTENIDO PARA EL INFORME INFORME MENSUAL DEL CASO
BloqueadorInforme: boolean = false;
mesReal: any; //VA A CAPTURAR EL MES QUE ESTE MARCADO EN EL OBJETO DATE
añoReal: any; // VA A CAPTURAR EL AÑO QUE ESTE MARCADO EN EL OBJETO DATE 
fechaRealPrincio: any 
fechaRealFinal: any
actualDate: Date = new Date();
fechaAmostrar: any 
selectedInforme: InformeMens

//seleccionador2: boolean

Et_procesalList: any[] = [
  { v: 0, c: 'Demanda' },
  { v: 1, c: 'Admision de demanda' },
  { v: 2, c: 'Diligencia de requerimiento de pago y embargo' },
  { v: 3, c: 'Contestacion de demanda' },
  { v: 4, c: 'Desahogo de vista de contestacion de demanda' },
  { v: 5, c: 'Pruebas' },
  { v: 6, c: 'Desahogo de pruebas' },
  { v: 7, c: 'Alegatos' },
  { v: 8, c: 'Sentencia' },
  { v: 9, c: 'Ejecucion de sentencia' },
  { v: 10, c: 'Procedimiento de remate' },
  { v: 11, c: 'Adjudicacion' },
  { v: 12, c: '' },
]
//LISTA PARA PODER SELECCIONAR UN ELEMENTO DE JUZGADO MERCANTIL A LA HORA DE EDITAR UN USUARIO
juzgadoMlist: any[] = [
  { v: 26, c: 'J°1 M.' },
  { v: 27, c: 'J°2 M.' },
  { v: 28, c: 'J°3 M.' },
  { v: 29, c: 'J°4 M.' },
  { v: 30, c: 'J°5 M.' },
  { v: 31, c: 'J°6 M.' },
  { v: 32, c: 'J°7 M.' },
  { v: 33, c: 'J°8 M.' },
  { v: 34, c: 'J°9 M.' },
  { v: 35, c: 'J°10 M.' },
  { v: 36, c: 'J°1 O.M.' },
  { v: 37, c: 'J°2 O.M.' },
  { v: 38, c: 'J°3 O.M.' },
  { v: 39, c: 'J°4 O.M.' },
  { v: 40, c: 'J°5 O.M.' },
  { v: 41, c: 'J°6 O.M.' },
  { v: 42, c: 'J°7 O.M.' },
  { v: 43, c: 'J°8 O.M.' },
  { v: 44, c: 'J°9 O.M.' },
  { v: 45, c: 'J°10 O.M.' },
  { v: 46, c: '' },
]
//LISTA PARA PODER SELECCIONAR UN ELEMENTO DE JUZGADO CIVIL A LA HORA DE EDITAR UN USUARIO
juzgadoClist: any[] = [
  { v: 0, c: 'J°1 C.' },
  { v: 1, c: 'J°2 C.' },
  { v: 2, c: 'J°3 C.' },
  { v: 3, c: 'J°4 C.' },
  { v: 4, c: 'J°5 C.' },
  { v: 5, c: 'J°6 C.' },
  { v: 6, c: 'J°7 C.' },
  { v: 7, c: 'J°8 C.' },
  { v: 8, c: 'J°9 C.' },
  { v: 9, c: 'J°10 C.' },
  { v: 10, c: 'J°11 C.' },
  { v: 11, c: 'J°12 C.' },
  { v: 12, c: 'J°13 C.' },
  { v: 13, c: 'J°14 C.' },
  { v: 14, c: 'J°1 O.C.' },
  { v: 15, c: 'J°2 O.C.' },
  { v: 16, c: 'J°3 O.C.' },
  { v: 17, c: 'J°4 O.C.' },
  { v: 18, c: 'J°5 O.C.' },
  { v: 19, c: 'J°6 O.C.' },
  { v: 20, c: 'J°7 O.C.' },
  { v: 21, c: 'J°8 O.C.' },
  { v: 22, c: 'J°9 O.C.' },
  { v: 23, c: 'J°10 O.C.' },
  { v: 24, c: 'J°11 O.C.' },
  { v: 25, c: 'J°12 O.C.' },
  { v: 26, c: 'J°13 O.C.' },
  { v: 27, c: 'J°14 O.C.' },
  { v: 28, c: '' },

]

/*
recuperadoList: any []=[
  {v: false, c: 'NO'},
  {v: true, c: 'SI'},
]*/
  constructor(public informeapi: InformeApi,public usuarioapi:UsuarioApi, public logapi:LogApi,public ref: DynamicDialogRef, public config: DynamicDialogConfig,public casoapi:CasoApi)
   { 
  
   }
  
  ngOnInit() {
    this.selectedCaso =   this.config.data.caso;
    //console.log(this.selectedCaso.idCaso)
    this.checarSiHayInforme();
  }

  checarSiHayInforme(){
    //console.log("ENTRO AL METODO!")
    let id: Number
    let stringId = ''
    let fechaInforme: any
    let spliteadoInforme
    this.BloqueadorInforme = false 
    this.mesReal = this.actualDate.getUTCMonth()+1;
    this.añoReal = this.actualDate.getUTCFullYear();
    this.fechaRealPrincio = this.añoReal+'-'+this.mesReal+'-'+'01'
    this.fechaRealFinal = this.añoReal+'-'+this.mesReal+'-'+'31'
    id = this.selectedCaso.idCaso
    stringId = id.toString();

  //----------CONSULTA DE INFORME DEPENDIENDO DEL ID CASO----
    this.informeapi.findOne({
      where:{
            idCaso:stringId,
            createdAt:{
              between:[new Date(this.fechaRealPrincio).toISOString(),
                new Date(this.fechaRealFinal).toISOString(),] as [string, string],
            },
          }}).subscribe((i: InformeMens) => {
      if(i.idCaso == null){
        this.BloqueadorInforme = false
      }
      else{
          this.selectedInforme = i //i AHORA TIENE EL INFORME SELECCIONADO POR ID
          //console.log("VALOR DE i "+ i.createdAt)
          fechaInforme = i.createdAt //AQUI SE OBTIENE LA FECHA DEL INFORME 
          spliteadoInforme = fechaInforme.split("-") //SE SPLITEA EL AÑO Y MES, YA QUE SON LOS UNICOS QUE NECESITAMOS
          //console.log("VALOR DE CREATED AT: "+i.createdAt)
          //console.log("VALOR DE spliteadoInforme: AÑO "+spliteadoInforme[0]);
          //console.log("VALOR DE spliteadoInforme: MES "+spliteadoInforme[1]);
          //console.log("VALOR DEL DATO TIPO DATE "+this.actualDate)
          if(spliteadoInforme[1]==this.mesReal && spliteadoInforme[0]==this.añoReal){
            this.BloqueadorInforme = true
            this.fechaAmostrar = "ESTE CASO YA CUENTA CON INFORME DEL MES ACTUAL: "+spliteadoInforme[1]+"/"+spliteadoInforme[0]
          }
      }
    })

  }

  //FUNCION PARA ENVIAR UN INFORME MENSUAL SOBRE EL CASO, SE ACTIVA ATRAVES DEL BOTON
  //Actualizar informe mensual
  elInforme(){
    var i: InformeMens = new InformeMens();
    //console.log("VALOR DEL BLOQUEADOR:"+this.BloqueadorInforme)
    
    if(this.comentario == null || this.comentario == '')
    {
      i.Contenido = null
    }
    else{
    i.Contenido = this.comentario
    i.idCaso = this.selectedCaso.idCaso
    //console.log("EL CONTENIDO "+i.Contenido);
    this.informeapi.upsert(i).subscribe((res) => {
      //console.log(res)
              this.casoapi.upsert(this.selectedCaso).subscribe((d) => {
                //genera el log
                this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
                  var r: Log = new Log();
                  r.createdAt = new Date()
                  r.updatedAt = new Date()
                  r.idUsuario = us.idUsuario
                  r.tipo = 7
                  r.contenido =  JSON.stringify(d)
                  this.logapi.create(r).subscribe((d) => {
                  })
                })
              })
            })
        this.BloqueadorInforme = true;
        this.fechaAmostrar = this.comentario //EN ESTA FUNCION, FECHA A MOSTRAR SOLO MOSTRARA EL CONTENIDO QUE SE ENVIO EN ESE MOMENTO
                                             //A LA BASE DEL SISTEMA, PERO CUANDO SE VUELVA A CHECAR EL CASO YA APARECERA LA OTRA LEYENDA
        this.comentario = '' //SE VUELVE A DEJAR EN BLANCO EL COMENTARIO 
    
      }
   }

updateCaso()
{
/*if(this.selectedCaso.recuperado==true){
  this.seleccionador2 = true;
}
//else{
this.selectedCaso.recuperado=this.seleccionador2;
console.log("ESTADO DEL SELECTEDCASO "+ this.selectedCaso.recuperado);
  console.log(this.seleccionador2);
  console.log(this.selectedCaso.recuperado);
//} SE QUEDA COMENTADO EL IF Y ELSE PARA FUTURA IMPLEMENTACION 
Y PORQUE ME COSTO MUCHO HACERLO EN UN DIA PARA QUE SEA DESHECHADO TuT*/
//-----------------------------------------------------------------

  //actualiza el objeto conforme a los datos que se hayan cambiado en el front
  this.casoapi.upsert(this.selectedCaso).subscribe((d)=>{
    this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
      //crea la entrada en el log
      var r: Log = new Log()
      r.createdAt = new Date()
      r.updatedAt = new Date()
      r.idUsuario = us.idUsuario
      r.tipo = 12
      r.contenido = JSON.stringify(d) 
      this.logapi.create(r).subscribe((d) => {
  
      })
      //console.log(d)
      this.ref.close()
    })
   
  },(error)=>{
    //console.log(error)
  })
}
}
