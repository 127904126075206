import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/primeng';
import { ComentarioApi, Comentario, Caso, ContainerApi, UsuarioApi, Log, LogApi, Usuario } from '../shared/sdk';
import { Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import * as fileSaver from 'file-saver';
import { HttpResponse,HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-historial-modal',
  templateUrl: './historial-modal.component.html',
  styleUrls: ['./historial-modal.component.css']
})
export class HistorialModalComponent implements OnInit {
comentarios = []
caso:Caso
  constructor(public logapi:LogApi,public usuarioapi:UsuarioApi, private http: HttpClient,public containerapi:ContainerApi, public ref: DynamicDialogRef, public config: DynamicDialogConfig, public comentarioapi:ComentarioApi) {
    this.caso = this.config.data.caso
    //console.log(this.caso)
   }


downloadFile(f): Observable<Blob>{
//descarga archivo con base al id del usuario actual y el nombre del archivo dado
  var i =  this.caso.usuario.idUsuario
  var url = 'https://api1.accedde.mx/api/containers/'+ i+'/download/'+f
  //var url = 'http://localhost:3000/api/containers/'+ i+'/download/'+f
  this.usuarioapi.getCurrent().subscribe((us: Usuario) => {

    //registra en el log la descarga
    var r: Log = new Log();
    r.createdAt = new Date()
    r.updatedAt = new Date()
    r.idUsuario = us.idUsuario
    r.tipo = 3
    r.contenido = JSON.stringify(f)
    this.logapi.create(r).subscribe((d) => {
  
    })	
  })
	
  return this.http.get(url, { responseType: 'blob' });
 }
//descarga el documento
 download(f) {
  this.downloadFile(f).subscribe(response => {
    let blob:any = new Blob([response], { type: 'text/json; charset=utf-8' });
    const url= window.URL.createObjectURL(blob);
   // window.open(url);
    //window.location.href = response.url;
    fileSaver.saveAs(blob, f);
  }), error => console.log('Error downloading the file'),
               () => console.info('File downloaded successfully');
}


  ngOnInit() {
    var c:Comentario = new Comentario()
    //this.comentarioapi.find({where:{idCaso:}})
   //console.log("INIT MODAL historial")
   //carga los comentarios del caso consultndo en la base
   //console.log(this.config)
   this.comentarioapi.find({where:{idCaso:this.config.data.caso.idCaso},order:'createdAt DESC'}).subscribe((com:[])=>
   {
     this.comentarios = com
     //console.log(com)
   })
  }

}
