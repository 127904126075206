import { Component, OnInit } from '@angular/core';
import { Log, LogApi } from '../shared/sdk';

@Component({
  selector: 'app-bitacora',
  templateUrl: './bitacora.component.html',
  styleUrls: ['./bitacora.component.scss']
})
export class BitacoraComponent implements OnInit {

  logs:Log[] = []
  constructor(public logapi:LogApi) { }

  ngOnInit() {
//consulta la tabla bitacora y te muestra los resultados
    this.logapi.find({order:'createdAt DESC',limit:50,include:'usuario'}).subscribe((l:Log[])=>
    {
      this.logs = l;
    })
  }

      //FUNCION PARA EL TIPO 14 Y 15 DEL LOG
      convertidorGastos(contenido){ 
        let jsonReal
          if(contenido==""||contenido==null){
            
          }
          else if(contenido != "" || contenido !=null){
          jsonReal = JSON.parse(contenido)
        } 
      return jsonReal.username
    }
    //------------------------------------------------------

    //FUNCION PARA EL TIPO 4 Y 5 DEL LOG
    convertidorAprobadoDesaprobado(contenido){ 
      
      let jsonReal
      let metodoDePago
        if(contenido==""||contenido==null){
          
        }
        else if(contenido != "" || contenido !=null){
      
      
        jsonReal = JSON.parse(contenido)
        switch(jsonReal.aplicarMediante){
          case 0:
            metodoDePago = "Transferencia"
            break;
          
          case 1:
            metodoDePago = "Efectivo"
            break;

          case 2: 
          metodoDePago = "Cheque"
          break;

          case 3:
          metodoDePago = "Deposito"
          break;

          default:
          metodoDePago = ""
          break;
        }
      } 

    return jsonReal.concepto +" por el monto de "+jsonReal.monto+"$ pesos por el metodo de pago "+"'"+metodoDePago+"'"
    }
    //-----------------------------------------------------------------------------------------

    //FUNCION PARA TIPO 6 DEL LOG
    convertidorAsignado(contenido){ 
      
      let jsonReal
      

        if(contenido==""||contenido==null){
          
        }
        else if(contenido != "" || contenido !=null){

        
        jsonReal = JSON.parse(contenido)
        
      } 
    return jsonReal.acreditado+" con el numero de control: "+jsonReal.numeroControl
    }
    //--------------------------------------------------------------------

    //FUNCION PARA TIPO 7 DEL LOG
      convertidorEstado(contenido){ 
        
        let jsonReal
        
      
          if(contenido==""||contenido==null){
           
          }
          else if(contenido != "" || contenido !=null){
          jsonReal = JSON.parse(contenido)
        } 
      
      return jsonReal.acreditado+" con id "+jsonReal.id+" y con el numero de control: "+jsonReal.numeroControl
    }
    //--------------------------------------------------------------------------

    //FUNCION PARA TIPO 8 DEL LOG

    convertidorArchivos(contenido){ 
      
      let jsonReal
      

        if(contenido==""||contenido==null){
          
        }
        else if(contenido != "" || contenido !=null){
        
        
        jsonReal = JSON.parse(contenido)
      
      } 

    return jsonReal.name
    }
    //---------------------------------------------------------------------

    //FUNCION PARA TIPO 9 DEL LOG
      convertidor_CompGasto(contenido){ 
        
        let jsonReal
          if(contenido==""||contenido==null){
          
          }
          else if(contenido != "" || contenido !=null){
      
          
          jsonReal = JSON.parse(contenido)
          
        } 
      return jsonReal.concepto+" por el monto de  "+jsonReal.monto+"$ pesos con el archivo: "+jsonReal.archivo
    }
    //---------------------------------------------------------------------------------------

    //FUNCION PARA TIPO 10 Y 12 DEL LOG
    convertidorEditar(contenido){ 
      
      let jsonReal
      let fechaAsigSplit
      let fechaTransformada
      let diaSplit
        if(contenido==""||contenido==null){
          
        }
        else if(contenido != "" || contenido !=null){

        jsonReal = JSON.parse(contenido)
        fechaAsigSplit =jsonReal.fechaAsignacion.split("-");
        diaSplit = fechaAsigSplit[2].split("")
        fechaTransformada = diaSplit[0]+diaSplit[1]+"-"+fechaAsigSplit[1]+"-"+fechaAsigSplit[0]
      } 
    return jsonReal.acreditado+" con id "+jsonReal.id+" y con el numero de control: "+jsonReal.numeroControl+" y con fecha de asigancion del "+fechaTransformada
    }
    //--------------------------------------------------------------------------------------


    //FUNCION PARA TIPO 11 DEL LOG
    convertidorComentario(contenido){ 
      
      let jsonReal
        if(contenido==""||contenido==null){
          
        }
        else if(contenido != "" || contenido !=null){
        jsonReal = JSON.parse(contenido)
      
      } 
    return jsonReal.archivo
    }
    //--------------------------------------------------------------------------------------

    //FUNCION PARA TIPO 16 DEL LOG
      convertidorNuevoC(contenido){ 
        
        let jsonReal
        let fechaAsigSplit
        let fechaTransformada
        let diaSplit
          if(contenido==""||contenido==null){
            
          }
          else if(contenido != "" || contenido !=null){
          
          jsonReal = JSON.parse(contenido)
          fechaAsigSplit =jsonReal.fechaAsignacion.split("-");
        diaSplit = fechaAsigSplit[2].split("")
        fechaTransformada = diaSplit[0]+diaSplit[1]+"-"+fechaAsigSplit[1]+"-"+fechaAsigSplit[0]
        
        } 
      
      
      return jsonReal.acreditado+", con numero de control: "+jsonReal.numeroControl+", id: "+jsonReal.id+" y fecha de asignacion del: "+fechaTransformada
    }
    //-----------------------------------------------------------------------------------------

    //FUNCION PARA TIPO 20 Y 21 DEL LOG
    convertidorDesbloquear(contenido){ 
      
      let jsonReal
      
    
        if(contenido==""||contenido==null){
          
        }
        else if(contenido != "" || contenido !=null){
      
        
        jsonReal = JSON.parse(contenido)
      
      } 
    

    return jsonReal.username+" || nombres: "+jsonReal.nombres+" "+jsonReal.aPaterno+" "+jsonReal.aMaterno
    }
    //-----------------------------------------------------

}
