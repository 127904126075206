import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reporte-principal',
  templateUrl: './reporte-principal.component.html',
  styleUrls: ['./reporte-principal.component.scss']
})
export class ReportePrincipalComponent implements OnInit {
//aqui irian reportes especificos, checar el trello
  constructor() { }

  ngOnInit() {

    

  }

}
