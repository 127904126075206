import { Component, OnInit } from '@angular/core';
import { UsuarioApi, Usuario, Caso, CasoApi, Log, LogApi } from '../shared/sdk';

@Component({
  selector: 'app-nuevo-caso',
  templateUrl: './nuevo-caso.component.html',
  styleUrls: ['./nuevo-caso.component.css']
})
export class NuevoCasoComponent implements OnInit {

  abogados: any[] = []
  caso: Caso = new Caso();

  constructor(public logapi: LogApi, public usuarioapi: UsuarioApi, public casoapi: CasoApi) {

  }

  ngOnInit() {
    this.getAbogados()
  }

  //obtiene lista de abogados (tipo =2 == abogado) para visualizar en el dropbox
  getAbogados() {
    this.usuarioapi.find({ where: { tipo: 2 }, fields: { username: true, idUsuario: true } }).subscribe((u: Usuario[]) => {
      //console.log(u)
      for (var a of u) {
        this.abogados.push(a)
      }

    })
  }

  logout() {

  }
  createCaso() {
    //todas las variables del front son epaquetadas en la variable this.caso de tipo Caso (dado por el sdk asi que )
    //no hay problemas de tipo de dato

    this.caso.estatusM = 0 //SE ASIGNA EN AUTOMATICO EL ESTATUS MERCANTIL AL CREAR UN NUEVO CASO
    this.caso.Et_procesal = 0 // SE ASIGNA EN AUTOMATICO LA ETAPA PROCESAL AL CREAR UN NUEVO CASO
    this.caso.recuperado2 = false //SE AGREGA EL RECUPERADO2 EN FALSE PARA QUE PUEDA HACERSE LA SUMATORIA DE CAPITALES
    this.casoapi.upsert(this.caso).subscribe((d) => {
      this.usuarioapi.getCurrent().subscribe((us: Usuario) => {

        //log de la accion
        var r: Log = new Log();
        r.createdAt = new Date()
        r.updatedAt = new Date()
        r.idUsuario = us.idUsuario
        r.tipo = 16
        r.contenido = JSON.stringify(d)
        this.logapi.create(r).subscribe((d) => {})

        this.caso.acreditado=''
        us.idUsuario = null
        this.caso.tramo = null
        this.caso.numeroControl = ''
        this.caso.Cast_fira = ''
        this.caso.Cast_accedde = ''
        this.caso.capital = null
        this.caso.intOrdinarios = null
        this.caso.intMoratorios = null
        this.caso.cap_efsy = null
        this.caso.intOrdinarios_efsy = null
        this.caso.intMoratorios_efsy = null
        this.caso.saldoCont = null
        this.caso.garantia = ''
        this.caso.valorEstimado = null
        this.caso.reservas = ''
        this.caso.grupo = ''
        this.caso.fechaAsignacion = null
        this.caso.fechaRecuperacion = null
        this.caso.honorario = null
        this.caso.honorariosPeri = null
        this.caso.honorariosRebel = null
        this.caso.expedienteM = ''
        this.caso.expedienteC = ''
        this.caso.juzgadoM = null
        this.caso.juzgadoC = null
        this.caso.id = null
      })
      alert("CASO AGREGADO EXITOSAMENTE")
      console.log(d)
    }, (error) => {
      console.log(error)
    })
    }
}
