/* tslint:disable */
import { Injectable } from '@angular/core';
import { User } from '../../models/User';
import { Container } from '../../models/Container';
import { Usuario } from '../../models/Usuario';
import { Caso } from '../../models/Caso';
import { Documento } from '../../models/Documento';
import { Comentario } from '../../models/Comentario';
import { Gasto } from '../../models/Gasto';
import { Garantia } from '../../models/Garantia';
import { GastoAsignacion } from '../../models/GastoAsignacion';
import { GastoEliminado } from '../../models/GastoEliminado';
import { Embargo } from '../../models/Embargo';
import { Log } from '../../models/Log';
import { Mensaje } from '../../models/Mensaje';
import { Archivo } from '../../models/Archivo';
import { Mail } from '../../models/Mail';
import { InformeMens } from '../../models/InformeMens'; //NUEVA TABLA
import { DiasInhabiles } from '../../models/DiasInhabiles';//NUEVA TABLA
import { Notas } from '../../models/Notas';//NUEVA TABLA
import { MensajeNotificacion } from '../../models/MensajeNotificacion';//NUEVA TABLA

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    User: User,
    Container: Container,
    Usuario: Usuario,
    Caso: Caso,
    Documento: Documento,
    Comentario: Comentario,
    Gasto: Gasto,
    Garantia: Garantia,
    GastoAsignacion: GastoAsignacion,
    GastoEliminado: GastoEliminado,
    Embargo: Embargo,
    Log: Log,
    Mensaje: Mensaje,
    Archivo: Archivo,
    Mail: Mail,
    InformeMens: InformeMens, //NUEVA TABLA 
    DiasInhabiles: DiasInhabiles, //NUEVA TABLA
    Notas: Notas, //NUEVA TABLA
    MensajeNotificacion: MensajeNotificacion
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
