import { Mensaje } from './../shared/sdk/models/Mensaje';
import { ArchivoApi } from './../shared/sdk/services/custom/Archivo';
import { Archivo } from './../shared/sdk/models/Archivo';

import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { Caso, ContainerApi, UsuarioApi, GastoApi, Log, LogApi, Usuario } from '../shared/sdk';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-subir-archivo-modal',
  templateUrl: './subir-archivo-modal.component.html',
  styleUrls: ['./subir-archivo-modal.component.scss']
})
export class SubirArchivoModalComponent implements OnInit {
  bntStyle: string = '';
  caso: Caso
  tipoArchivo
  file
  filename
  value: number;
  data
  showProgress = false;

  constructor(public logapi: LogApi, public archivoapi:ArchivoApi, public containerapi: ContainerApi, public usuarioapi: UsuarioApi, public http: HttpClient, public ref: DynamicDialogRef, public config: DynamicDialogConfig) 
  { 
    this.caso = this.config.data.caso;
    this.tipoArchivo = this.config.data.tipoArchivo;
  }
 
  ngOnInit() {
  }
  onFileSelect(event) {
    //console.log('on MODAL')
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.filename = event.target.files[0].name;
    }
    //console.log(this.file)
  }

  upload() {
    this.showProgress = true;
    let formData: FormData = new FormData();
    formData.append('file', this.file, this.filename);
    let headerst = new HttpHeaders();
    headerst.append('Content-Type', 'multipart/form-data');
    headerst.append('Accept', 'application/json');
    var i: number = this.caso.idCaso;
    //subiendo archivo al servidor 
    var url = 'https://api1.accedde.mx/api/containers/' + i + '/upload'
    //var url = 'http://localhost:3000/api/containers/' + i + '/upload'
    this.http.post(url, formData, { headers: headerst, reportProgress: true, observe: 'events' }).pipe(map((event: any) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          this.value = progress;
          //console.log(progress)
          return { status: 'progress', message: progress };
        case HttpEventType.Response:
          this.ref.close()
          //console.log("FINISHED")
          return event.body;
        default:
          //console.log("unhandled")
          return `Unhandled event: ${event.type}`;
      }
    }))
      .subscribe(
        (data: any) => {
          //console.log(data)
          this.data = data
        },
        error => {
          //si hay error es que no se ha creado el contenedor (carpeta) en el servidor , creala y vuelve a intentar
          var s = i.toString()
          this.containerapi.create({ name: s }).subscribe((s) => {
            //console.log(s)
            this.upload()
          })
        }, () => {
          var temparchivo = new Archivo();
          temparchivo.path = this.data.result.files.file[0].name;
          temparchivo.idCaso = this.caso.idCaso;
          temparchivo.tipo = this.tipoArchivo;
          this.archivoapi.create(temparchivo).subscribe((archivoResponse)=>
          {
            //crea un objeto archivo en la base 
            this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
              //log de la accion
              var r2: Log = new Log()
              r2.createdAt = new Date()
              r2.updatedAt = new Date()
              r2.idUsuario = us.idUsuario
              r2.tipo = 8
              r2.contenido =JSON.stringify(this.data.result.files.file[0])
              this.logapi.create(r2).subscribe((d) => {
              })
              //log de la accion
              var r: Log = new Log()
              r.createdAt = new Date()
              r.updatedAt = new Date()
              r.idUsuario = us.idUsuario
              r.tipo = 9
              r.contenido =JSON.stringify(archivoResponse)
              this.logapi.create(r).subscribe((d) => {
              })
            })
          },(err)=>
          {
          console.log(err)
          });
      }
      )
  }

}
