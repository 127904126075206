/* tslint:disable */
import {
  Caso
} from '../index';

declare var Object: any;
export interface GarantiaInterface {
  "idGarantia"?: number;
  "descripcion"?: any;
  "tipo"?: number;
  "subclasificacion"?: number;
  "numeroSerie"?: any;
  "numeroFactura"?: any;
  "tituloPropiedad"?: any;
  "archivo"?: any;
  "valorEstimado"?: number;
  "latitud"?: number;
  "longitud"?: number;
  "idCaso"?: number;
  "createdAt": Date;
  "updatedAt": Date;
  caso?: Caso;
}

export class Garantia implements GarantiaInterface {
  "idGarantia": number;
  "descripcion": any;
  "tipo": number;
  "subclasificacion": number;
  "numeroSerie": any;
  "numeroFactura": any;
  "tituloPropiedad": any;
  "archivo": any;
  "valorEstimado": number;
  "latitud": number;
  "longitud": number;
  "idCaso": number;
  "createdAt": Date;
  "updatedAt": Date;
  caso: Caso;
  constructor(data?: GarantiaInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Garantia`.
   */
  public static getModelName() {
    return "Garantia";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Garantia for dynamic purposes.
  **/
  public static factory(data: GarantiaInterface): Garantia{
    return new Garantia(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Garantia',
      plural: 'Garantias',
      path: 'Garantias',
      idName: 'idGarantia',
      properties: {
        "idGarantia": {
          name: 'idGarantia',
          type: 'number'
        },
        "descripcion": {
          name: 'descripcion',
          type: 'any'
        },
        "tipo": {
          name: 'tipo',
          type: 'number'
        },
        "subclasificacion": {
          name: 'subclasificacion',
          type: 'number'
        },
        "numeroSerie": {
          name: 'numeroSerie',
          type: 'any'
        },
        "numeroFactura": {
          name: 'numeroFactura',
          type: 'any'
        },
        "tituloPropiedad": {
          name: 'tituloPropiedad',
          type: 'any'
        },
        "archivo": {
          name: 'archivo',
          type: 'any'
        },
        "valorEstimado": {
          name: 'valorEstimado',
          type: 'number'
        },
        "latitud": {
          name: 'latitud',
          type: 'number'
        },
        "longitud": {
          name: 'longitud',
          type: 'number'
        },
        "idCaso": {
          name: 'idCaso',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        caso: {
          name: 'caso',
          type: 'Caso',
          model: 'Caso',
          relationType: 'belongsTo',
                  keyFrom: 'idCaso',
          keyTo: 'idCaso'
        },
      }
    }
  }
}
