/* tslint:disable */
import {
  Caso,
  Comentario,
  Log,
  Mensaje
} from '../index';

declare var Object: any;
export interface UsuarioInterface {
  "idUsuario"?: number;
  "tipo": number;
  "nombres": string;
  "aPaterno": string;
  "aMaterno": string;
  "activo": boolean;
  "gastosHabilitados"?: boolean;
  "slack_id"?: string;
  "realm"?: string;
  "username"?: string;
  "email": string;
  "emailVerified"?: boolean;
  "createdAt": Date;
  "updatedAt": Date;
  "password"?: string;
  accessTokens?: any[];
  casos?: Caso[];
  comentario?: Comentario[];
  logs?: Log[];
  mensajes?: Mensaje[];
}

export class Usuario implements UsuarioInterface {
  "idUsuario": number;
  "tipo": number;
  "nombres": string;
  "aPaterno": string;
  "aMaterno": string;
  "activo": boolean;
  "gastosHabilitados": boolean;
  "slack_id": string;
  "realm": string;
  "username": string;
  "email": string;
  "emailVerified": boolean;
  "createdAt": Date;
  "updatedAt": Date;
  "password": string;
  accessTokens: any[];
  casos: Caso[];
  comentario: Comentario[];
  logs: Log[];
  mensajes: Mensaje[];
  constructor(data?: UsuarioInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Usuario`.
   */
  public static getModelName() {
    return "Usuario";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Usuario for dynamic purposes.
  **/
  public static factory(data: UsuarioInterface): Usuario{
    return new Usuario(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Usuario',
      plural: 'Usuarios',
      path: 'Usuarios',
      idName: 'idUsuario',
      properties: {
        "idUsuario": {
          name: 'idUsuario',
          type: 'number'
        },
        "tipo": {
          name: 'tipo',
          type: 'number'
        },
        "nombres": {
          name: 'nombres',
          type: 'string'
        },
        "aPaterno": {
          name: 'aPaterno',
          type: 'string'
        },
        "aMaterno": {
          name: 'aMaterno',
          type: 'string'
        },
        "activo": {
          name: 'activo',
          type: 'boolean'
        },
        "gastosHabilitados": {
          name: 'gastosHabilitados',
          type: 'boolean',
          default: true
        },
        "slack_id": {
          name: 'slack_id',
          type: 'string'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'idUsuario',
          keyTo: 'userId'
        },
        casos: {
          name: 'casos',
          type: 'Caso[]',
          model: 'Caso',
          relationType: 'hasMany',
                  keyFrom: 'idUsuario',
          keyTo: 'idCaso'
        },
        comentario: {
          name: 'comentario',
          type: 'Comentario[]',
          model: 'Comentario',
          relationType: 'hasMany',
                  keyFrom: 'idUsuario',
          keyTo: 'idComentario'
        },
        logs: {
          name: 'logs',
          type: 'Log[]',
          model: 'Log',
          relationType: 'hasMany',
                  keyFrom: 'idUsuario',
          keyTo: 'idLog'
        },
        mensajes: {
          name: 'mensajes',
          type: 'Mensaje[]',
          model: 'Mensaje',
          relationType: 'hasMany',
                  keyFrom: 'idUsuario',
          keyTo: 'idMensaje'
        },
      }
    }
  }
}
