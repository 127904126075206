/* tslint:disable */
import {
  Caso
} from '../index';

declare var Object: any;
export interface ComentarioInterface {
  "idComentario"?: number;
  "contenido"?: any;
  "archivo"?: any;
  "estadoFueCreado"?: number;
  "idCaso"?: number;
  "createdAt": Date;
  "updatedAt": Date;
  "abogado": String;
  caso?: Caso;
}

export class Comentario implements ComentarioInterface {
  "idComentario": number;
  "contenido": any;
  "archivo": any;
  "estadoFueCreado": number;
  "idCaso": number;
  "createdAt": Date;
  "updatedAt": Date;
  "abogado": String;
  caso: Caso;
  constructor(data?: ComentarioInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Comentario`.
   */
  public static getModelName() {
    return "Comentario";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Comentario for dynamic purposes.
  **/
  public static factory(data: ComentarioInterface): Comentario{
    return new Comentario(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Comentario',
      plural: 'Comentarios',
      path: 'Comentarios',
      idName: 'idComentario',
      properties: {
        "idComentario": {
          name: 'idComentario',
          type: 'number'
        },
        "contenido": {
          name: 'contenido',
          type: 'any'
        },
        "archivo": {
          name: 'archivo',
          type: 'any'
        },
        "estadoFueCreado": {
          name: 'estadoFueCreado',
          type: 'number'
        },
        "idCaso": {
          name: 'idCaso',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "abogado": {
          name: 'abogado',
          type: 'text'
        },
      },
      relations: {
        caso: {
          name: 'caso',
          type: 'Caso',
          model: 'Caso',
          relationType: 'belongsTo',
                  keyFrom: 'idCaso',
          keyTo: 'idCaso'
        },
      }
    }
  }
}
