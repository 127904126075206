import { Component, OnInit } from '@angular/core';
import { Usuario, UsuarioApi, Caso } from '../shared/sdk';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';

@Component({
  selector: 'app-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.css']
})
export class PopupModalComponent implements OnInit {
  u: Usuario
  casos: Caso[]
  acumCapital = [0, 0, 0, 0, 0, 0, 0] //SE QUITA UN "0" YA QUE X.F. YA NO SE UTILIZARA
  //SE AGREGA EL ACUMULADOR DE CIFRAS PARA LAS CIFRAS DE EFSY
  acumCapital_efsy = [0, 0, 0, 0, 0, 0, 0] //SE QUITA UN "0" YA QUE X.F. YA NO SE UTILIZARA
//popup de todos los gastos para el administrador, concatena en diferentes tramos y mostrarlos
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, public usuarioapi: UsuarioApi) {

    usuarioapi.getCurrent().subscribe((usuario: Usuario) => {
      this.u = usuario
      this.casos = config.data.casos
      this.casos.forEach(element => {
        if (element.recuperado2==false) {
          //console.log (element.recuperado2)
          //checar que no haya sido recuperado
          switch (element.tramo) {
            case 0:
              this.acumCapital[0] += element.capital
              this.acumCapital_efsy [0] += element.cap_efsy
              break;
            case 1:
              this.acumCapital[1] += element.capital
              this.acumCapital_efsy [1] += element.cap_efsy
              break;
            case 2:
              this.acumCapital[2] += element.capital
              this.acumCapital_efsy [2] += element.cap_efsy
              break;
            case 3:
              this.acumCapital[3] += element.capital
              this.acumCapital_efsy [3] += element.cap_efsy
              break;
            case 4:
              this.acumCapital[4] += element.capital
              this.acumCapital_efsy [4] += element.cap_efsy
              break;
            case 5:
              this.acumCapital[5] += element.capital
              this.acumCapital_efsy [5] += element.cap_efsy
              break;
            case 6:
              this.acumCapital[6] += element.capital
              this.acumCapital_efsy [6] += element.cap_efsy
              break;
            /*case 7:
              this.acumCapital[7] += element.capital
              this.acumCapital_efsy [7] += element.cap_efsy
              break;
            case 8:
              this.acumCapital[8] += element.capital
              this.acumCapital_efsy [8] += element.cap_efsy
              break;*/
            default:
              break;
          }

        }

      });

      //  console.log("from popup")
      //console.log(this.casos)
    })
  }

  ngOnInit() {
  }

}