import {EventEmitter, Injectable, Output } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ServicioInformeService {

  @Output() envioRecuAccedde: EventEmitter<any> = new EventEmitter();
  constructor() { }
}
