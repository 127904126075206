import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { CasoApi, Caso } from 'src/app/shared/sdk';
import { UserIdleService } from 'angular-user-idle';
import { Router } from '@angular/router';
@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
  thisdate = new Date()
  notifwarning = []
  notifdanger = []
  constructor(public casoapi: CasoApi,private userIdle: UserIdleService,public router:Router) { }

  ngOnInit() {
    this.userIdle.startWatching();
    
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => console.log(count));
    
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() =>{
      this.userIdle.resetTimer()
      this.router.navigateByUrl('logout')
      
    });

    this.casoapi.find(
      {
        include: [{ relation: 'usuario', scope: { fields: ['username', 'idUsuario', 'honorario'] } },
        { relation: 'comentarios', scope: { order: 'createdAt DESC', limit: 1 } }, { relation: 'gastos' }]
      })
      .subscribe((casosResponse: Caso[]) => {


        for (var c of casosResponse) {
          if (c.comentarios.length > 0) {
            if (this.diasHastaCaducidad(c.comentarios[0].createdAt) < 45) {
              this.notifwarning.push(c)
              
              if (this.diasHastaCaducidad(c.comentarios[0].createdAt) < 30) {
               this.notifwarning.pop()
                this.notifdanger.push(c)
              }
              
            }
          }
        }
        //console.log(this.notifwarning)
       // console.log(this.notifdanger)

      });

  }
  diasHastaCaducidad(d1) {
    var m = +this.thisdate - +new Date(d1)
    m = m / 1000 / 60 / 60 / 24
    var f: any = (Number.parseInt(m.toString())).toFixed(0)
    return 120 - f
  }
}
