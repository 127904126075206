import { Injectable } from '@angular/core';
import { UsuarioApi, Usuario } from 'src/app/shared/sdk';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
  isSoporte?:boolean;
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItemsAdmin = [
  {
    id: 'navigation',
    title: 'Carteras',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Cartera Global',
        type: 'item',
        url: '/cartera',
        icon: 'fa fa-book '
      }
    ]
  },

  {
    id: 'table',
    title: 'Casos',
    type: 'group',
    icon: 'feather icon-list',
    children: [
      {
        id: 'basic',
        title: 'Nuevo Caso',
        type: 'item',
        url: '/nuevo-caso',
        icon: 'fa fa-plus',
      },
      {
        id: 'forms-element',
        title: 'Asignar Caso',
        type: 'item',
        url: '/asignar-caso',
        icon: 'fa fa-handshake'
      },
      {
        id: 'forms-element',
        title: 'Actualizar Casos',
        type: 'item',
        url: '/editar-caso',
        icon: 'fa fa-pencil-square-o'
      }
    ]
  },
  {
    id: 'ui-element',
    title: 'Abogado Corporativo',
    type: 'group',
    icon: 'feather icon-layers',
    children: [
      {
        id: 'forms-element',
        title: 'Resumen',
        type: 'item',
        url: '/resumen',
        icon: 'fa fa-bar-chart'
      },
      {
        id: 'forms-element',
        title: 'Asignar Gastos',
        type: 'item',
        url: '/gastos',
        icon: 'fa fa-money'
      },
      {
        id: 'forms-element',
        title: 'Aprobar Gastos',
        type: 'item',
        url: '/aprobar-gastos',
        icon: 'fa fa-thumbs-o-up '
      },
      {
        id: 'bootstrap',
        title: 'Deshabilitar Gastos',
        type: 'item',
        url: '/inhabilitar-despacho',
        icon: 'fa fa-gavel'
      },
      {
        id: 'bootstrap',
        title: 'Dias Inhabiles',
        type: 'item',
        url: '/Assignar-dias-inahables',
        icon: 'fa fa-user-times'
      },
      /*{
        id: 'bootstrap',
        title: 'Nuevo Despacho',
        type: 'item',
        url: '/nuevo-despacho',
        icon: 'fa fa-gavel'
      },*/ //se desabilita ya que no hace nada mas y no hay tiempo de momento para resolverlo

    ]
  },
  {
    id: 'table',
    title: 'Usuario',
    type: 'group',
    icon: 'feather icon-list',
    children: [
      {
        id: 'bootstrap',
        title: 'Nuevo Usuario',
        type: 'item',
        url: '/nuevo-usuario',
        icon: 'fa fa-user-o'
      },
      {
        id: 'bootstrap',
        title: 'Bloquear Usuario',
        type: 'item',
        url: '/bloquear-usuario',
        icon: 'fa fa-ban'
      },
      {
        id: 'bootstrap',
        title: 'Bitacora',
        type: 'item',
        url: '/bitacora',
        icon: 'fa fa-history'
      },
      {
        id: 'apex',
        title: 'Cerrar Sesion',
        type: 'item',
        url: '/logout',
        icon: 'fa fa-power-off'
      }
    ]
  },
];
const NavigationItemsWorkout = [
  {
    id: 'navigation',
    title: 'Carteras',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Cartera Global',
        type: 'item',
        url: '/cartera',
        icon: 'fa fa-book '
      }
    ]
  },

  {
    id: 'table',
    title: 'Casos',
    type: 'group',
    icon: 'feather icon-list',
    children: [
      {
        id: 'basic',
        title: 'Nuevo Caso',
        type: 'item',
        url: '/nuevo-caso',
        icon: 'fa fa-plus',
      },
      {
        id: 'forms-element',
        title: 'Asignar Caso',
        type: 'item',
        url: '/asignar-caso',
        icon: 'fa fa-handshake'
      },
      {
        id: 'forms-element',
        title: 'Actualizar Casos',
        type: 'item',
        url: '/editar-caso',
        icon: 'fa fa-pencil-square-o'
      }
    ]
  },
  {
    id: 'ui-element',
    title: 'Abogado Corporativo',
    type: 'group',
    icon: 'feather icon-layers',
    children: [
      {
        id: 'forms-element',
        title: 'Resumen',
        type: 'item',
        url: '/resumen',
        icon: 'fa fa-bar-chart'
      },
      {
        id: 'forms-element',
        title: 'Asignar Gastos',
        type: 'item',
        url: '/gastos',
        icon: 'fa fa-money'
      },
      {
        id: 'forms-element',
        title: 'Aprobar Gastos',
        type: 'item',
        url: '/aprobar-gastos',
        icon: 'fa fa-thumbs-o-up '
      },
      {
        id: 'bootstrap',
        title: 'Deshabilitar Gastos',
        type: 'item',
        url: '/inhabilitar-despacho',
        icon: 'fa fa-gavel'
      },
      {
        id: 'bootstrap',
        title: 'Dias Inhabiles',
        type: 'item',
        url: '/Assignar-dias-inahables',
        icon: 'fa fa-user-times'
      },
      /*{
        id: 'bootstrap',
        title: 'Nuevo Despacho',
        type: 'item',
        url: '/nuevo-despacho',
        icon: 'fa fa-gavel'
      },*/

    ]
  },
  {
    id: 'table',
    title: 'Usuario',
    type: 'group',
    icon: 'feather icon-list',
    children: [
      {
        id: 'bootstrap',
        title: 'Nuevo Usuario',
        type: 'item',
        url: '/nuevo-usuario',
        icon: 'fa fa-user-o'
      },
      {
        id: 'bootstrap',
        title: 'Bloquear Usuario',
        type: 'item',
        url: '/bloquear-usuario',
        icon: 'fa fa-ban'
      },
      {
        id: 'bootstrap',
        title: 'Bitacora',
        type: 'item',
        url: '/bitacora',
        icon: 'fa fa-history'
      },
      /*{
        id: 'bootstrap',
        title: 'Soporte',
        type: 'item',
        url: '/bitacora',
        icon: 'fa fa-history',
        isSoporte:true
      },*/
      {
        id: 'apex',
        title: 'Cerrar Sesion',
        type: 'item',
        url: '/logout',
        icon: 'fa fa-power-off'
      }
    ]
  },
];
const NavigationItemsAbogado = [
  {
    id: 'navigation',
    title: 'Abogado Corporativo',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Mi Cartera',
        type: 'item',
        url: '/cartera-abogado',
        icon: 'fa fa-book'
      },
      {
        id: 'dashboard',
        title: 'Mis Gastos',
        type: 'item',
        url: '/gastos-abogado',
        icon: 'fa fa-handshake-o'
      },
      {
        id: 'dashboard',
        title: 'Nuevos Gasto',
        type: 'item',
        url: '/nuevo-gasto',
        icon: 'fa fa-money'
      }
    ]
  },

  {
    id: 'table',
    title: 'Usuario',
    type: 'group',
    icon: 'feather icon-list',
    children: [
 
      
      {
        id: 'apex',
        title: 'Cerrar Sesion',
        type: 'item',
        url: '/logout',
        icon: 'fa fa-power-off'
      }
    ]
  },
];
@Injectable()
export class NavigationItem {

  public get() {
    return NavigationItemsAdmin;
  }

  public get2() {
    return NavigationItemsAbogado;
  }
  public get3() {
    return NavigationItemsWorkout;
  }
}
