import { MensajesModalComponent } from './../mensajes-modal/mensajes-modal.component';
import { DialogService, MessageService } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { Usuario, UsuarioApi, Log, LogApi } from '../shared/sdk';

@Component({
  selector: 'app-inhabilitar-despacho',
  templateUrl: './inhabilitar-despacho.component.html',
  styleUrls: ['./inhabilitar-despacho.component.scss'],
  providers: [DialogService, MessageService]
})
export class InhabilitarDespachoComponent implements OnInit {
  usuarios: Usuario[]
  msgs = []
  ADMIN_ID = 4;
  constructor(public usuarioapi: UsuarioApi, public logapi: LogApi, public dialogService: DialogService) { }


  //obtener lista de abogados
  ngOnInit() {
    this.usuarioapi.find({ where: { tipo: 2 } }).subscribe((u: Usuario[]) => {
      this.usuarios = u
    })

  }
  openMensajesModal(u:Usuario) {
    //en caso de deshabilitar a un abogado se abrira una ventana para escribir el motivo
    const ref = this.dialogService.open(MensajesModalComponent, {
      data:
      {
        idRemitente:this.ADMIN_ID,
        idDestinatario:u.idUsuario
      },
      width: '40%',
      showHeader: false,
      dismissableMask: true,
      baseZIndex: 1030,
      style: { "position": "fixed", "overflow": "auto", "max-height": "91%" }
    });
  }
  updateUsuario(u) {
    //this.usuarioapi.up
//acutaliza el estado "activo" del usuario el cual habilita las demas consults para el mismo
    this.usuarioapi.patchOrCreate(u).subscribe((u: Usuario) => {

      if (u.gastosHabilitados) {
        this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
          //log del proceso
          var r: Log = new Log();
          r.createdAt = new Date()
          r.updatedAt = new Date()
          r.idUsuario = us.idUsuario
          r.tipo = 14
          r.contenido = JSON.stringify(u)
          this.logapi.create(r).subscribe((d) => {

          })
        })
        this.msgs.push({ severity: 'success', summary: 'Exito', detail: 'Gastos Habilitados del abogado ' + u.username });

      } else {
        //lo mismo solo para la habilitacon en lugar de la deshabiitacion
        this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
          var r: Log = new Log();
          r.createdAt = new Date()
          r.updatedAt = new Date()
          r.idUsuario = us.idUsuario
          r.tipo = 15
          r.contenido = JSON.stringify(u)
          this.logapi.create(r).subscribe((d) => {

          })
        })
        this.msgs.push({ severity: 'success', summary: 'Exito', detail: 'Gastos Deshabilitados del abogado ' + u.username });

      }

      //console.log(u)
    }, (error) => {
      this.msgs.push({ severity: 'error', summary: 'error', detail: 'No se pudo modificar el abogado ' + u.username });

    })
  }

}
