import { Caso } from "./../shared/sdk/models/Caso";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/primeng";
import { ComentarioApi, ContainerApi, UsuarioApi, Comentario, CasoApi, Log, LogApi, Usuario } from "../shared/sdk";
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { encode } from "punycode";
@Component({
  selector: "app-cambiar-estado",
  templateUrl: "./cambiar-estado.component.html",
  styleUrls: ["./cambiar-estado.component.css"],
})
export class CambiarEstadoComponent implements OnInit {
  comentario = "";
  filename = "";
  file;
  d = false;
  value: number = 0;
  msgs: any[] = [];
  hidden = true;
  data: any;
  nuevoestatus;
  selectedCaso: Caso;
  Et_procesalList: any[] = [
    { v: 0, c: "Demanda" },
    { v: 1, c: "Admision de demanda" },
    { v: 2, c: "Diligencia de requerimiento de pago y embargo" },
    { v: 3, c: "Contestacion de demanda" },
    { v: 4, c: "Desahogo de vista de contestacion de demanda" },
    { v: 5, c: "Pruebas" },
    { v: 6, c: "Desahogo de pruebas" },
    { v: 7, c: "Alegatos" },
    { v: 8, c: "Sentencia" },
    { v: 9, c: "Ejecucion de sentencia" },
    { v: 10, c: "Procedimiento de remate" },
    { v: 11, c: "Adjudicacion" },
    { v: 13, c: "" }
  ];
  estatusMlist: any[] = [
    { v: 0, c: "Presentacion de la Demanda" },
    { v: 1, c: "Admision de la Demanda" },
    { v: 2, c: "Diligencia del Requerimiento, emplazamiento y embargo" },
    { v: 3, c: "Etapa Probatoria" },
    { v: 4, c: "Alegatos" },
    { v: 5, c: "Sentencia de la Instancia" },
    { v: 6, c: "Ejecucion de la Sentencia" },
    { v: 7, c: "Procedimiento de Remate" },
    { v: 8, c: "Suspension de proceso" },
    { v: 9, c: "" },
  ];
  impugnacionMlist: any[] = [
    { v: 0, c: "Incidente" },
    { v: 1, c: "Recurso de revocacion" },
    { v: 2, c: "Recurso de apelacion" },
    { v: 3, c: "Amparo" },
    { v: 4, c: "Amparo en revision" },
    { v: 5, c: "" },
  ];
  estatusClist: any[] = [
    { v: 0, c: "Contestacion de la Demanda" },
    { v: 1, c: "Reconvencion" },
    { v: 2, c: "Etapa Probatoria" },
    { v: 4, c: "Alegatos" },
    { v: 5, c: "Sentencia de la Instancia" },
    { v: 6, c: "" },
  ];

  //Se agrega impugnacionClist para que aparescan las opciones
  //en el selector de la ventana "cambiar estado"
  impugnacionClist: any[] = [
    { v: 0, c: "Apelacion" },
    { v: 1, c: "Revocacion" },
    { v: 2, c: "Queja" },
    { v: 3, c: "Amparo Directo" },
    { v: 4, c: "Amparo Indirecto" },
    { v: 5, c: "Suspendido" },
    { v: 6, c: "Caducado" },
    { v: 7, c: "" },
  ];

  @ViewChild("file", null) fileselector: ElementRef;
  constructor(
    public logapi: LogApi,
    public casoapi: CasoApi,
    public containerapi: ContainerApi,
    public usuarioapi: UsuarioApi,
    public http: HttpClient,
    public comentarioapi: ComentarioApi,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    this.selectedCaso = this.config.data.caso;
  }

  //pone en blanco los campos
  reset() {
    this.fileselector.nativeElement.value = "";
    (this.filename = ""), (this.d = false);
  }

  onFileSelect(event) {
    //console.log('on MODAL')
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.filename = event.target.files[0].name;
      
    }
  }
  changeEstatus() {
    if (!this.file) {
      var c: Comentario = new Comentario();
      c.contenido = this.comentario;
      c.idCaso = this.config.data.caso.idCaso;
      c.archivo =  '';
      c.estadoFueCreado = this.config.data.caso.estatusM;
      //AQUI VA ESTADO CIVIL TOO, ACTUALIZAR MODELO COMENTARIO
      this.comentarioapi.upsert(c).subscribe((res) => {
        //console.log(res)
        this.casoapi.upsert(this.config.data.caso).subscribe((d) => {
            //genera el log
            this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
              var r: Log = new Log();
              r.createdAt = new Date();
              r.updatedAt = new Date();
              r.idUsuario = us.idUsuario;
              r.tipo = 7;
              r.contenido = JSON.stringify(this.config.data.caso);
              this.logapi.create(r).subscribe((d) => {});
              this.ref.close();
            });
          },
          (error) => {
            //console.log(error)
          }
        );
      });
    } else {
      //primero sube el archivo al servidor y despues crea un nuevo comentario con la url del archivo recien subido
      let formData: FormData = new FormData();
      formData.append("file", this.file, this.file.name);
      let headerst = new HttpHeaders();
      headerst.append("Content-Type", "multipart/form-data; charset=utf-8");
      headerst.append("Accept", "application/json");
      var i: number = this.selectedCaso.idCaso;
      var url = "https://api1.accedde.mx/api/containers/" + i + "/upload";
      //var url = 'http://localhost:3000/api/containers/' + i + '/upload'
      this.hidden = false;
      this.http.post(url, formData, {headers: headerst, reportProgress: true, observe: "events",}).pipe(map((event: any) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                const progress = Math.round((100 * event.loaded) / event.total);
                this.value = progress;
                //console.log(progress)
                return { status: "progress", message: progress };
              case HttpEventType.Response:
                //console.log("FINISHED")
                return event.body;
              default:
                //console.log("unhandled")
                return `Unhandled event: ${event.type}`;
            }
          })
        ).subscribe(
          (data: any) => {
            this.data = data;
          },(error) => {
            var s = i.toString();
            this.containerapi.create({ name: s }).subscribe((s) => {
              //console.log(s)
              this.changeEstatus();
            });
          },() => {
            var c: Comentario = new Comentario();
            c.contenido = this.comentario;
            c.idCaso = this.config.data.caso.idCaso;
            c.archivo = this.data.result.files.file[0].name;
            c.estadoFueCreado = this.config.data.caso.estatusM;
            //AQUI VA ESTADO CIVIL TOO, ACTUALIZAR MODELO COMENTARIO
            this.comentarioapi.upsert(c).subscribe((res) => {
              //console.log(res)
              this.casoapi.upsert(this.config.data.caso).subscribe((d) => {
                  //genera el log
                  this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
                    var r: Log = new Log();
                    r.createdAt = new Date();
                    r.updatedAt = new Date();
                    r.idUsuario = us.idUsuario;
                    r.tipo = 7;
                    r.contenido = JSON.stringify(this.config.data.caso);
                    this.logapi.create(r).subscribe((d) => {});
                    this.ref.close();
                  });
                },
                (error) => {
                  //console.log(error)
                }
              );
            });
          }
        );
    }
  }
}
