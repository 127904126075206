import { Caso } from './../shared/sdk/models/Caso';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ComentarioApi, Comentario, UsuarioApi, ContainerApi, Log, LogApi, Usuario } from '../shared/sdk';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-editar-modal',
  templateUrl: './editar-modal.component.html',
  styleUrls: ['./editar-modal.component.css'],
})

export class EditarModalComponent implements OnInit {
  comentario = ''
  filename = ''
  file
  d = false;
  value: number = 0
  msgs: any[] = [];
  hidden = true
  data: any
  caso: Caso
  @ViewChild('file', null) fileselector: ElementRef;


  constructor(public logapi: LogApi, public containerapi: ContainerApi, public usuarioapi: UsuarioApi, public http: HttpClient, public comentarioapi: ComentarioApi, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.caso = this.config.data.caso
  }
    //si quiere subir el cambio tiene que tener un comentario y seleccionar un archivo

  onFileSelect(event) {
    //console.log('on MODAL')
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.filename = event.target.files[0].name;
      //console.log(event.target.files[0])
      if (this.comentario != '') {
        this.d = false;
      }
    }
  }
//pone en blanco los campos 

  reset() {
    this.fileselector.nativeElement.value = '';
    this.filename = '',
      this.d = true
  }

  ngOnInit() {
    //inicializa un nuevo comentario para asignarlo a losinputs del html
    var c: Comentario = new Comentario()
    //console.log("INIT MODAL editar")
  }

  selected(evt) {
    this.d = false;
  }

  nuevoComentario() {
   //primero sube el archivo al servidor y despues crea un nuevo comentario con la url del archivo recien subido
    if(!this.file){
      this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
      var c: Comentario = new Comentario();
      c.contenido = this.comentario;
      c.idCaso = this.config.data.caso.idCaso;
      c.archivo = '';
      c.estadoFueCreado = this.caso.estatusM;
      c.abogado = us.username;
      this.comentarioapi.upsert(c).subscribe((res) => {   
          var r: Log = new Log();
          r.createdAt = new Date()
          r.updatedAt = new Date()
          r.idUsuario = us.idUsuario
          r.tipo = 11
          r.contenido = JSON.stringify(res)
          this.logapi.create(r).subscribe((d) => {
          })
          this.ref.close();
        })
      })
    }else{
    let formData: FormData = new FormData();
    formData.append('file', this.file, this.file.name);
    let headerst = new HttpHeaders();
    headerst.append('Content-Type', 'multipart/form-data');
    headerst.append('Accept', 'application/json');
    var i: number = this.caso.idCaso;
    var url = 'https://api1.accedde.mx/api/containers/' + i + '/upload'
    //var url = 'http://localhost:3000/api/containers/' + i + '/upload'
    this.hidden = false;
    //todo esto es parnafenalia para subir el archivo y que nos actualize el porcetaje de subida
    this.http.post(url, formData, { headers: headerst, reportProgress: true, observe: 'events' }).pipe(map((event: any) => {
      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          this.value = progress;
          //console.log(progress)
          return { status: 'progress', message: progress };

        case HttpEventType.Response:

          //console.log("FINISHED")
          return event.body;
        default:
          //console.log("unhandled")
          return `Unhandled event: ${event.type}`;
      }
    })).subscribe((data: any) => {
          this.data = data;
        },
        error => {
          //si hay error es que no existe la carpeta y hay que crearla 
          var s = i.toString()
          this.containerapi.create({ name: s }).subscribe((s) => {
            //console.log(s)
            this.nuevoComentario()
          })
        }, () => {
        //console.log(this.data)
        this.usuarioapi.getCurrent().subscribe((us: Usuario) => {
        var c: Comentario = new Comentario();
        c.contenido = this.comentario;
        c.idCaso = this.config.data.caso.idCaso;
        c.archivo = this.data.result.files.file[0].name;
        c.estadoFueCreado = this.caso.estatusM;
        c.abogado = us.username;
        this.comentarioapi.upsert(c).subscribe((res) => {
            //entrada en el log
            var r: Log = new Log();
            r.createdAt = new Date()
            r.updatedAt = new Date()
            r.idUsuario = us.idUsuario
            r.tipo = 11
            r.contenido = JSON.stringify(res)
            this.logapi.create(r).subscribe((d) => {
            })
            this.ref.close();
            //console.log(res)
          })
          // this.messageService.add({severity:'success', summary:'Service Message', detail:'Via MessageService'});
        })
      }
      )
    }
  }
}
