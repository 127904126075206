/* tslint:disable */
import {
  Usuario
} from '../index';

declare var Object: any;
export interface LogInterface {
  "idLog"?: number;
  "contenido"?: any;
  "tipo"?: number;
  "idUsuario"?: number;
  "createdAt": Date;
  "updatedAt": Date;
  usuario?: Usuario;
}

export class Log implements LogInterface {
  "idLog": number;
  "contenido": any;
  "tipo": number;
  "idUsuario": number;
  "createdAt": Date;
  "updatedAt": Date;
  usuario: Usuario;
  constructor(data?: LogInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Log`.
   */
  public static getModelName() {
    return "Log";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Log for dynamic purposes.
  **/
  public static factory(data: LogInterface): Log{
    return new Log(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Log',
      plural: 'Logs',
      path: 'Logs',
      idName: 'idLog',
      properties: {
        "idLog": {
          name: 'idLog',
          type: 'number'
        },
        "contenido": {
          name: 'contenido',
          type: 'any'
        },
        "tipo": {
          name: 'tipo',
          type: 'number'
        },
        "idUsuario": {
          name: 'idUsuario',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        usuario: {
          name: 'usuario',
          type: 'Usuario',
          model: 'Usuario',
          relationType: 'belongsTo',
                  keyFrom: 'idUsuario',
          keyTo: 'idUsuario'
        },
      }
    }
  }
}
